import { Link } from "react-router-dom";
import ops from '../../assets/img/ops.svg'


export default function NotFound() {


    return (
        <>
            <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 min-h-screen py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
                <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                    <div className="relative">
                        <div className="absolute">
                            <div className="">
                                <h1 className="my-2 text-gray-800 font-bold text-2xl">
                                    Wow! Sembra che tu abbia trovato la porta per il grande nulla 🤭
                                </h1>
                                <Link to={`/crm/customers`}>
                                    <button
                                        className="flex rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >Homepage</button>
                                </Link>
                                
                            </div>
                        </div>
                        <div>
                            <h1 className="text-9xl font-extrabold text-green-600/20 p-24 " >404</h1>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={ops} className="h-72 w-auto" alt="cat"/>
                </div>
            </div>
        </>
    )
}
