import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition, Dialog, RadioGroup } from '@headlessui/react'
import { ChevronDownIcon, ArrowUpTrayIcon, PlusCircleIcon, CursorArrowRaysIcon, ArrowDownTrayIcon, MagnifyingGlassIcon, PaperClipIcon, XMarkIcon, QuestionMarkCircleIcon, UserIcon, HandThumbUpIcon, CheckIcon, CogIcon, PlusIcon, StarIcon, EllipsisVerticalIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, UserGroupIcon, NoSymbolIcon, PaperAirplaneIcon, PencilIcon, TrashIcon, EnvelopeOpenIcon, EnvelopeIcon, PhoneIcon, KeyIcon } from '@heroicons/react/24/outline'
import { LuMailCheck } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebar, toggleNavbar, sidebarSubMenu } from '../../actions'
import { Tooltip } from 'react-tooltip'


import image from '../../assets/img/default.svg'
import aruba from '../../assets/img/aruba.png'

import axios from 'axios';

import Cookies from 'js-cookie'

import Table1 from '../../components/tables/table-1'
import Loader1 from '../../components/utility/loader-1'
import Modal2 from '../../components/modals/modal-2'
import Form1 from '../../components/forms/form-1'
import Notification1 from '../../components/notifications/notification-1'
import WYSIWYG1 from '../../components/utility/wysiwyg'



export default function Users() {

    let company = JSON.parse(Cookies.get('company'))
    console.log(company)
    let theme = company.theme
    let userCookie = JSON.parse(Cookies.get('user'))
    let user = userCookie.user
    let jwt = userCookie.jwt

    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [innerSidebarOpen, setInnerSidebarOpen] = useState(true)

    const [data, setData] = useState(null)
    const [open, setOpen] = useState(false)
    const [tabs, setTabs] = useState([
        { name: 'Attivi', slug: 'attivi', count: 0, current: true },
        { name: 'Invitati', slug: 'invitati', count: 0, current: false },
        { name: 'Disattivi', slug: 'disattivati', count: 0, current: false },
    ])
    const [openInvitation, setOpenInvitation] = useState(false)
    const [openResendPassword, setOpenResendPassword] = useState(false)
    const [openBlock, setOpenBlock] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openReactivate, setOpenReactivate] = useState(false)
    const [notification, setNotification] = useState({
        title: "",
        description: "",
        type: "success"
    })
    const [showNotification, setShowNotification] = useState(false)
    const [loadingItem, setLoadingItem] = useState(false)

    const [teams, setTeams] = useState(null)

    const getTeams = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/teams/fetchInSelect`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: company.uuid, // Utilizza data.company.id al posto di ${ company.id }
                    }
                }
            );
            if (response.data) {
                setTeams(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };



    const getUsers = async (perPage, currentPage, sorted, sortedBy, search) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/users/fetchByCompany`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: company.uuid, // Utilizza data.company.id al posto di ${ company.id }
                        tab: tabs.find(tab => tab.current).slug,
                        limit: perPage,
                        page: currentPage,
                        sorted: sorted,
                        sortedBy: sortedBy,
                        search: search
                    }
                }
            );
            if (response.data) {
                setData(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getUsers(25, 1, "asc", "name", "")
        getTeams()
    }, [tabs]);


    const dataCallback = async (array, perPage, currentPage, sorted, sortedBy, search) => {
        getUsers(perPage, currentPage, sorted, sortedBy, search)
    }

    const config = {
        title: "Utenti",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700">
                    La lista degli <strong>utenti</strong>, scegli quale operazione effettuare.
                </p>
            )
        },
        extra: () => {
            return (
                <>
                    <Menu as="div" className="relative inline-block text-left">
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                <img src={aruba} className='w-16 mr-2' /> <span className="text-nowrap text-ellipsis overflow-hidden">Fatturazione di Aruba</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                CSV .csv
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className={`flex rounded-md bg-${theme}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                    >
                        <PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />Aggiungi
                    </button>
                </>
            )
        },
        pagination: true,
        sizePerPage: 25,
        perPage: [5, 10, 25, 50, 75, 100],
        export: true,
        rowClick: null
    }

    const colonne = [
        {
            name: "Nome",
            dataField: null,
            formatter: (item) => {
                return (
                    <div className="flex items-center cursor-pointer" onClick={() => {
                        setSelectedUser(item)
                        }}>
                        <div className="h-11 w-11 flex-shrink-0">
                            <img className="h-11 w-11 rounded-full" src={item.image ? item.image : image} alt="" />
                        </div>
                        <div className="ml-4">
                            <div className="font-medium text-gray-900 flex">{item.fname + " " + item.lname} {item.permission === "ADMIN" ? <StarIcon className='h-4 w-4 ml-2 text-yellow-600' /> : null}
                                {(!item.confirmed && item.enabled ) ? <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 ml-4 bg-white">
                                    <svg className="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                                        <circle cx={3} cy={3} r={3} />
                                    </svg>
                                    Invitato
                                </span> : null}
                            </div>
                            <div className="text-gray-400">{item.jobTitle}</div>
                        </div>
                    </div>
                )

            }
        },
        {
            name: "Contatti",
            dataField: null,
            formatter: (item) => {
                return (
                    <div className="flex flex-col cursor-pointer" onClick={() => {
                        setSelectedUser(item)
                    }}>
                        <div className="flex flex-row items-center">
                            <EnvelopeIcon className="w-4 h-4 mr-2" />{item.email}
                        </div>
                        {item.phone ? <div className="flex flex-row items-center">
                            <PhoneIcon className="w-4 h-4 mr-2" />{item.phone}
                        </div> : null}
                    </div>
                )

            }
        },
        {
            name: "Team",
            dataField: "team",
            sort: true
        },
        {
            name: "Azioni",
            dataField: null,
            formatter: (item) => {
                return (
                    <>{item.uuid !== user.uuid ? <div className="flex items-center">
                        {tabs.find(tab => tab.current).slug === 'invitati' ? <>
                        <Tooltip id="send-tooltip" />
                            <EnvelopeOpenIcon data-tooltip-id="send-tooltip" data-tooltip-content="Reinvia invito" className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer' onClick={() => {
                                setSelectedItem(item)
                                setOpenInvitation(true)
                            }} />
                        </> : null}
                        {tabs.find(tab => tab.current).slug === 'attivi' ? 
                        <>
                        <Tooltip id="password-tooltip" />
                        <KeyIcon data-tooltip-id="password-tooltip" data-tooltip-content="Reset password" className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer' onClick={() => {
                            setSelectedItem(item)
                            setOpenResendPassword(true)
                            }} /></> : null}
                        {tabs.find(tab => tab.current).slug === 'attivi' ? 
                        <>
                                <Tooltip id="block-tooltip" />
                                <NoSymbolIcon data-tooltip-id="block-tooltip" data-tooltip-content="Blocca utente" className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer' onClick={() => {
                                    setSelectedItem(item)
                                    setOpenBlock(true)
                                }} />
                        </>
                         : null}
                        <>
                            <Tooltip id="delete-tooltip" />
                            <TrashIcon data-tooltip-id="delete-tooltip" data-tooltip-content="Elimina utente" className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer' onClick={() => {
                                setSelectedItem(item)
                                setOpenDelete(true)
                                }} />
                        </>
                        
                        {tabs.find(tab => tab.current).slug === 'disattivati' ? <CursorArrowRaysIcon className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer' onClick={() => {
                            setSelectedItem(item)
                            setOpenReactivate(true)
                            }} /> : null}
                    </div> : <p className='text-gray-400 text-sm'>Non puoi effettuare operazioni su te stesso.</p>}</>
                )

            }
        },
    ]

    const handleTabChange = (slug) => {
        const updatedTabs = tabs.map(tab => ({
            ...tab,
            current: tab.slug === slug,
        }));
        setTabs(updatedTabs);
    };

    const [invitationSuccess, setInvitationSuccess] = useState(null)

    const sendInvitation = async data => {
        try {
            setInvitationSuccess(null)
            const response = await axios.post(
                `${process.env.REACT_APP_API_KEY}/users/sendInvitation`, {
                    email: data.email,
                    company: company,
                    user: user
                },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                }
            );
            if (response.data) {
                getUsers(25, 1, "asc", "name", "")
                getTeams()
                setInvitationSuccess("Utente invitatə con successo 🖖🏻")
                setOpenInvitation(false)
                setNotification({
                    title: "Conferma",
                    description: "Utente invitatə con successo!",
                    type: "success",
                })
                setShowNotification(true)
            }
        } catch (e) {
            console.error(e)
        }
    }

    const resendPassword = async () => {
        try {
            if(selectedItem) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/users/resetPassword`, {
                        uuid: selectedItem.uuid
                    },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    }
                );
                if (response.data) {
                    getUsers(25, 1, "asc", "name", "")
                    setOpenResendPassword(false)
                    setNotification({
                        title: "Conferma",
                        description: "Nuova password inviata con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }
            }
        } catch (e) {
            console.error(e)
            setNotification({
                title: "Errore",
                description: "Ops! Qualcosa è andato storto",
                type: "error",
            })
            setShowNotification(true)
        }
    }

    const blockUser = async () => {
        try {
            if (selectedItem) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/users/blockUser`, {
                    uuid: selectedItem.uuid,
                    blocked: true
                },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    }
                );
                if (response.data) {
                    getUsers(25, 1, "asc", "name", "")
                    getTeams()
                    setOpenBlock(false)
                    setNotification({
                        title: "Conferma",
                        description: "Utente bloccatə con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }
            }
        } catch (e) {
            console.error(e)
            setNotification({
                title: "Errore",
                description: "Ops! Qualcosa è andato storto",
                type: "error",
            })
            setShowNotification(true)
        }
    }

    const deleteUser = async () => {
        try {
            if (selectedItem) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/users/deleteUser`, {
                    uuid: selectedItem.uuid,
                    company: company.uuid
                },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    }
                );
                if (response.data) {
                    getUsers(25, 1, "asc", "name", "")
                    setOpenDelete(false)
                    setNotification({
                        title: "Conferma",
                        description: "Utente eliminatə con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }
            }
        } catch (e) {
            console.error(e)
            setNotification({
                title: "Errore",
                description: "Ops! Qualcosa è andato storto",
                type: "error",
            })
            setShowNotification(true)
        }
    }

    const reactivateUser = async () => {
        try {
            if (selectedItem) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/users/blockUser`, {
                    uuid: selectedItem.uuid,
                    blocked: false
                },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    }
                );
                if (response.data) {
                    getUsers(25, 1, "asc", "name", "")
                    setOpenReactivate(false)
                    setNotification({
                        title: "Conferma",
                        description: "Utente riattivato con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }
            }
        } catch (e) {
            console.error(e)
            setNotification({
                title: "Errore",
                description: "Ops! Qualcosa è andato storto",
                type: "error",
            })
            setShowNotification(true)
        }
    }

    const editUser = async data => {
        try {
            setInvitationSuccess(null)
            data.uuid = selectedUser.uuid
            const response = await axios.patch(
                `${process.env.REACT_APP_API_KEY}/users/edit`, {
                user: data,
            },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                }
            );
            if (response.data) {
                setLoadingItem(true)
                getUsers(25, 1, "asc", "name", "")
                getTeams()
                setSelectedUser(response.data)
                setTimeout(() => {
                    setLoadingItem(false)
                    setNotification({
                        title: "Conferma",
                        description: "Utente modificatə con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }, 1000)
                
            }
        } catch (e) {
            console.error(e)
        }
    }

    let configForm = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Modica i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "fname",
                name: "fname",
                label: "Nome",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.fname : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "lname",
                name: "lname",
                label: "Cognome",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.lname : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "email",
                name: "email",
                label: "Email",
                class: "sm:col-span-6",
                type: "email",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.email : null,
                onChange: null,
                required: true,
                disabled: true,
                placeholder: "Inserisci email",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "phone",
                name: "phone",
                label: "Telefono",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.phone : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "jobTitle",
                name: "jobTitle",
                label: "Posizione lavorativa",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.jobTitle : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "team",
                name: "team",
                label: "Team",
                class: "sm:col-span-3",
                type: "select",
                value: null,
                options: teams ? teams.data : null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                extra: null
            },

        ],
        cancelText: "Annulla",
        submitText: "Modifica",
        submit: data => editUser(data)
    }

    let configFormInvitation = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Inserisci la mail di chi vuoi <strong>invitare</strong> a collaborare nel tuo spazio 4BIZ.</p>
            )
        },
        fields: [
            {
                id: "email",
                name: "email",
                label: "Email",
                class: "sm:col-span-6",
                type: "email",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.fname : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci email aziendale dell'utente",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            }
        ],
        cancelText: "Annulla",
        submitText: "Invita",
        submit: data => sendInvitation(data)
    }


    

    return (
        <>
            {showNotification ? <Notification1 config={notification} show={showNotification} setShow={setShowNotification} /> : null}

            <>
                {selectedUser ? <><div>
                    {!loadingItem ? <main>
                        {/**Modali di conferma */}
                        {selectedUser ? <>
                            <Modal2 title={"Manda invito"} description={"Sei sicurə di voler inviare nuovamente l'invito di registrazione?"} dataCallbackConfirm={() => sendInvitation(selectedItem)} dataCallbackReset={() => {
                                setSelectedItem(null)
                                setOpenInvitation(false)
                            }} open={openInvitation} setOpen={setOpenInvitation} theme={theme} />
                            <Modal2 title={"Invia reset password"} description={"Sei sicurə di voler inviare il reset della password?"} dataCallbackConfirm={() => resendPassword()} dataCallbackReset={() => {
                                setSelectedItem(null)
                                setOpenResendPassword(false)
                            }} open={openResendPassword} setOpen={setOpenInvitation} theme={theme} />
                            <Modal2 title={"Blocca utente"} description={"Sei sicurə di voler bloccare l'utente?"} dataCallbackConfirm={() => blockUser()} dataCallbackReset={() => {
                                setSelectedItem(null)
                                setOpenBlock(false)
                            }} open={openBlock} setOpen={setOpenBlock} theme={theme} />
                            <Modal2 title={"Elimina utente"} description={"Sei sicurə di voler eliminare l'utente? Non potrai invitarlo nuovamente a far parte del tuo spazio 4BIZ per 24 ore."} dataCallbackConfirm={() => deleteUser()} dataCallbackReset={() => {
                                setSelectedItem(null)
                                setOpenDelete(false)
                            }} open={openDelete} setOpen={setOpenDelete} theme={theme} />
                            <Modal2 title={"Riattiva utente"} description={"Sei sicurə di voler riattivare l'utente?"} dataCallbackConfirm={() => reactivateUser()} dataCallbackReset={() => {
                                setSelectedItem(null)
                                setOpenReactivate(false)
                            }} open={openReactivate} setOpen={setOpenReactivate} theme={theme} />
                        </> : null}
                        <div className={innerSidebarOpen ? `xl:pl-96 ` : "xl:pl-36"}>
                            <div className={`absolute grow px-4 py-10 min-h-screen min-w-64 sm:px-6 lg:px-8 lg:py-6 bg-crazy-${theme} overflow-y-scroll`}>
                                {/* Main area */}
                                <div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5  lg:px-8">
                                    <div className="flex items-center space-x-5">
                                        <div className="flex-shrink-0">
                                            <div className="relative">
                                                <img
                                                    className="h-16 w-16 rounded-full"
                                                    src={selectedUser.image ? selectedUser.image : process.env.REACT_APP_DEFAULT_AVATAR}
                                                    alt=""
                                                />
                                                <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div>
                                            <h1 className="text-2xl font-bold text-gray-900">{selectedUser.fname + " " + selectedUser.lname}</h1>
                                            <p className="text-sm font-medium text-gray-500">
                                                {selectedUser.email}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div>
                                                <Menu.Button
                                                    className={`mx-1 block rounded-md bg-${theme}-600 px-3 py-2 flex flex-start items-center text-center text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                                                >
                                                    <CogIcon className="mr-2 h-5 w-5 text-white hover:text-white" />
                                                    Azioni
                                                    <ChevronDownIcon
                                                        className="-mr-1 ml-2 h-5 w-5 text-white hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                    <div className="px-1 py-1 ">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    type="button"
                                                                    className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                    onClick={() => {
                                                                        setOpenResendPassword(true)
                                                                    }}
                                                                >
                                                                    <KeyIcon className='h-4 w-4 mr-2' />Reset password
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    type="button"
                                                                    className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                    onClick={() => {
                                                                        setOpenBlock(true)
                                                                    }}
                                                               >
                                                                    <NoSymbolIcon className='h-4 w-4 mr-2' />Blocca utente
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setOpenDelete(true)
                                                                    }}
                                                                    className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                >
                                                                    <TrashIcon className='h-4 w-4 mr-2' /> Elimina
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>

                                <div className="mx-auto mt-8  sm:px-6  lg:grid-flow-col-dense lg:grid-cols-3">
                                    <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                                        {/* Description list*/}
                                        <section aria-labelledby="applicant-information-title">
                                            <div className="bg-white shadow sm:rounded-lg">
                                                <div className="px-4 py-5 sm:px-6">

                                                    <div className='flex flex-row justify-between items-center mb-4'>
                                                        <div className="flex flex-col justify-between">
                                                            <h2 id="timeline-title" className="text-lg font-bold text-gray-900">
                                                                Informazioni sull'utente
                                                            </h2>
                                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Leggi o modifica i dati dell'utente.</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                                                    <Form1 config={configForm} />
                                                    
                                                </div>
                                                
                                            </div>
                                        </section>

                                        {/* Comments*/}
                                        <section aria-labelledby="notes-title">
                                            <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
                                                <div className="divide-y divide-gray-200">
                                                    <div className="px-4 py-5 sm:px-6">
                                                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                                            Responsabile
                                                        </h2>
                                                    </div>
                                                    <div className="px-4 py-6 sm:px-6">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                         


                                </div>

                            </div>
                        </div>
                    </main> : <main>
                            <div className={innerSidebarOpen ? `xl:pl-96 ` : "xl:pl-36"}>
                                <div className={`absolute grow px-4 py-10 h-screen sm:px-6 lg:px-8 lg:py-6 bg-crazy-${theme} overflow-y-scroll`}>
                                    <Loader1 />
                                </div>
                            </div>
                            </main>}


                    <aside className={`inset-y-0 left-72 hidden ${innerSidebarOpen ? `w-96` : `w-236`} overflow-y-auto border-r border-gray-200 xl:block`}>
                        <div className='flex flex-row justify-between'>
                            {innerSidebarOpen ? <div className="pb-4 px-4 mt-4">
                                <button
                                    type="button"
                                    onClick={() => setInnerSidebarOpen(!innerSidebarOpen)}
                                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <ChevronDoubleLeftIcon className="h-3 w-3" />
                                </button>
                            </div> : <div className="pb-4 px-4 mt-4">
                                <button
                                    type="button"
                                    onClick={() => setInnerSidebarOpen(!innerSidebarOpen)}
                                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <ChevronDoubleRightIcon className="h-3 w-3" />
                                </button></div>}
                            {<div className="pb-4 px-4 mt-4">
                                <button
                                    type="button"
                                    onClick={() => setSelectedUser(null)}
                                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <XMarkIcon className="h-3 w-3" />
                                </button>
                            </div>}
                        </div>

                        {innerSidebarOpen ? <div className="pb-4 px-4 mt-4">
                            <h2 className="text-2xl font-extrabold text-gray-900">Utenti</h2>
                            <p className="mt-1 text-sm text-gray-60">La lista dei tuoi <strong>utenti</strong>, scegli quale operazione effettuare.</p>
                            <RadioGroup value={tabs.find(tab => tab.current)} onChange={tab => handleTabChange(tab.slug)} className="mt-2">
                                <RadioGroup.Label className="sr-only">Choose a memory option</RadioGroup.Label>
                                <div className="grid grid-cols-3 gap-24 sm:grid-cols-6">
                                    {tabs.map((tab) => (
                                        <RadioGroup.Option
                                            key={tab.name}
                                            value={tab}
                                        >
                                            <RadioGroup.Label className={`${tab.current ? `bg-${theme}-500 text-white px-2.5 py-2 ` : `border-1 px-2 py-2 text-gray-500 hover:border-gray-200 hover:text-gray-700 `} rounded-md`}>{tab.name}</RadioGroup.Label>
                                        </RadioGroup.Option>
                                    ))}
                                </div>
                            </RadioGroup>
                            <form className="mt-6 flex gap-x-4" action="#">
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="search" className="sr-only">
                                        Cerca...
                                    </label>
                                    <div className="relative rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input
                                            type="search"
                                            name="search"
                                            id="search"
                                            className={`block w-full rounded-md border-0 bg-gray-100 focus:border-${theme}-600 py-1.5 pl-10  placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                            placeholder="Search"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div> : null}
                        {/* Directory list */}
                        <nav className="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">

                            <ul role="list" className="relative z-0 divide-y divide-gray-200">
                                {data.data.map((item) => (
                                    <li key={item.uuid} onClick={() => {
                                        setLoadingItem(true)
                                        setSelectedUser(item)
                                        setTimeout(() => setLoadingItem(false), 1000)
                                        }}>
                                        <div className={`relative flex items-center ${selectedUser.uuid === item.uuid ? `border-2 border-${theme}-600 ring-inset bg-${theme}-50` : ""} space-x-3 px-6 py-5 hover:bg-${theme}-50`}>
                                            <div className="flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full" src={image} alt="" />
                                            </div>
                                            {innerSidebarOpen ? <div className="min-w-0 flex-1">
                                                    {/* Extend touch target to entire panel */}
                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                    <p className="text-sm font-medium text-gray-900">
                                                        {item.fname + " " + item.lname}
                                                        {(!item.confirmed && item.enabled) ? <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-0.5 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 ml-4 bg-white">
                                                            <svg className="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                                                                <circle cx={3} cy={3} r={3} />
                                                            </svg>
                                                            Invitato
                                                        </span> : null}
                                                        </p>
                                                    <p className="truncate text-sm text-gray-500">{item.email}</p>
                                            </div> : null}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </aside>
                </div></> : <main className={`py-10 w-full bg-crazy-${theme} min-h-screen overflow-y-scroll`}>
                    <div className='px-8 mb-8'>
                        {/**Notifiche successo o errore */}

                        <div className="sm:hidden ">

                            {/**Modali di conferma */}
                            {selectedItem ? <>
                                    <Modal2 title={"Manda invito"} description={"Sei sicurə di voler inviare nuovamente l'invito di registrazione?"} dataCallbackConfirm={() => sendInvitation(selectedItem)} dataCallbackReset={() => {
                                    setSelectedItem(null)
                                    setOpenInvitation(false)
                                }} open={openInvitation} setOpen={setOpenInvitation} theme={theme}/>
                                <Modal2 title={"Invia reset password"} description={"Sei sicurə di voler inviare il reset della password?"} dataCallbackConfirm={() => resendPassword()} dataCallbackReset={() => {
                                    setSelectedItem(null)
                                    setOpenResendPassword(false)
                                    }} open={openResendPassword} setOpen={setOpenInvitation} theme={theme}/>
                                <Modal2 title={"Blocca utente"} description={"Sei sicurə di voler bloccare l'utente?"} dataCallbackConfirm={() => blockUser()} dataCallbackReset={() => {
                                    setSelectedItem(null)
                                    setOpenBlock(false)
                                    }} open={openBlock} setOpen={setOpenBlock} theme={theme}/>
                                <Modal2 title={"Elimina utente"} description={"Sei sicurə di voler eliminare l'utente? Non potrai invitarlo nuovamente a far parte del tuo spazio 4BIZ per 24 ore."} dataCallbackConfirm={() => deleteUser()} dataCallbackReset={() => {
                                    setSelectedItem(null)
                                    setOpenDelete(false)
                                    }} open={openDelete} setOpen={setOpenDelete} theme={theme}/>
                                <Modal2 title={"Riattiva utente"} description={"Sei sicurə di voler riattivare l'utente?"} dataCallbackConfirm={() => reactivateUser()} dataCallbackReset={() => {
                                    setSelectedItem(null)
                                    setOpenReactivate(false)
                                    }} open={openReactivate} setOpen={setOpenReactivate} theme={theme}/>
                            </> : null}

                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            {tabs ? <select
                                id="tabs"
                                name="tabs"
                                onClick={e => handleTabChange(e.target.value)}
                                className={`block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-${theme}-500 focus:outline-none focus:ring-${theme}-500 sm:text-sm`}
                                defaultValue={tabs.find((tab) => tab.current)}
                            >
                                {tabs.map((tab) => (
                                    <option key={tab.name} value={tab.slug}>{tab.name}</option>
                                ))}
                            </select> : null}
                        </div>
                        <div className="hidden sm:block ">
                            <div className="bg-white rounded-xl shadow px-8">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                    {tabs.map((tab) => (
                                        <span
                                            key={tab.name}
                                            onClick={() => handleTabChange(tab.slug)}
                                            className={`cursor-pointer flex whitespace-nowrap border-b-2 ${tab.current ? `border-${theme}-500` : ""} py-4 px-1 text-sm font-medium`}
                                            aria-current={tab.current ? 'page' : undefined}
                                        >
                                            <span className={`${tab.current ? `bg-${theme}-500 text-white px-2.5 py-0.5 ` : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700'} rounded-xl`}>{tab.name}</span>
                                        </span>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </div>
                    {data ? <Table1 data={data} columns={colonne} config={config} dataCallback={dataCallback} /> : <Loader1 />}
                    <Transition.Root show={open} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={setOpen}>
                            <div className="fixed inset-0" />

                            <div className="fixed inset-0 overflow-hidden">
                                <div className="absolute inset-0 overflow-hidden">
                                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                                            enterFrom="translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                                            leaveFrom="translate-x-0"
                                            leaveTo="translate-x-full"
                                        >
                                            <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                                    <div className="px-4 sm:px-6">
                                                        <div className="flex items-start justify-between">
                                                            <Dialog.Title className="text-base font-semibold items-center flex leading-6 text-gray-900">
                                                                    <LuMailCheck className='h-5 w-5 mr-2' /> Invita nuovo utente
                                                            </Dialog.Title>
                                                            <div className="ml-3 flex h-7 items-center">
                                                                <button
                                                                    type="button"
                                                                    className={`relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-${theme}-500 focus:ring-offset-2`}
                                                                    onClick={() => {
                                                                        setOpen(false)
                                                                        setInvitationSuccess(null)
                                                                    }}
                                                                >
                                                                    <span className="absolute -inset-2.5" />
                                                                    <span className="sr-only">Close panel</span>
                                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                            <Form1 config={configFormInvitation} />
                                                            {invitationSuccess ? <p className='text-sm text-green-600 font-semibold mt-4'>{invitationSuccess}</p> : null}
                                                    </div>
                                                </div>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </main>}
            </>

        </>
            
    )
}

