import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import ResponsiveLineChart from '../../components/charts/responsiveLine'

const SuperAdminLogs = ({ props }) => {
    
    const navigate = useNavigate()
    const user = useSelector(state => state.auth.user)
    const jwt = useSelector(state => state.auth.jwt)

    const [data, setData] = useState([
        {
            id: "Temperature",
            color: "hsl(205, 70%, 50%)",
            data: [
                { x: "Jan", y: 7 },
                { x: "Feb", y: 9 },
                { x: "Mar", y: 13 },
                { x: "Apr", y: 15 },
                { x: "May", y: 20 },
                { x: "Jun", y: 25 },
                { x: "Jul", y: 28 },
                { x: "Aug", y: 30 },
                { x: "Sep", y: 24 },
                { x: "Oct", y: 18 },
                { x: "Nov", y: 12 },
                { x: "Dec", y: 8 },
            ]
        }
    ])

    const getData = async () => {
        try {
            
        } catch(e) {

        }
    }

    useEffect(() => {
        getData()
    }, [])

    let config = {
        title: "Licenza",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700">
                    La lista delle <strong>licenze 4BIZ </strong>, scegli quale operazione effettuare.
                </p>
            )
        },
    }

    return (
        <main className={`py-10 px-10 rounded-lg w-full bg-crazy-green min-h-screen overflow-y-scroll`}>
            <div className="p-10 bg-white">
                
            </div>
        </main>
    );
};

export default SuperAdminLogs;