const regimiFiscali = [
    {
        value: "RF01",
        label: "[RF01] Ordinario",
        image: null,
    },
    {
        value: "RF02",
        label: "[RF02] Contribuenti minimi (art.1, c.96-117, L. 244/07)",
        image: null,
    },
    {
        value: "RF04",
        label: "[RF04] Agricoltura e attività connesse e pesca (art.34 e 34-bis, DPR 633/72)",
        image: null,
    },
    {
        value: "RF05",
        label: "[RF05] Vendita sali e tabacchi (art.74, c.1, DPR. 633/72)",
        image: null,
    },
    {
        value: "RF06",
        label: "[RF06] Commercio fiammiferi (art.74, c.1, DPR. 633/72)",
        image: null,
    },
    {
        value: "RF07",
        label: "[RF07] Editoria (art.74, c.1, DPR. 633/72)",
        image: null,
    },
    {
        value: "RF08",
        label: "[RF08] Gestione servizi telefonia pubblica (art.74, c.1, DPR. 633/72)",
        image: null,
    },
    {
        value: "RF09",
        label: "[RF09] Rivendita documenti di trasporto pubblico e di sosta (art.74, c.1, DPR. 633/72)",
        image: null,
    },
    {
        value: "RF10",
        label: "[RF10] Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al DPR 640/72 (art.74, c.1, DPR. 633/72)",
        image: null,
    },
    {
        value: "RF11",
        label: "[RF11] Agenzie viaggi e turismo (art.74-ter, DPR. 633/72)",
        image: null,
    },
    {
        value: "RF12",
        label: "[RF12] Agriturismo (art.5, c.2, L. 413/91)",
        image: null,
    },
    {
        value: "RF13",
        label: "[RF13] Vendite a domicilio (art.25-bis, c.6, DPR 600/73)",
        image: null,
    },
    {
        value: "RF14",
        label: "[RF14] Rivendita beni usati, oggetti d’arte, d’antiquariato o da collezione (art.36, DL 41/95)",
        image: null,
    },
    {
        value: "RF15",
        label: "[RF15] Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art.40-bis, DL 41/95)",
        image: null,
    },
    {
        value: "RF16",
        label: "[RF16] IVA per cassa P.A. (art.6, c.5, DPR 633/72)",
        image: null,
    },
    {
        value: "RF17",
        label: "[RF17] IVA per cassa (art. 32-bis, DL 83/2012)",
        image: null,
    },
    {
        value: "RF18",
        label: "[RF18] Altro",
        image: null,
    },
    {
        value: "RF19",
        label: "[RF19] Regime forfettario (art.1, c.54-89, L. 190/2014)",
        image: null,
    },
]


const esigibilitaIVA = [
    {
        value: "IVAimmediata",
        label: "IVA ad esigibilità immediata",
        image: null,
    },
    {
        value: "IVAdifferita",
        label: "IVA ad esigibilità differita",
        image: null,
    },
    {
        value: "ScissionePagamenti",
        label: "Scissione dai pagamenti",
        image: null,
    },
]

export { regimiFiscali, esigibilitaIVA }