import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ArrowUpTrayIcon, PlusCircleIcon, ArrowDownTrayIcon, MagnifyingGlassIcon, PaperClipIcon, XMarkIcon, QuestionMarkCircleIcon, UserIcon, HandThumbUpIcon, CheckIcon, CogIcon, EnvelopeIcon, PhoneIcon, PlusIcon, StarIcon, EllipsisVerticalIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, PencilIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'


import { customers } from '../../assets/data/customers'
import image from '../../assets/img/default.svg'
import aruba from '../../assets/img/aruba.png'

import Table1 from '../../components/tables/table-1'
import Loader1 from '../../components/utility/loader-1'
import WYSIWYG1 from '../../components/utility/wysiwyg'
import Modal1 from '../../components/modals/modal-1'
import Form1 from '../../components/forms/form-1'
import { parseCookies } from "nookies";


const attachments = [
    { name: 'resume_front_end_developer.pdf', href: '#' },
    { name: 'coverletter_front_end_developer.pdf', href: '#' },
]


const user = {
    name: 'Whitney Francis',
    email: 'whitney@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
}

const comments = [
    {
        id: 1,
        name: 'Leslie Alexander',
        date: '4d ago',
        imageId: '1494790108377-be9c29b29330',
        body: 'Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.',
    },
    {
        id: 2,
        name: 'Michael Foster',
        date: '4d ago',
        imageId: '1519244703995-f4e0f30006d5',
        body: 'Et ut autem. Voluptatem eum dolores sint necessitatibus quos. Quis eum qui dolorem accusantium voluptas voluptatem ipsum. Quo facere iusto quia accusamus veniam id explicabo et aut.',
    },
    {
        id: 3,
        name: 'Dries Vincent',
        date: '4d ago',
        imageId: '1506794778202-cad84cf45f1d',
        body: 'Expedita consequatur sit ea voluptas quo ipsam recusandae. Ab sint et voluptatem repudiandae voluptatem et eveniet. Nihil quas consequatur autem. Perferendis rerum et.',
    },
]

export default function Customers() {

    const cookies = parseCookies()
    let company = JSON.parse(cookies['@company'])
    let theme = company.theme
    
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [innerSidebarOpen, setInnerSidebarOpen] = useState(true)
    

    const config = {
        title: "Clienti",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700">
                    La lista dei tuoi <strong>clienti</strong>, scegli quale operazione effettuare.
                </p>
            )
        },
        extra: () => {
            return (
                <>
                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button
                                className={`mx-1 block rounded-md bg-${theme}-600 px-3 py-2 flex flex-start items-center text-center text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                            >
                                <ArrowUpTrayIcon className="mr-2 h-5 w-5 text-white hover:text-white" />Importa
                                <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5 text-white hover:text-white"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                <img src={aruba} className='w-16 mr-2' /> <span className="text-nowrap text-ellipsis overflow-hidden">Fatturazione di Aruba</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                CSV .csv
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <button
                        type="button"
                        className={`flex rounded-md bg-${theme}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                    >
                        <PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />Aggiungi
                    </button>
                </>
            )
        },
        pagination: true,
        sizePerPage: 5,
        perPage: [5, 10, 20, 50, 75, 100],
        export: true,
        rowClick: (item) => {
            setSelectedItem(item)
        }
    }

    const [open, setOpen] = useState(false)


    const [pagination, setPagination] = useState(1)
    const [data, setData] = useState({
        meta: {
            "pagination": {
                "page": 1,
                "pageSize": 25,
                "pageCount": 1,
                "total": 1
            }
        },
        data: customers.data.slice(0, config.sizePerPage)
    })

    const colonne = [
        {
            name: "Name",
            dataField: null,
            formatter: (item) => {
                return (
                    <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                            <img className="h-11 w-11 rounded-full" src={image} alt="" />
                        </div>
                        <div className="ml-4">
                            <div className="font-medium text-gray-900">{item.businessName}</div>
                            <div className="mt-1 text-gray-500">{item.vat}</div>
                        </div>
                    </div>
                )

            }
        },
        {
            name: "P.IVA",
            dataField: "vat",
        },
        {
            name: "Email",
            dataField: "email",
            sort: true
        },


    ]


    useEffect(() => {

        if (selectedItem) {
            // Logica per la visualizzazione dei dettagli dell'elemento selezionato

        }
    }, [selectedItem, data]);



    const dataCallback = async (array, perPage, currentPage, sorted, sortedBy, search) => {
        const startIndex = perPage * (currentPage - 1);
        const endIndex = startIndex + perPage;
        console.log(startIndex, endIndex)
        const slicedData = customers.data.slice(startIndex, endIndex);

        const updatedData = {
            total: array.length,
            data: slicedData,
        };

        setData(updatedData);
    }

    let configForm = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Modica i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "fname",
                name: "fname",
                label: "Nome",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-500">Write a few sentences about yourself.</p>
                    )
                }
            },
            {
                id: "lname",
                name: "lname",
                label: "Cognome",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-500">Write a few sentences about yourself.</p>
                    )
                },
            },

        ],
        cancelText: "Annulla",
        submitText: "Invia"
    }


    let configModal = {
        title: "Considerazioni",
        size: "SM",
        formatter: () => {
            return (
                <div>
                    <Form1 config={configForm} />
                </div>
            )
        },
        type: "EDIT"
    }



    return (
        <>
            {data ? <>
                <Modal1 config={configModal} open={open} setOpen={setOpen} />
                {selectedItem ? <div>
                    <main>
                        <div className={innerSidebarOpen ? "xl:pl-96 " : "xl:pl-36"}>
                            <div className={`absolute grow px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-crazy-${theme} overflow-y-scroll`}>
                                {/* Main area */}
                                <div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5  lg:px-8">
                                    <div className="flex items-center space-x-5">
                                        <div className="flex-shrink-0">
                                            <div className="relative">
                                                <img
                                                    className="h-16 w-16 rounded-full"
                                                    src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                                    alt=""
                                                />
                                                <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div>
                                            <h1 className="text-2xl font-bold text-gray-900">{selectedItem.businessName}</h1>
                                            <p className="text-sm font-medium text-gray-500">
                                                P.IVA {selectedItem.vat}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div>
                                                <Menu.Button
                                                    className={`mx-1 block rounded-md bg-${theme}-600 px-3 py-2 flex flex-start items-center text-center text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                                                >
                                                    <CogIcon className="mr-2 h-5 w-5 text-white hover:text-white" />
                                                    Azioni
                                                    <ChevronDownIcon
                                                        className="-mr-1 ml-2 h-5 w-5 text-white hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                    <div className="px-1 py-1 ">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                >
                                                                    Invia email
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                >
                                                                    Elimina
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>

                                <div className="mx-auto mt-8 grid  grid-cols-1 gap-6 sm:px-6  lg:grid-flow-col-dense lg:grid-cols-3">
                                    <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                                        {/* Description list*/}
                                        <section aria-labelledby="applicant-information-title">
                                            <div className="bg-white shadow sm:rounded-lg">
                                                <div className="px-4 py-5 sm:px-6">

                                                    <div className='flex flex-row justify-between items-center mb-4'>
                                                        <div className="flex flex-col justify-between">
                                                            <h2 id="timeline-title" className="text-lg font-bold text-gray-900">
                                                                Informazioni sulla società
                                                            </h2>
                                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Leggi o modifica i dati dell'azienda.</p>
                                                        </div>

                                                        <div className='flex flex-row items-center justify-between'>
                                                            <button
                                                                type="button"
                                                                className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                                            >
                                                                <PencilIcon className='h-4 w-4 text-gray-600 hover:text-gray-900' />
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                        <div className="sm:col-span-1">
                                                            <dt className="text-sm font-medium text-gray-500">Sede legale</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{selectedItem.addresses ? <>{selectedItem.addresses[0].street + ", " + selectedItem.addresses[0].number}</> : null}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <dt className="text-sm font-medium text-gray-500">Email address</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{selectedItem.email}</dd>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <dt className="text-sm font-medium text-gray-500">Sito web</dt>
                                                            <dd className="mt-1 text-sm text-gray-900"><a href={selectedItem.website} className='flex flex-row' target="_blank">{selectedItem.website} <ArrowTopRightOnSquareIcon className='h-3 w-3 ml-1' /></a></dd>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <dt className="text-sm font-medium text-gray-500">Telefono</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">{selectedItem.phone}</dd>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                                                    {attachments.map((attachment) => (
                                                                        <li
                                                                            key={attachment.name}
                                                                            className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                                                        >
                                                                            <div className="flex w-0 flex-1 items-center">
                                                                                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                                                <span className="ml-2 w-0 flex-1 truncate">{attachment.name}</span>
                                                                            </div>
                                                                            <div className="ml-4 flex-shrink-0">
                                                                                <a href={attachment.href} className="font-medium text-blue-600 hover:text-blue-500">
                                                                                    Download
                                                                                </a>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="block bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
                                                    >
                                                        Read full application
                                                    </a>
                                                </div>
                                            </div>
                                        </section>

                                        {/* Comments*/}
                                        <section aria-labelledby="notes-title">
                                            <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
                                                <div className="divide-y divide-gray-200">
                                                    <div className="px-4 py-5 sm:px-6">
                                                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                                            Notes
                                                        </h2>
                                                    </div>
                                                    <div className="px-4 py-6 sm:px-6">
                                                        <ul role="list" className="space-y-8">
                                                            {comments.map((comment) => (
                                                                <li key={comment.id}>
                                                                    <div className="flex space-x-3">
                                                                        <div className="flex-shrink-0">
                                                                            <img
                                                                                className="h-10 w-10 rounded-full"
                                                                                src={`https://images.unsplash.com/photo-${comment.imageId}?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <div className="text-sm">
                                                                                <a href="#" className="font-medium text-gray-900">
                                                                                    {comment.name}
                                                                                </a>
                                                                            </div>
                                                                            <div className="mt-1 text-sm text-gray-700">
                                                                                <p>{comment.body}</p>
                                                                            </div>
                                                                            <div className="mt-2 space-x-2 text-sm">
                                                                                <span className="font-medium text-gray-500">{comment.date}</span>{' '}
                                                                                <span className="font-medium text-gray-500">&middot;</span>{' '}
                                                                                <button type="button" className="font-medium text-gray-900">
                                                                                    Reply
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="bg-gray-50 px-4 py-6 sm:px-6">
                                                    <div className="flex space-x-3">
                                                        <div className="flex-shrink-0">
                                                            <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                                                        </div>
                                                        <div className="min-w-0 flex-1">
                                                            <form action="#">
                                                                <div>
                                                                    <label htmlFor="comment" className="sr-only">
                                                                        About
                                                                    </label>
                                                                    <textarea
                                                                        id="comment"
                                                                        name="comment"
                                                                        rows={3}
                                                                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                        placeholder="Add a note"
                                                                        defaultValue={''}
                                                                    />
                                                                </div>
                                                                <div className="mt-3 flex items-center justify-between">
                                                                    <a
                                                                        href="#"
                                                                        className="group inline-flex items-start space-x-2 text-sm text-gray-500 hover:text-gray-900"
                                                                    >
                                                                        <QuestionMarkCircleIcon
                                                                            className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span>Some HTML is okay.</span>
                                                                    </a>
                                                                    <button
                                                                        type="submit"
                                                                        className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                                    >
                                                                        Comment
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
                                        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                            <div className='flex flex-row justify-between items-center mb-4'>
                                                <h2 id="timeline-title" className="text-lg font-bold text-gray-900">
                                                    Considerazioni
                                                </h2>
                                                <div className='flex flex-row items-center justify-between'>
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpen(!open)}
                                                        className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                                    >
                                                        <PencilIcon className='h-4 w-4 text-gray-600 hover:text-gray-900' />
                                                    </button>
                                                </div>

                                            </div>

                                            <WYSIWYG1 />
                                        </div>

                                        <div className="bg-white px-4 py-5 mt-8 shadow sm:rounded-lg sm:px-6">
                                            <div className='flex flex-row justify-between items-center mb-4'>
                                                <h2 id="timeline-title" className="text-lg font-bold text-gray-900">
                                                    Rubrica
                                                </h2>
                                                <div className='flex flex-row items-center justify-between'>
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpen(!open)}
                                                        className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                                    >
                                                        <PlusIcon className='h-4 w-4 text-gray-600 hover:text-gray-900' />
                                                    </button>
                                                </div>

                                            </div>

                                            <ul role="list" className="divide-y divide-gray-100">
                                                {selectedItem.contacts.map((person) => (
                                                    <li key={person.email} className="flex justify-between py-5">
                                                        <div className="flex min-w-0 items-center justify-start">
                                                            <div className='flex flex-col justify-between'>

                                                                <Menu as="div" className="relative inline-block text-left">
                                                                    <div>
                                                                        <Menu.Button
                                                                            className="mx-1 block rounded-md py-2 flex flex-start items-center text-center text-sm font-semibold "
                                                                        >
                                                                            <EllipsisVerticalIcon className='h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-800' />
                                                                        </Menu.Button>
                                                                    </div>
                                                                    <Transition
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-100"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                                            <div className="px-1 py-1 ">
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        >
                                                                                            Invia email
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        >
                                                                                            Chiama
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        >
                                                                                            Modifica
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        >
                                                                                            Elimina
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            </div>
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </Menu>
                                                            </div>
                                                            <img className="h-12 w-12 mr-2 flex-none rounded-full bg-gray-50" src={image} alt="" />
                                                            <div className="min-w-0 flex-auto">
                                                                <p className="text-sm font-semibold leading-6 text-gray-900">{person.fname + " " + person.lname}</p>
                                                                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email ? <div className='flex flex-row justify-start items-center'><EnvelopeIcon className='h-4 w-4 mr-1' />{person.email}</div> : null}</p>
                                                                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.phone ? <div className='flex flex-row justify-start items-center'><PhoneIcon className='h-4 w-4 mr-1' />{person.phone}</div> : null}</p>
                                                            </div>
                                                        </div>
                                                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                                            <p className="text-sm leading-6 text-gray-900">{person.role}</p>
                                                            <div className="mt-1 flex items-center gap-x-1.5">
                                                                <StarIcon className={`h-4 w-4 text-${person.decision ? `yellow` : `gray`}-400`} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </section>


                                </div>

                            </div>
                        </div>
                    </main>


                    <aside className={`inset-y-0 left-72 hidden ${innerSidebarOpen ? `w-96` : `w-236`} overflow-y-auto border-r border-gray-200 xl:block`}>
                        <div className='flex flex-row justify-between'>
                            {innerSidebarOpen ? <div className="pb-4 px-4 mt-4">
                                <button
                                    type="button"
                                    onClick={() => setInnerSidebarOpen(!innerSidebarOpen)}
                                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <ChevronDoubleLeftIcon className="h-3 w-3" />
                                </button>
                            </div> : <div className="pb-4 px-4 mt-4">
                                <button
                                    type="button"
                                    onClick={() => setInnerSidebarOpen(!innerSidebarOpen)}
                                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <ChevronDoubleRightIcon className="h-3 w-3" />
                                </button></div>}
                            {<div className="pb-4 px-4 mt-4">
                                <button
                                    type="button"
                                    onClick={() => setSelectedItem(null)}
                                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <XMarkIcon className="h-3 w-3" />
                                </button>
                            </div>}
                        </div>

                        {innerSidebarOpen ? <div className="pb-4 px-4 mt-4">
                            <h2 className="text-2xl font-extrabold text-gray-900">Clienti</h2>
                            <p className="mt-1 text-sm text-gray-600">La lista dei tuoi <strong>clienti</strong>, scegli quale operazione effettuare.</p>
                            <form className="mt-6 flex gap-x-4" action="#">
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="search" className="sr-only">
                                        Cerca...
                                    </label>
                                    <div className="relative rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input
                                            type="search"
                                            name="search"
                                            id="search"
                                            className={`block w-full rounded-md border-0 bg-gray-100 focus:border-${theme}-600 py-1.5 pl-10  placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                            placeholder="Search"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div> : null}
                        {/* Directory list */}
                        <nav className="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">

                            <ul role="list" className="relative z-0 divide-y divide-gray-200">
                                {customers.data.map((item) => (
                                    <li key={item.id} onClick={() => setSelectedItem(item)}>
                                        <div className={`relative flex items-center ${selectedItem.uuid === item.uuid ? `border-2 border-${theme}-600 ring-inset bg-${theme}-50` : ""} space-x-3 px-6 py-5 hover:bg-${theme}-50`}>
                                            <div className="flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full" src={image} alt="" />
                                            </div>
                                            {innerSidebarOpen ? <div className="min-w-0 flex-1">
                                                <a href="#" className="focus:outline-none">
                                                    {/* Extend touch target to entire panel */}
                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                    <p className="text-sm font-medium text-gray-900">{item.businessName}</p>
                                                    <p className="truncate text-sm text-gray-500">{item.vat}</p>
                                                </a>
                                            </div> : null}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </aside>
                </div> : (<main className={`py-10 w-full bg-crazy-${theme} min-h-screen overflow-y-scroll`}>
                    <Table1 data={data} columns={colonne} config={config} dataCallback={dataCallback} />
                    <div className="px-4 sm:px-6 lg:px-8 py-4 ">
                            <span className={`text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-${theme}-700 text-${theme}-50 rounded-xl`}>
                                &#9675;<XMarkIcon className={`h-4 w-4 text-${theme}-50`} />
                            TECH
                                <span className={`inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-${theme}-800 bg-${theme}-200 rounded-full`}>
                                2
                            </span>
                        </span>
                    </div>

                </main>)}
            </> : <Loader1 />}</>
    )
}

