const customers = {
    total: 20,
    data: [
        {
            id: 0,
            uuid: "136ef852-65b6-4993-bae8-6426e1f70f29",
            businessName: "1 MARIO Informatica SRL",
            vat: "10265700943",
            addresses: [
                {
                    street: "Via Giovanni Bottoni",
                    number: "1",
                    city: "Milano",
                    region: "MI",
                    postalCode: "20141",
                    type: "MAIN"
                },
                {
                    street: "Via della Gloria",
                    number: "16/C/5",
                    city: "Saluzzo",
                    region: "CN",
                    postalCode: "12037",
                    type: "SLAVE"
                },
            ],
            contacts: [
                {
                    fname: "Alain",
                    lname: "Campagno",
                    email: "a.campagno@tribusinformatica.it",
                    phone: "+393406938950",
                    role: "CTO",
                    decision: true
                }
            ],
            email: "supporto@tribusinformatica.it",
            phone: "+390175576419",
            website: "https://tribusinformatica.it",
            tags: ["IT", "Developing", "VoIP"],
            custom: [
                {
                    name: "PEC",
                    type: "TEXTINPUT",
                    value: "tribusadv@pec.it"
                },
                {
                    name: "SDI",
                    type: "TEXTINPUT",
                    value: "MXU49W"
                },
            ],
            notes: "<p>Tipo losco, richiedere pagamento anticipato</p>"
        },
        {
            id: 1,
            uuid: "c70829e2-2f58-4be2-84cd-da0c6e52e489",
            businessName: "2 Tribus Informatica SRL",
            vat: "10265700962",
            addresses: [
                {
                    street: "Via Piero Bottoni",
                    number: "1",
                    city: "Milano",
                    region: "MI",
                    postalCode: "20141",
                    type: "MAIN"
                },
                {
                    street: "Via della Resistenza",
                    number: "16/C/5",
                    city: "Saluzzo",
                    region: "CN",
                    postalCode: "12037",
                    type: "SLAVE"
                },
            ],
            contacts: [
                {
                    fname: "Samuele",
                    lname: "Allasia",
                    email: "s.allasia@tribusinformatica.it",
                    phone: "+393406938950",
                    role: "COO",
                    decision: true
                }
            ],
            email: "info@tribusinformatica.it",
            phone: "+390175576419",
            website: "https://tribusinformatica.it",
            tags: ["IT", "Developing", "VoIP"],
            custom: [
                {
                    name: "PEC",
                    type: "TEXTINPUT",
                    value: "tribusadv@pec.it"
                },
                {
                    name: "SDI",
                    type: "TEXTINPUT",
                    value: "MXU49W"
                },
            ],
            notes: "<p>Tipo losco, richiedere pagamento anticipato</p>"
        },
        {
            "id": 3,
            "uuid": "a5908c1a-4d85-4f09-ba5c-79b7a572f53f",
            "businessName": "Tech Solutions SRL",
            "vat": "98765432101",
            "addresses": [
                {
                    "street": "Via Roma",
                    "number": "25",
                    "city": "Torino",
                    "region": "TO",
                    "postalCode": "10121",
                    "type": "MAIN"
                },
                {
                    "street": "Via Verdi",
                    "number": "8/B",
                    "city": "Aosta",
                    "region": "AO",
                    "postalCode": "11100",
                    "type": "SLAVE"
                }
            ],
            "contacts": [
                {
                    "fname": "Alessia",
                    "lname": "Rossi",
                    "email": "a.rossi@techsolutions.it",
                    "phone": "+391234567890",
                    "role": "CEO",
                    "decision": true
                }
            ],
            "email": "info@techsolutions.it",
            "phone": "+390111223344",
            "website": "https://techsolutions.it",
            "tags": ["Technology", "Innovation", "Software"],
            "custom": [
                {
                    "name": "PEC",
                    "type": "TEXTINPUT",
                    "value": "techsolutions@pec.it"
                },
                {
                    "name": "SDI",
                    "type": "TEXTINPUT",
                    "value": "TSU78V"
                }
            ],
            notes: "<p>Tipo losco, richiedere pagamento anticipato</p>"
        },
        {
            "id": 4,
            "uuid": "b2e93848-7f7b-4d63-a1a7-8b5516e63e3f",
            "businessName": "InnovateTech Solutions",
            "vat": "11223344556",
            "addresses": [
                {
                    "street": "Corso Vittorio Emanuele",
                    "number": "45",
                    "city": "Roma",
                    "region": "RM",
                    "postalCode": "00186",
                    "type": "MAIN"
                },
                {
                    "street": "Via Garibaldi",
                    "number": "10",
                    "city": "Napoli",
                    "region": "NA",
                    "postalCode": "80142",
                    "type": "SLAVE"
                }
            ],
            "contacts": [
                {
                    "fname": "Marco",
                    "lname": "Bianchi",
                    "email": "m.bianchi@innovatetech.it",
                    "phone": "+393334445566",
                    "role": "CTO",
                    "decision": true
                }
            ],
            "email": "info@innovatetech.it",
            "phone": "+390678912345",
            "website": "https://innovatetech.it",
            "tags": ["Innovation", "Technology", "Software"],
            "custom": [
                {
                    "name": "PEC",
                    "type": "TEXTINPUT",
                    "value": "innovatetech@pec.it"
                },
                {
                    "name": "SDI",
                    "type": "TEXTINPUT",
                    "value": "INN45T"
                }
            ],
            notes: "<p>Tipo losco, richiedere pagamento anticipato</p>"
        },
        {
            "id": 5,
            "uuid": "f4c12d07-8f02-4ee9-8f4c-d1528d6a98a5",
            "businessName": "Digital Innovations Ltd",
            "vat": "98765432109",
            "addresses": [
                {
                    "street": "Baker Street",
                    "number": "221B",
                    "city": "London",
                    "region": "LDN",
                    "postalCode": "NW1 6XE",
                    "type": "MAIN"
                },
                {
                    "street": "King's Road",
                    "number": "5",
                    "city": "Manchester",
                    "region": "MAN",
                    "postalCode": "M1 1AE",
                    "type": "SLAVE"
                }
            ],
            "contacts": [
                {
                    "fname": "Emma",
                    "lname": "Smith",
                    "email": "e.smith@digitalinnovations.co.uk",
                    "phone": "+447712345678",
                    "role": "CIO",
                    "decision": true
                }
            ],
            "email": "info@digitalinnovations.co.uk",
            "phone": "+442071234567",
            "website": "https://digitalinnovations.co.uk",
            "tags": ["Digital", "Innovations", "Tech"],
            "custom": [
                {
                    "name": "PEC",
                    "type": "TEXTINPUT",
                    "value": "digitalinnovations@pec.co.uk"
                },
                {
                    "name": "SDI",
                    "type": "TEXTINPUT",
                    "value": "DIG89T"
                }
            ],
            notes: "<p>Tipo losco, richiedere pagamento anticipato</p>"
        },
        {
            "id": 6,
            "uuid": "f93d3d54-0d7a-4e88-8ed3-7b6e2e3d2e6f",
            "businessName": "FutureTech Solutions LLC",
            "vat": "11223344567",
            "addresses": [
                {
                    "street": "Broadway",
                    "number": "123",
                    "city": "New York",
                    "region": "NY",
                    "postalCode": "10001",
                    "type": "MAIN"
                },
                {
                    "street": "Silicon Alley",
                    "number": "7/A",
                    "city": "San Francisco",
                    "region": "CA",
                    "postalCode": "94105",
                    "type": "SLAVE"
                }
            ],
            "contacts": [
                {
                    "fname": "Jennifer",
                    "lname": "Williams",
                    "email": "j.williams@futuretechsolutions.com",
                    "phone": "+11234567890",
                    "role": "CFO",
                    "decision": true
                }
            ],
            "email": "info@futuretechsolutions.com",
            "phone": "+12121234567",
            "website": "https://futuretechsolutions.com",
            "tags": ["FutureTech", "Innovation", "Technology"],
            "custom": [
                {
                    "name": "PEC",
                    "type": "TEXTINPUT",
                    "value": "futuretech@pec.com"
                },
                {
                    "name": "SDI",
                    "type": "TEXTINPUT",
                    "value": "FTS12V"
                }
            ],
            notes: "<p>Tipo losco, richiedere pagamento anticipato</p>"
        },
        {
            "id": 7,
            "uuid": "9a40885d-2fcd-4b96-80f1-2bfaa0df9fb9",
            "businessName": "Smart Innovations Inc",
            "vat": "98765432118",
            "addresses": [
                {
                    "street": "Tech Avenue",
                    "number": "567",
                    "city": "San Jose",
                    "region": "CA",
                    "postalCode": "95110",
                    "type": "MAIN"
                },
                {
                    "street": "Innovation Drive",
                    "number": "9",
                    "city": "Los Angeles",
                    "region": "CA",
                    "postalCode": "90001",
                    "type": "SLAVE"
                }
            ],
            "contacts": [
                {
                    "fname": "Michael",
                    "lname": "Johnson",
                    "email": "m.johnson@smartinnovationsinc.com",
                    "phone": "+14081234567",
                    "role": "CTO",
                    "decision": true
                }
            ],
            "email": "info@smartinnovationsinc.com",
            "phone": "+14251234567",
            "website": "https://smartinnovationsinc.com",
            "tags": ["Smart", "Innovations", "Technology"],
            "custom": [
                {
                    "name": "PEC",
                    "type": "TEXTINPUT",
                    "value": "smartinnovations@pec.com"
                },
                {
                    "name": "SDI",
                    "type": "TEXTINPUT",
                    "value": "SMI56X"
                }
            ],
            notes: "<p>Tipo losco, richiedere pagamento anticipato</p>"
        },
        {
            "id": 8,
            "uuid": "62f667a0-01c3-49a5-8f5b-3a0d78b72667",
            "businessName": "Innovative Solutions Ltd",
            "vat": "11223344578",
            "addresses": [
                {
                    "street": "High Street",
                    "number": "321",
                    "city": "London",
                    "region": "LDN",
                    "postalCode": "W1A 1AA",
                    "type": "MAIN"
                },
                {
                    "street": "Tech Park",
                    "number": "15",
                    "city": "Manchester",
                    "region": "MAN",
                    "postalCode": "M2 1AE",
                    "type": "SLAVE"
                }
            ],
            "contacts": [
                {
                    "fname": "Sophia",
                    "lname": "Miller",
                    "email": "s.miller@innovativesolutions.co.uk",
                    "phone": "+447823456789",
                    "role": "COO",
                    "decision": true
                }
            ],
            "email": "info@innovativesolutions.co.uk",
            "phone": "+442081234567",
            "website": "https://innovativesolutions.co.uk",
            "tags": ["Innovation", "Technology", "Solutions"],
            "custom": [
                {
                    "name": "PEC",
                    "type": "TEXTINPUT",
                    "value": "innovativesolutions@pec.co.uk"
                },
                {
                    "name": "SDI",
                    "type": "TEXTINPUT",
                    "value": "INS55T"
                }
            ],
            notes: "<p>Tipo losco, richiedere pagamento anticipato</p>"
        }






    ]
}

export { customers }