//Importo Componenti react
import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PrivateRoute from '../PrivateRoute'


import Header1 from '../components/navigations/headers/header-1'
import Sidebar1 from '../components/navigations/sidebars/sidebar-1'

export default function Layout1() {
    
    let company = useSelector(state => state.company)
    let navigate = useNavigate()
    const [show, setShow] = useState(false)

    useEffect(() => {
        if(navigate) {
            setShow(true)
        }

        return navigate
    }, [navigate])

    return (
        <PrivateRoute>
            {show ? <div className="min-h-screen min-w-screen"> 
                <Header1/>
                <div className="flex flex-row justify-start">
                    <Sidebar1 />
                    <Outlet context={company.theme}/>
                </div>
            </div> : null}
        </PrivateRoute>
    )
}

