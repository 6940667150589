import {
    CpuChipIcon,
    ArrowPathIcon,
    DocumentDuplicateIcon,
    ArrowsRightLeftIcon,
    CheckCircleIcon,
    BuildingOffice2Icon,
    DocumentCheckIcon,
    QueueListIcon,
    CogIcon,
    BookmarkIcon,
    CurrencyEuroIcon,
    BriefcaseIcon, EnvelopeIcon, KeyIcon, UserGroupIcon, UsersIcon, ClipboardDocumentIcon, MegaphoneIcon,
    PhoneIcon,
    IdentificationIcon
} from '@heroicons/react/24/outline'

import { LuCalendarDays, LuTicket, LuWorkflow, LuListTodo, LuWarehouse, LuShoppingBag, LuGroup, LuShoppingCart, LuArrowRightLeft, LuCalendarClock, LuFileKey, LuBookMarked, LuFileText } from "react-icons/lu";

const crm = [
    { name: 'Società', href: '/crm/customers', icon: BuildingOffice2Icon, slug: 'societa' },
    { name: 'Appuntamenti', href: '/crm/appointments', icon: LuCalendarDays, slug: 'appuntamenti' },
    { name: 'Opportunità', href: '/crm/opportunities', icon: CheckCircleIcon, slug: 'opportunita' }, //https://codepen.io/robstinson/pen/BaLQQdX
    { name: 'Preventivi', href: '/crm/quotations', icon: DocumentCheckIcon, slug: 'preventivi' },
    { name: 'Listino', href: '/crm/price-list', icon: QueueListIcon, slug: 'listino' },
    { name: 'Knowledge', href: '/crm/knowledge', icon: BookmarkIcon, slug: 'knowledge' },
    { name: 'Impostazioni', href: '/crm/options', icon: CogIcon, slug: 'crmImpostazioni' },
]

const progetti = [
    { name: 'Ticket', href: '#', icon: LuTicket, slug: 'ticket' },
    { name: 'ODL', href: '#', icon: BriefcaseIcon, slug: 'odl' },
    { name: 'Progetti', href: '#', icon: LuWorkflow, slug: 'progetti' },
    { name: 'Tasks', href: '#', icon: LuListTodo, slug: 'tasks' },
    { name: 'Impostazioni', href: '#', icon: CogIcon, slug: 'progettiImpostazioni' },
]

const pim = [
    { name: 'Prodotti', href: '#', icon: LuShoppingBag, slug: 'prodotti' },
    { name: 'Collezioni', href: '#', icon: LuGroup, slug: 'collezioni' },
    { name: 'DDT', href: '#', icon: ClipboardDocumentIcon, slug: 'ddt' },
    { name: 'Ordini', href: '#', icon: LuShoppingCart, slug: 'ordini' },
    { name: 'Stock', href: '#', icon: LuWarehouse, slug: 'stock' },
    { name: 'Movimentazioni', href: '#', icon: LuArrowRightLeft, slug: 'movimentazioni' },
    { name: 'Impostazioni', href: '#', icon: CogIcon, slug: 'pimImpostazioni' },
]  

const finance = [
    { name: 'Attivo', href: '#', icon: ArrowPathIcon, slug: 'attivo' },
    { name: 'Passivo', href: '#', icon: ArrowPathIcon, slug: 'passivo' },
    { name: 'Bozze', href: '#', icon: DocumentDuplicateIcon, slug: 'bozze' },
    { name: 'Flusso cassa', href: '/finance/cash-flow', icon: ArrowsRightLeftIcon, slug: 'flusso-cassa' },
    { name: 'Prima nota', href: '/finance/prima-nota', icon: CurrencyEuroIcon, slug: 'prima-nota' },
    { name: 'Scadenziario', href: '#', icon: LuCalendarClock, slug: 'scadenziario' },
    { name: 'Impostazioni', href: '/finance/options', icon: CogIcon, slug: 'finaceImpostazioni' },
]  

const hr = [
    { name: 'Dipendenti', href: '#', icon: UserGroupIcon, slug: 'dipendenti' },
    { name: 'Documenti', href: '#', icon: DocumentCheckIcon, slug: 'documenti' },
    { name: 'Corsi', href: '#', icon: LuBookMarked, slug: 'corsi' },
    { name: 'Comunicazioni', href: '#', icon: MegaphoneIcon, slug: 'comunicazioni' },
    { name: 'Impostazioni', href: '#', icon: CogIcon, slug: 'hrImpostazioni' },
]  

const aimodels = [
    { name: 'I miei modelli', href: '/ai-models/my-models', icon: CpuChipIcon, slug: 'mymodels' },
    { name: 'Channels', href: '/ai-models/channels', icon: ArrowsRightLeftIcon, slug: 'channels' },
]  

const superadmin = [
    { name: 'Licenze', href: '/superadmin/licenses', icon: KeyIcon, slug: 'licenses' },
    { name: 'Utenti', href: '/superadmin/users', icon: UserGroupIcon, slug: 'users' },
    { name: 'Aziende', href: '/superadmin/companies', icon: UserGroupIcon, slug: 'companies' },
    { name: 'AI Models', href: '/superadmin/ai-models', icon: CpuChipIcon, slug: 'ai-models' },
    { name: 'Logs', href: '/superadmin/logs', icon: LuFileText, slug: 'logs' },
]  

const options = [
    { name: 'Utenti', href: '/options/users', icon: UsersIcon, slug: 'users' },
    { name: 'Team', href: '/options/teams', icon: UserGroupIcon, slug: 'teams' },
    { name: 'Informazioni società', href: '/options/company', icon: BuildingOffice2Icon, slug: 'informazioniSocieta' },
    { name: 'Email', href: '/options/email', icon: EnvelopeIcon, slug: 'email' },
    { name: 'Licenza', href: '/options/license', icon: LuFileKey, slug: 'license' },
]

const profile = [
    { name: 'Dettagli', href: '/options/profile', icon: UsersIcon, slug: 'profile' },
    { name: 'Dati personali', href: '/options/personal-details', icon: IdentificationIcon, slug: 'personal' },
    { name: 'Contatti', href: '/options/personal-contacts', icon: PhoneIcon, slug: 'contacts' },
]

export { crm, progetti, pim, finance, options, hr, superadmin ,aimodels, profile}