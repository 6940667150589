import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition, Dialog, RadioGroup } from '@headlessui/react'
import { ChevronDownIcon, ArrowUpTrayIcon, PlusCircleIcon, CursorArrowRaysIcon, ArrowDownTrayIcon, MagnifyingGlassIcon, PaperClipIcon, XMarkIcon, QuestionMarkCircleIcon, UserIcon, HandThumbUpIcon, CheckIcon, CogIcon, PlusIcon, StarIcon, EllipsisVerticalIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, UserGroupIcon, NoSymbolIcon, PaperAirplaneIcon, PencilIcon, TrashIcon, EnvelopeOpenIcon, EnvelopeIcon, PhoneIcon, KeyIcon, InformationCircleIcon, CurrencyEuroIcon, HomeModernIcon } from '@heroicons/react/24/outline'
import { LuEuro, LuMailCheck, LuPiggyBank } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebar, toggleNavbar, sidebarSubMenu } from '../../actions'
import { regimiFiscali, esigibilitaIVA } from '../../assets/data/regimiFiscali'
import image from '../../assets/img/default.svg'
import aruba from '../../assets/img/aruba.png'

import axios from 'axios';

import { parseCookies } from 'nookies'

import Table1 from '../../components/tables/table-1'
import Loader1 from '../../components/utility/loader-1'
import Modal2 from '../../components/modals/modal-2'
import Form1 from '../../components/forms/form-1'
import Notification1 from '../../components/notifications/notification-1'
import WYSIWYG1 from '../../components/utility/wysiwyg'

const frequencies = [
    { value: 'monthly', label: 'Monthly' },
    { value: 'annually', label: 'Annually' },
]
const tiers = [
    {
        name: 'Starter',
        id: 'tier-starter',
        href: '#',
        featured: false,
        description: 'All your essential business finances, taken care of.',
        price: { monthly: '$15', annually: '$144' },
        mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts'],
    },
    {
        name: 'Scale',
        id: 'tier-scale',
        href: '#',
        featured: true,
        description: 'The best financial services for your thriving business.',
        price: { monthly: '$60', annually: '$576' },
        mainFeatures: [
            'Advanced invoicing',
            'Easy to use accounting',
            'Mutli-accounts',
            'Tax planning toolkit',
            'VAT & VATMOSS filing',
            'Free bank transfers',
        ],
    },
    {
        name: 'Growth',
        id: 'tier-growth',
        href: '#',
        featured: false,
        description: 'Convenient features to take your business to the next level.',
        price: { monthly: '$30', annually: '$288' },
        mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts', 'Tax planning toolkit'],
    },
]
const sections = [
    {
        name: 'Catered for business',
        features: [
            { name: 'Tax Savings', tiers: { Starter: true, Scale: true, Growth: true } },
            { name: 'Easy to use accounting', tiers: { Starter: true, Scale: true, Growth: true } },
            { name: 'Multi-accounts', tiers: { Starter: '3 accounts', Scale: 'Unlimited accounts', Growth: '7 accounts' } },
            { name: 'Invoicing', tiers: { Starter: '3 invoices', Scale: 'Unlimited invoices', Growth: '10 invoices' } },
            { name: 'Exclusive offers', tiers: { Starter: false, Scale: true, Growth: true } },
            { name: '6 months free advisor', tiers: { Starter: false, Scale: true, Growth: true } },
            { name: 'Mobile and web access', tiers: { Starter: false, Scale: true, Growth: false } },
        ],
    },
    {
        name: 'Other perks',
        features: [
            { name: '24/7 customer support', tiers: { Starter: true, Scale: true, Growth: true } },
            { name: 'Instant notifications', tiers: { Starter: true, Scale: true, Growth: true } },
            { name: 'Budgeting tools', tiers: { Starter: true, Scale: true, Growth: true } },
            { name: 'Digital receipts', tiers: { Starter: true, Scale: true, Growth: true } },
            { name: 'Pots to separate money', tiers: { Starter: false, Scale: true, Growth: true } },
            { name: 'Free bank transfers', tiers: { Starter: false, Scale: true, Growth: false } },
            { name: 'Business debit card', tiers: { Starter: false, Scale: true, Growth: false } },
        ],
    },
]

export default function License() {

    const cookies = parseCookies()
    let company = JSON.parse(cookies['@company'])
    let theme = company.theme
    let userCookie = JSON.parse(cookies['@user'])
    let user = userCookie.user
    let jwt = userCookie.jwt

    const [frequency, setFrequency] = useState(frequencies[0])

    const [selectedUser, setSelectedUser] = useState(null)
    const [innerSidebarOpen, setInnerSidebarOpen] = useState(true)
    const [data, setData] = useState({
        meta: {
            total: 0
        },
        data: [] 
    })

    const [currentOption, setCurrentOption] = useState(0) 

    const [open, setOpen] = useState(false)
    const [tabs, setTabs] = useState([
        { name: 'Attivi', slug: 'attivi', count: 0, current: true },
        { name: 'Invitati', slug: 'invitati', count: 0, current: false },
        { name: 'Disattivi', slug: 'disattivati', count: 0, current: false },
    ])
    const [openInvitation, setOpenInvitation] = useState(false)
    const [openResendPassword, setOpenResendPassword] = useState(false)
    const [openBlock, setOpenBlock] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [notification, setNotification] = useState({
        title: "",
        description: "",
        type: "success"
    })
    const [showNotification, setShowNotification] = useState(false)
    const [loadingItem, setLoadingItem] = useState(false)

    const [companyData, setCompanyData] = useState(null)

    const getCompanyInformation = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/companies/profile`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: company.uuid, // Utilizza data.company.id al posto di ${ company.id }
                    }
                }
            );
            if (response.data) {
                setCompanyData(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getCompanyInformation()
    }, []);


    const handleTabChange = (slug) => {
        const updatedTabs = tabs.map(tab => ({
            ...tab,
            current: tab.slug === slug,
        }));
        setTabs(updatedTabs);
    };

    const [invitationSuccess, setInvitationSuccess] = useState(null)



 

    const editUser = async data => {
        try {
            setInvitationSuccess(null)
            data.uuid = selectedUser.uuid
            const response = await axios.patch(
                `${process.env.REACT_APP_API_KEY}/users/edit`, {
                user: data,
            },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                }
            );
            if (response.data) {
                setLoadingItem(true)
                getCompanyInformation()
                setSelectedUser(response.data)
                setTimeout(() => {
                    setLoadingItem(false)
                    setNotification({
                        title: "Conferma",
                        description: "Utente modificatə con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }, 1000)
                
            }
        } catch (e) {
            console.error(e)
        }
    }

    let configForm = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Modica i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "businessName",
                name: "businessName",
                label: "Ragione sociale",
                class: "sm:col-span-6",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.businessName : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "vat",
                name: "vat",
                label: "Partita IVA",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.vat : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "fiscalCode",
                name: "fiscalCode",
                label: "Codice fiscale",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.fiscalCode : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "infoEmail",
                name: "infoEmail",
                label: "Email generale",
                class: "sm:col-span-3",
                type: "email",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.infoEmail : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci email",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "billingEmail",
                name: "billingEmail",
                label: "Email fatturazione",
                class: "sm:col-span-3",
                type: "email",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.billingEmail : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci email",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "phone",
                name: "phone",
                label: "Telefono",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.phone : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "fax",
                name: "fax",
                label: "Fax",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.fax : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "website",
                name: "website",
                label: "Sito web",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.fax : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },

        ],
        cancelText: "Annulla",
        submitText: "Modifica",
        submit: data => editUser(data)
    }

    let configFormFatturazione = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Modica i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "taxRegime",
                name: "taxRegime",
                label: "Regime fiscale",
                class: "sm:col-span-3",
                type: "select",
                value: null,
                options: regimiFiscali ,
                defaultValue: companyData ? companyData.taxRegime : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci regime fiscale",
                autoComplete: "taxRegime",
                extra: null
            },
            {
                id: "taxCollectability",
                name: "taxCollectability",
                label: "Esigibilità IVA",
                class: "sm:col-span-3",
                type: "select",
                value: null,
                options: esigibilitaIVA ,
                defaultValue: companyData ? companyData.taxCollectability : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci esigibilità IVA",
                autoComplete: "textRegime",
                extra: null
            },
            {
                id: "pec",
                name: "pec",
                label: "PEC",
                class: "sm:col-span-3",
                type: "email",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.billingEmail : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci pec",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "sdiCode",
                name: "sdiCode",
                label: "Codice SDI",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.sdiCode : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci codice SDI",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            

        ],
        cancelText: "Annulla",
        submitText: "Modifica",
        submit: data => editUser(data)
    }

    const colonne = [
        {
            name: "Nome",
            dataField: null,
            formatter: (item) => {
                return (
                    <div className="flex items-center cursor-pointer" onClick={() => {
                        setSelectedUser(item)
                    }}>
                        <div className="h-11 w-11 flex-shrink-0">
                            <img className="h-11 w-11 rounded-full" src={item.image ? item.image : image} alt="" />
                        </div>
                        <div className="ml-4">
                            <div className="font-medium text-gray-900 flex">{item.fname + " " + item.lname} {item.permission === "ADMIN" ? <StarIcon className='h-4 w-4 ml-2 text-yellow-600' /> : null}
                                {(!item.confirmed && item.enabled) ? <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 ml-4 bg-white">
                                    <svg className="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                                        <circle cx={3} cy={3} r={3} />
                                    </svg>
                                    Invitato
                                </span> : null}
                            </div>
                            <div className="mt-1 text-gray-500">{item.jobTitle}</div>
                        </div>
                    </div>
                )

            }
        },
        {
            name: "Contatti",
            dataField: null,
            formatter: (item) => {
                return (
                    <div className="flex flex-col cursor-pointer" onClick={() => {
                        setSelectedUser(item)
                    }}>
                        <div className="flex flex-row items-center">
                            <EnvelopeIcon className="w-4 h-4 mr-2" />{item.email}
                        </div>
                        {item.phone ? <div className="flex flex-row items-center">
                            <PhoneIcon className="w-4 h-4 mr-2" />{item.phone}
                        </div> : null}
                    </div>
                )

            }
        },
        {
            name: "Team",
            dataField: "team",
            sort: true
        },
        {
            name: "Azioni",
            dataField: null,
            formatter: (item) => {
                return (
                    <>{item.uuid !== user.uuid ? <div className="flex items-center">
                        <EnvelopeOpenIcon className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer' onClick={() => {
                            setOpenInvitation(true)
                        }} />
                        
                    </div> : <p className='text-gray-400 text-sm'>Non puoi effettuare operazioni su te stesso.</p>}</>
                )

            }
        },
    ]

    const config = {
        title: "Unità",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700">
                    La lista delle <strong>unità societarie</strong>, scegli quale operazione effettuare.
                </p>
            )
        },
        extra: () => {
            return (
                <>
                    <Menu as="div" className="relative inline-block text-left">
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                <img src={aruba} className='w-16 mr-2' /> <span className="text-nowrap text-ellipsis overflow-hidden">Fatturazione di Aruba</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                CSV .csv
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className={`flex rounded-md bg-${theme}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                    >
                        <PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />Aggiungi
                    </button>
                </>
            )
        },
        pagination: true,
        sizePerPage: 25,
        perPage: [5, 10, 25, 50, 75, 100],
        export: true,
        rowClick: null
    }

    const configBank = {
        title: "Banche",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700">
                    La lista dei <strong>conti bancari</strong>, scegli quale operazione effettuare.
                </p>
            )
        },
        extra: () => {
            return (
                <>
                    <Menu as="div" className="relative inline-block text-left">
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                <img src={aruba} className='w-16 mr-2' /> <span className="text-nowrap text-ellipsis overflow-hidden">Fatturazione di Aruba</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                CSV .csv
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className={`flex rounded-md bg-${theme}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                    >
                        <PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />Aggiungi
                    </button>
                </>
            )
        },
        pagination: true,
        sizePerPage: 25,
        perPage: [5, 10, 25, 50, 75, 100],
        export: true,
        rowClick: null
    }


 


    return (
        <>
            {showNotification ? <Notification1 config={notification} show={showNotification} setShow={setShowNotification} /> : null}

                        {/**Modali di conferma */}

            <main className={`py-10 w-full bg-crazy-${theme} min-h-screen overflow-y-scroll`}></main>

                        
                       

                   
            </>

            
    )
}

