//Importo Componenti react
import React, { useState } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


//LAYOUTS
import Layout1 from './layouts/layout-1';

//AUTH
import Login from './screens/auth/login';
import ConfirmAccount from './screens/auth/confirmAccount';

//OPTIONS
import Users from './screens/options/users'
import Teams from './screens/options/teams';
import Company from './screens/options/company';
import License from './screens/options/license';
import MyProfile from './screens/options/myprofile';

//SUPERADMIN
import Licenses from './screens/superadmin/licenses'
import LicensesStats from './screens/superadmin/licenseStats'
import SuperAdminLogs from './screens/superadmin/logs'
import SuperAdminUsers from './screens/superadmin/users'
import SuperadminCompanies from './screens/superadmin/companies'
import SuperadminAIModels from './screens/superadmin/aimodels'

//CRM
import Customers from './screens/crm/customers';

//FINANCE
import PrimaNota from './screens/finance/primaNota'

//UTILITY
import NotFound from './screens/auth/404'
import Loader1 from './components/utility/loader-1';
import Landing from './screens/landing';
import ForgotPassword from './screens/auth/forgotPassword';

//AI MODELS
import MyModels from './screens/ai-models/mymodels'
import Channels from './screens/ai-models/channels'




const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    exact: true,
  },
  {
    path: "/confirm-account/:token",
    element: <ConfirmAccount />,
    exact: true,
  },
  {
    path: "/crm",
    element: <Layout1 />,
    errorElement: <NotFound />,
    exact: true,
    children: [
      {
        path: "/crm/customers",
        element: <Customers />,
      },
    ],
  },
  {
    path: "/ai-models",
    element: <Layout1 />,
    errorElement: <NotFound />,
    exact: true,
    children: [
      {
        path: "/ai-models/my-models",
        element: <MyModels />,
      },
      {
        path: "/ai-models/channels",
        element: <Channels />,
      },
    ],
  },
  {
    path: "/superadmin",
    element: <Layout1 />,
    errorElement: <NotFound />,
    exact: true,
    children: [
      {
        path: "/superadmin/licenses",
        element: <Licenses />,
      },
      {
        path: "/superadmin/licenses/stats/:id",
        element: <LicensesStats />,
      },
      {
        path: "/superadmin/logs",
        element: <SuperAdminLogs />,
      },
      {
        path: "/superadmin/users",
        element: <SuperAdminUsers />,
      },
      {
        path: "/superadmin/companies",
        element: <SuperadminCompanies />,
      },
      {
        path: "/superadmin/ai-models",
        element: <SuperadminAIModels />,
      },
    ],
  },
  {
    path: "/finance",
    element: <Layout1 />,
    errorElement: <NotFound />,
    exact: true,
    children: [
      {
        path: "/finance/cash-flow",
        element: <Users />,
      },
      {
        path: "/finance/prima-nota",
        element: <PrimaNota />,
      },
      {
        path: "/finance/options",
        element: <Users />,
      },
    ],
  },
  {
    path: "/options",
    element: <Layout1 />,
    errorElement: <NotFound />,
    exact: true,
    children: [
      {
        path: "/options/users",
        element: <Users />,
      },
      {
        path: "/options/teams",
        element: <Teams />,
      },
      {
        path: "/options/company",
        element: <Company />,
      },
      {
        path: "/options/license",
        element: <License />,
      },
      {
        path: "/options/profile",
        element: <MyProfile />,
      },
    ], 
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <NotFound />,
  },
  {
    path: "/recover-password",
    element: <ForgotPassword />,
    errorElement: <NotFound />,
  },
])


const App = (props) => {

  // Managing auth
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }
  // ./Managing auth

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  )
}
export default App
