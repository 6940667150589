import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import avatar from '../../assets/img/default.svg'
import background from '../../assets/img/login-bg.webp'
import { RadioGroup } from '@headlessui/react'
import axios from 'axios'
import { BriefcaseIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import ops from '../../assets/img/ops.svg'

//Gestione dati nei cookies
import { setCookie } from "nookies";
import { AES, enc } from "crypto-js";
import Footer1 from "../../components/navigations/footers/footer-1";


const colors = [
    { name: 'Green', bgColor: 'bg-green-500', selectedColor: 'ring-green-500', value: 'green' },
    { name: 'Lime', bgColor: 'bg-lime-500', selectedColor: 'ring-lime-500', value: 'lime' },
    { name: 'Yellow', bgColor: 'bg-yellow-500', selectedColor: 'ring-yellow-500', value: 'green' },
    { name: 'Orange', bgColor: 'bg-orange-500', selectedColor: 'ring-orange-500', value: 'orange' },
    { name: 'Red', bgColor: 'bg-red-500', selectedColor: 'ring-red-500', value: 'red' },
    { name: 'Pink', bgColor: 'bg-pink-500', selectedColor: 'ring-pink-500', value: 'pink' },
    { name: 'Violet', bgColor: 'bg-violet-500', selectedColor: 'ring-violet-500', value: 'violet' },
    { name: 'Cyan', bgColor: 'bg-cyan-500', selectedColor: 'ring-cyan-500', value: 'cyan' },
    { name: 'Blue', bgColor: 'bg-blue-500', selectedColor: 'ring-blue-500', value: 'blue' },
    { name: 'Slate', bgColor: 'bg-slate-500', selectedColor: 'ring-slate-500', value: 'slate' },
]

export default function ConfirmAccount() {

    let user  = useSelector(state => state.auth ? state.auth.user : state.auth)
    let jwt  = useSelector(state => state.auth ? state.auth.jwt : state.auth)
    const dispatch = useDispatch();


    const { token } = useParams();


    const navigate = useNavigate(); // Ottieni l'istanza di history
    const [email, setEmail] = useState("")
    const [err, setErr] = useState(null)
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [phone, setPhone] = useState("")
    const [jobTitle, setJobTitle] = useState("")
    const [loaded, setLoaded] = useState(false)
    const [confirmRegistration, setConfirmRegistration] = useState(false)
    const [error, setError] = useState(false)

    const getData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/users/get-token-information`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    params: {
                        t: token// Utilizza data.company.id al posto di ${ company.id }
                    }
                }
            );
            if (response.data) {
                if(response.data.success) {
                    setEmail(response.data.user.email)
                    setLoaded(true)
                } else {
                    console.log("Errore")
                }
            }
        } catch (e) {
            setError(true)
            console.error(e);
        }
    }

    useEffect(() => {
        getData()
    }, [loaded])





    const completeRegistration = async () => {
        try {
            if (email !== '') {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/auth/signup`,
                    {
                        email: email,
                        fname: fname,
                        lname: lname,
                        phone: phone,
                        jobTitle: jobTitle,
                        token: token
                    },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.data) {
                    
                    setConfirmRegistration(true)
                }
            } else {
                setErr('Si è verificato un errore, controlla i dati inseriti e riprova.');
            }
        } catch (e) {
            console.error(e);
            setErr('Si è verificato un errore, controlla i dati inseriti e riprova.');
        }
    };

    
    return (
        <div className="h-screen flex flex-col">
            {loaded ? <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-24">
                        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                            <img
                                className="mx-auto h-24 w-auto"
                                src={process.env.REACT_APP_LOGO}
                                alt="Luigis"
                            />
                            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Crea il tuo account
                            </h2>
                            <p className="text-sm text-gray-400">Ti chiediamo un paio di dati per iniziare, potrai inserire il resto in seguito da <strong>Il mio profilo</strong> 🖖🏻</p>
                        </div>

                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-lg">

                    {confirmRegistration ? <div>
                        <p className="text-sm text-gray-900">Attivazione del profilo effettuata con successo, clicca sul pulsante per <strong>accedere</strong> a 4BIZ.</p> 
                        <button
                                        type="button"
                                onClick={() => navigate("/login")}
                                className="flex w-full mt-4 justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 border-2 border-green-600 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Vai a login
                                    </button></div>: <form className="grid mt-4 max-w-screen grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6 items-center" >
                                <div className="sm:col-span-3">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Nome
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            value={fname}
                                            onChange={e => setFname(e.target.value)}
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Cognome
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            value={lname}
                                            onChange={e => setLname(e.target.value)}
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            disabled
                                            className="block w-full rounded-md border-0 py-1.5 pl-10 bg-gray-100 text-gray-400 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                            value={email}
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
                                        Posizione lavorativa
                                    </label>
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <BriefcaseIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input
                                            type="text"
                                            name="jobTitle"
                                            id="jobTitle"
                                            value={jobTitle}
                                            onChange={e => setJobTitle(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                            placeholder="Team leader"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
                                        Telefono
                                    </label>
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                            placeholder="+39 333 123 12312"
                                        />
                                    </div>
                                </div>
                                

                        <div className="sm:col-span-6 flex flex-row">
                                    <button
                                        type="reset"
                                        className="flex w-full mt-4 justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-green-600 shadow-sm hover:bg-green-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Indietro
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => completeRegistration()}
                                        className="flex w-full mt-4 justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Procedi
                                    </button>
                                </div>
                            </form>}
                            
                            
                            
                        </div>

            </div> : <>
                <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 min-h-screen py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
                    <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                        <div className="relative">
                            <div className="absolute">
                                <div className="">
                                    <h1 className="my-2 text-gray-800 font-bold text-2xl">
                                        Ops! Sembra che il token sia scaduto 🤭
                                    </h1>
                                    <Link to={`/login`}>
                                        <button
                                            className="flex rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                        >Login</button>
                                    </Link>

                                </div>
                            </div>
                            <div>
                                <h1 className="text-9xl font-extrabold text-green-600/20 p-24 " >404</h1>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={ops} className="h-72 w-auto" alt="cat" />
                    </div>
                </div>
            </>}

                    <Footer1 />
             
        </div>
    )
}
