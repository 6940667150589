export default function Button({ type, right, left, bottom, top, icon, title, onClick }) {


    return (
        <button
            type={type ? type : 'button'}
            onClick={onClick ? onClick : null}
            className={`flex rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 ${right ? 'mr-' + right : null} ${left ? 'ml-' + left : null} ${top ? 'mt-' + top : null} ${bottom ? 'mb-' + bottom : null}`}
        >
            {icon ? icon : null}{title ? title : ""}
        </button>
    )
}

