import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition, Dialog } from '@headlessui/react'
import { ChevronDownIcon, ArrowUpTrayIcon, PlusCircleIcon, CursorArrowRaysIcon, ArrowDownTrayIcon, MagnifyingGlassIcon, PaperClipIcon, XMarkIcon, QuestionMarkCircleIcon, UserIcon, HandThumbUpIcon, CheckIcon, CogIcon, PlusIcon, StarIcon, EllipsisVerticalIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, UserGroupIcon, NoSymbolIcon, PaperAirplaneIcon, PencilIcon, TrashIcon, EnvelopeOpenIcon, EnvelopeIcon, PhoneIcon, UserCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline'

import { useDispatch, useSelector } from 'react-redux'
import image from '../../assets/img/default.svg'
import aruba from '../../assets/img/aruba.png'

import axios from 'axios';


import Table1 from '../../components/tables/table-1'
import Loader1 from '../../components/utility/loader-1'
import Modal2 from '../../components/modals/modal-2'
import Form1 from '../../components/forms/form-1'


export default function Teams() {

    const user = useSelector(state => state.auth.user)
    const jwt = useSelector(state => state.auth.jwt)


    const [selectedItem, setSelectedItem] = useState(null)

    const [data, setData] = useState(null)
    const [users, setUsers] = useState(null)
    const [open, setOpen] = useState(false)
    const [tabs, setTabs] = useState([
        { name: 'Attivi', slug: 'attivi', count: 0, current: true },
        { name: 'Invitati', slug: 'invitati', count: 0, current: false },
        { name: 'Disattivi', slug: 'disattivati', count: 0, current: false },
    ])
    const [openInvitation, setOpenInvitation] = useState(false)
    const [openResendPassword, setOpenResendPassword] = useState(false)
    const [openBlock, setOpenBlock] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openReactivate, setOpenReactivate] = useState(false)

    const dispatch = useDispatch();
    const theme = useSelector(state => state.company.theme)
    const company = useSelector(state => state.company)

    const getUsers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/users/fetchInSelect`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: company.uuid, // Utilizza data.company.id al posto di ${ company.id }
                    }
                }
            );
            if (response.data) {

                setUsers(response.data);
            }
        } catch (e) {

        }
    }

    const getTeams = async (perPage, currentPage, sorted, sortedBy, search) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/teams/fetch`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: company.uuid, // Utilizza data.company.id al posto di ${ company.id }
                        limit: perPage,
                        page: currentPage,
                        sorted: sorted,
                        sortedBy: sortedBy,
                        search: search
                    }
                }
            );
            if (response.data) {
                setData(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getTeams(25, 1, "asc", "name", "")
        getUsers()
    }, []);


    const dataCallback = async (array, perPage, currentPage, sorted, sortedBy, search) => {
        getTeams(perPage, currentPage, sorted, sortedBy, search)
    }

    const config = {
        title: "Teams",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700">
                    La lista dei <strong>teams</strong>, scegli quale operazione effettuare.
                </p>
            )
        },
        extra: () => {
            return (
                <>
                    <Menu as="div" className="relative inline-block text-left">
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                <img src={aruba} className='w-16 mr-2' /> <span className="text-nowrap text-ellipsis overflow-hidden">Fatturazione di Aruba</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                CSV .csv
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className={`flex rounded-md bg-${theme}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                    >
                        <PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />Aggiungi
                    </button>
                </>
            )
        },
        pagination: true,
        sizePerPage: 25,
        perPage: [5, 10, 25, 50, 75, 100],
        export: true,
        rowClick: (item) => {
            setSelectedItem(item)
        },
    }

    const colonne = [
        {
            name: "Nome",
            dataField: "name",
        },
        {
            name: "Team leader",
            dataField: null,
            formatter: (item) => {
                return (
                    <div className="flex flex-col">
                        <div className="flex flex-row">
                            <UserCircleIcon className="w-4 h-4 mr-2" />{item.teamleader.name}
                        </div>
                        <div className="flex flex-row">
                            <EnvelopeIcon className="w-4 h-4 mr-2" />{item.teamleader.email}
                        </div>
                    </div>
                )

            }
        },
    ]

    const handleTabChange = (slug) => {
        const updatedTabs = tabs.map(tab => ({
            ...tab,
            current: tab.slug === slug,
        }));
        setTabs(updatedTabs);
    };

    const addTeam = async data => {
        try {
            data.systemUser = user
            data.addToCompany = company.uuid
            const response = await axios.post(
                `${process.env.REACT_APP_API_KEY}/teams/create`,
                data,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                }
            );
            if (response.data) {
                getTeams(25, 1, "asc", "name", "")
                getUsers()
            }
        } catch (e) {
            console.error(e)
        }
    }

    const sendInvitation = async data => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_KEY}/users/sendInvitation`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: data.uuid, // Utilizza data.company.id al posto di ${ company.id }
                    }
                }
            );
            if (response.data) {
                setData(response.data);
            }
        } catch (e) {
            console.error(e)
        }
    }

    const resendPassword = async data => {
        console.log("Resend password")
    }

    const blockUser = async data => {
        console.log("Blcok user")
    }

    const deleteUser = async data => {
        console.log("Delete user")
    }

    const reactivateUser = async data => {
        console.log("Reactivate user")
    }

    let configForm = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Inserisci i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "name",
                name: "name",
                label: "Nome",
                class: "sm:col-span-6",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">Inserisci un nome per il team, es. Produzione.</p>
                    )
                }
            },
            {
                id: "description",
                name: "description",
                label: "Descrizione",
                class: "sm:col-span-6",
                type: "textarea",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
                rows: 5,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">La descrizione del team ha scopo puramente informativo per i partecipanti.</p>
                    )
                },
            },
            {
                id: "teamleader",
                name: "teamleader",
                label: "Team leader",
                class: "sm:col-span-2",
                type: "select",
                value: null,
                options: users ? users.data : null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                extra: null
            },
            {
                id: "approvation",
                name: "approvation",
                label: "Richiede approvazione",
                subLabel: "Richiedi al team leader di confermare alcune operazioni del team",
                class: "sm:col-span-2",
                type: "checkbox",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
                extra: null,
            },
            {
                id: "notification",
                name: "notification",
                label: "Riceve notifiche",
                subLabel: "Invia al team leader notifiche in app di operazioni del team",
                class: "sm:col-span-2",
                type: "checkbox",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
                extra: null,
            },
        ],
        cancelText: "Annulla",
        submitText: "Invia",
        submit: data => addTeam(data)
    }

    return (
            <main className={`py-10 w-full bg-crazy-${theme} min-h-screen overflow-y-scroll`}>
                <div className='px-8 mb-8'>
                    <div className="sm:hidden ">
                    <Modal2 title={"Manda invito"} description={"Sei sicurə di voler inviare nuovamente l'invito di registrazione?"} dataCallbackConfirm={() => sendInvitation()} dataCallbackReset={() => setOpenInvitation(false)} open={openInvitation} setOpen={setOpenInvitation} />
                    <Modal2 title={"Invia reset password"} description={"Sei sicurə di voler inviare il reset della password?"} dataCallbackConfirm={() => resendPassword()} dataCallbackReset={() => setOpenResendPassword(false)} open={openResendPassword} setOpen={setOpenInvitation} />
                    <Modal2 title={"Blocca utente"} description={"Sei sicurə di voler bloccare l'utente?"} dataCallbackConfirm={() => blockUser()} dataCallbackReset={() => setOpenBlock(false)} open={openBlock} setOpen={setOpenBlock} />
                    <Modal2 title={"Elimina utente"} description={"Sei sicurə di voler eliminare l'utente?"} dataCallbackConfirm={() => deleteUser()} dataCallbackReset={() => setOpenDelete(false)} open={openDelete} setOpen={setOpenDelete} />
                    <Modal2 title={"Riattiva utente"} description={"Sei sicurə di voler riattivare l'utente?"} dataCallbackConfirm={() => reactivateUser()} dataCallbackReset={() => setOpenReactivate(false)} open={openReactivate} setOpen={setOpenReactivate} />
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                       
                    </div>
                    
                </div>
               {data ? <Table1 data={data} columns={colonne} config={config} dataCallback={dataCallback} /> : <Loader1/>}

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                        Nuovo team
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className={`relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-${theme}-500 focus:ring-offset-2`}
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                <Form1 config={configForm} />
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            </main>
    )
}

