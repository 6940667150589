import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/20/solid'

export default function Modal1({ config, open, setOpen }) {

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {config ? <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all  ${
                                (() => {
                                    switch (config.size) {
                                        case 'SM':
                                            return `sm:max-w-lg`;
                                        case 'MD':
                                            return `sm:max-w-2xl`;
                                        case 'LG':
                                            return `sm:max-w-4xl`;
                                        case 'XL':
                                            return `sm:max-w-7xl`;
                                        default:
                                            return `sm:max-w-lg`;
                                    }
                                })()
                                } sm:p-6`}>
                                <div>
                                    {(() => {
                                        switch (config.type) {
                                            case 'SUCCESS':
                                                return (<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                                </div>);
                                            case 'DANGER':
                                                return (<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                                </div>);
                                            case 'EDIT':
                                                return (<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                                    <PencilIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                                </div>);
                                            case 'NONE':
                                                return null;
                                            default:
                                                return null;
                                        }
                                    })()}
                                    
                                    <div className="mt-3 sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base text-center font-semibold leading-6 text-gray-900">
                                            {config.title}
                                        </Dialog.Title>
                                        {config.formatter ? config.formatter() : null}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div> : null}
            </Dialog>
        </Transition.Root>
    )
}
