const sidebarReducer = (state = true, action) => {
    switch (action.type) {
        case 'TOGGLE_SIDEBAR':
            return action.payload;
        default:
            return state;
    }
};

const sidebarMenuReducer = (state = "societa", action) => {
    switch (action.type) {
        case 'TOGGLE_MENU_SIDEBAR':
            return action.payload;
        default:
            return state;
    }
};

export { sidebarReducer, sidebarMenuReducer };
