import { ChartBarIcon, BriefcaseIcon, CubeIcon, CurrencyDollarIcon, ChartPieIcon, UsersIcon, ShieldCheckIcon, CpuChipIcon } from '@heroicons/react/24/outline';

const header = [
    {
        id: 0,
        name: "Vendite",
        slug: "CRM",
        initial: "societa",
        path: "/crm",
        icon: <ChartBarIcon className="h-6 w-6 text-gray-400 mr-2" />  // Icona per Vendite
    },
    {
        id: 1,
        name: "Progetti",
        slug: "PROGETTI",
        initial: "ticket",
        path: "/progetti",
        icon: <BriefcaseIcon className="h-6 w-6 text-gray-400 mr-2" />  // Icona per Progetti
    },
    {
        id: 2,
        name: "Magazzino",
        slug: "PIM",
        initial: "prodotti",
        path: "/pim",
        icon: <CubeIcon className="h-6 w-6 text-gray-400 mr-2" />  // Icona per Magazzino
    },
    {
        id: 3,
        name: "Finanze",
        slug: "FINANCE",
        initial: "attivo",
        path: "/finance",
        icon: <CurrencyDollarIcon className="h-6 w-6 text-gray-400 mr-2" />  // Icona per Finanze
    },
    {
        id: 4,
        name: "Analytics",
        slug: "BI",
        initial: "stats",
        path: "/bi",
        icon: <ChartPieIcon className="h-6 w-6 text-gray-400 mr-2" />  // Icona per Analytics
    },
    {
        id: 5,
        name: "Risorse Umane",
        slug: "HR",
        initial: "employees",
        path: "/hr",
        icon: <UsersIcon className="h-6 w-6 text-gray-400 mr-2" />  // Icona per Risorse Umane
    },
    {
        id: 6,
        name: "AI models",
        slug: "AI",
        initial: "ai-models",
        path: "/ai-models/my-models",
        icon: <CpuChipIcon className="h-6 w-6 text-gray-400 mr-2" />  // Icona per Synapse GPT
    },
    {
        id: 7,
        name: "Superadmin",
        slug: "SUPERADMIN",
        initial: "superadmin",
        path: "/superadmin",
        icon: <ShieldCheckIcon className="h-6 w-6 text-gray-400 mr-2" />  // Icona per Superadmin
    },
]

export { header }