import { Fragment, useState, useEffect } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { ChartBarSquareIcon, ShieldCheckIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import axios from 'axios';

//Importo le componenti di 4BIZ
import Table1 from '../../components/tables/table-1'
import Loader1 from '../../components/utility/loader-1'
import Modal2 from '../../components/modals/modal-2'
import Form1 from '../../components/forms/form-1'
import Button from '../../components/utility/button'
import Notification1 from '../../components/notifications/notification-1'


export default function MyModels() {

    const user = useSelector(state => state.auth.user)
    const jwt = useSelector(state => state.auth.jwt)

    const navigate = useNavigate()


    const [selectedItem, setSelectedItem] = useState(null)

    const [data, setData] = useState(null)
    const [openLicenseEdit, setOpenLicenseEdit] = useState(false)
    const [openAssing, setOpenAssign] = useState(false)
    const [openLicense, setOpenLicense] = useState(false)
    const [openCapability, setOpenCapability] = useState(false)
    
    const [tabs, setTabs] = useState([
        { name: 'Attivi', slug: 'attivi', count: 0, current: true },
        { name: 'Invitati', slug: 'invitati', count: 0, current: false },
        { name: 'Disattivi', slug: 'disattivati', count: 0, current: false },
    ])
    const [openInvitation, setOpenInvitation] = useState(false)
    const [openResendPassword, setOpenResendPassword] = useState(false)
    const [openBlock, setOpenBlock] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openReactivate, setOpenReactivate] = useState(false)
    const [licenseInSelect, setLicenseInSelect] = useState(null)
    const [selectedAssignLicense, setSelectedAssignLicense] = useState(null)
    const [selectedAssignSuggestedPrice, setSelectedAssignSuggestedPrice] = useState(0)

    const [notification, setNotification] = useState({
        title: "",
        description: "",
        type: "success"
    })

    const dispatch = useDispatch();
    const theme = useSelector(state => state.company.theme)
    const company = useSelector(state => state.company)


    const getLicenses = async (perPage, currentPage, sorted, sortedBy, search) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/license/fetch`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: company.uuid, // Utilizza data.company.id al posto di ${ company.id }
                        limit: perPage,
                        page: currentPage,
                        sorted: sorted,
                        sortedBy: sortedBy,
                        search: search
                    }
                }
            );
            if (response.data) {
                setData(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getLicensesSelect = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/license/fetchInSelect`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }                
                }
            );
            if (response.data) {
                setLicenseInSelect(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        getLicenses(25, 1, "asc", "name", "")
        getLicensesSelect()
    }, []);


    const dataCallback = async (array, perPage, currentPage, sorted, sortedBy, search) => {
        getLicenses(perPage, currentPage, sorted, sortedBy, search)
    }

    const config = {
        title: "Licenze",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700">
                    La lista delle <strong>licenze 4BIZ </strong>, scegli quale operazione effettuare.
                </p>
            )
        },
        extra: () => {
            return (
                <>
                    <Button
                        onClick={() => setOpenLicense(true)}
                        right="1"
                        icon={<PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />}
                        title="Aggiungi"
                    />
                    <Button
                        onClick={() => setOpenAssign(true)}
                        icon={<PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />} 
                        title="Assegna" 
                    />
                </>
            )
        },
        pagination: true,
        sizePerPage: 25,
        perPage: [5, 10, 25, 50, 75, 100],
        export: true,
        rowClick: (item) => {
            setSelectedItem(item)
            setOpenLicenseEdit(true)
        },
    }

    // Funzione per mappare la ricorrenza
    const formatRecurrency = (recurrency) => {
        const recurrencyMap = {
            '1M': 'Mensile',
            '2M': 'Bimestre',
            '3M': 'Trimestre',
            '6M': 'Semestre',
            '1A': 'Annuale',
            '2A': 'Biannuale'
        };
        return recurrencyMap[recurrency] || '';
    };

    // Funzione per mappare il contatore
    const formatCounter = (counter) => {
        const counterMap = {
            'USER': 'Utente',
            'DEVICE': 'Device',
            'COMPANY': 'Azienda'
        };
        return counterMap[counter] || '';
    };

    // Funzione per formattare il prezzo
    const formatPrice = (price) => {
        if (!price || price.length === 0) {
            return "Nessun prezzo disponibile";
        }

        const ultimoPrezzo = price[price.length - 1];

        return (
            <div className="flex flex-col">
                {ultimoPrezzo.value.toFixed(2)}{"€ /"}
                {formatRecurrency(ultimoPrezzo.recurrency)}{" /"}
                {formatCounter(ultimoPrezzo.counter)}
            </div>
        );
    };

    // Funzione per formattare la ricorrenza
    const formatPriceCounter = (price) => {
        console.log(price)
        if (!price || price.length === 0) {
            return "Nessun prezzo disponibile";
        }

        const ultimoPrezzo = price[price.length - 1];


        return formatRecurrency(ultimoPrezzo.recurrency) + " /" + formatCounter(ultimoPrezzo.counter)
        /*return (
            <span className="flex flex-col">
                {formatRecurrency(ultimoPrezzo.recurrency)}{" /"}
                {formatCounter(ultimoPrezzo.counter)}
            </span>
        );*/
    };

    const formatPermission = (permissions, section) => {
        if (!permissions || permissions.length === 0) {
            return null;
        }

        const lastPermission = permissions[permissions.length - 1]; // L'ultimo oggetto di permission
        if (!lastPermission.sections || lastPermission.sections.length === 0) {
            return null;
        }

        const sectionFound = lastPermission.sections.find(s => s.value === section);
        return sectionFound ? sectionFound.enabled : null; // Restituisce il valore di `enabled`
    };

    // Definizione delle colonne
    const colonne = [
        {
            name: "Nome",
            dataField: "name",
        },
        {
            name: "Prezzo",
            dataField: "price",
            formatter: (item) => formatPrice(item.price)
        },
        {
            name: "Azioni",
            dataField: "",
            formatter: (item) => (
                <div className="flex flex-row">
                    <Tooltip id="send-tooltip" />
                    <ChartBarSquareIcon
                        data-tooltip-id="send-tooltip"
                        data-tooltip-content="Andamento licenza"
                        className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer'
                        onClick={() => navigate(`/superadmin/licenses/stats/${item.uuid}`)}
                    />
                    <Tooltip id="send-tooltip" />
                    <ShieldCheckIcon
                        data-tooltip-id="send-tooltip"
                        data-tooltip-content="Permessi licenza"
                        className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer'
                        onClick={() => {
                            setSelectedItem(item);
                            setOpenCapability(true);
                        }}
                    />
                    <Tooltip id="delete-tooltip" />
                    <TrashIcon
                        data-tooltip-id="delete-tooltip"
                        data-tooltip-content="Elimina licenza"
                        className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer'
                        onClick={() => {
                            setSelectedItem(item);
                            setOpenDelete(true);
                        }}
                    />
                </div>
            )
        }
    ];

    const handleTabChange = (slug) => {
        const updatedTabs = tabs.map(tab => ({
            ...tab,
            current: tab.slug === slug,
        }));
        setTabs(updatedTabs);
    };

    const addLicense = async data => {
        try {
            data.systemUser = user
            data.addToCompany = company.uuid
            const response = await axios.post(
                `${process.env.REACT_APP_API_KEY}/license/create`,
                data,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                }
            );
            if (response.data) {
                getLicenses(25, 1, "asc", "name", "")
            }
        } catch (e) {
            console.error(e)
        }
    }

    const assingLicense = async data => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_KEY}/license/assignLicense`, data,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: data.uuid, // Utilizza data.company.id al posto di ${ company.id }
                    }
                }
            );
            if (response.data) {
                setData(response.data);
            }
        } catch (e) {
            console.error(e)
        }
    }

    const resendPassword = async data => {
        console.log("Resend password")
    }

    const blockUser = async data => {
        console.log("Blcok user")
    }

    const deleteLicense = async () => {
        try {
            if (selectedItem) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/users/deleteLicense`, {
                    uuid: selectedItem.uuid,
                    company: company.uuid
                },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    }
                );
                if (response.data) {
                    setOpenDelete(false)
                    setNotification({
                        title: "Conferma",
                        description: "Utente eliminatə con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }
            }
        } catch (e) {
            console.error(e)
            setNotification({
                title: "Errore",
                description: "Ops! Qualcosa è andato storto",
                type: "error",
            })
            setShowNotification(true)
        }
    }

    const assingPermissionLicense = async data => {
        try {
            if (selectedItem) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/license/assignPermissionToLicense`, {
                    uuid: selectedItem.uuid,
                    permissions: data
                },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    }
                );
                if (response.data) {
                    getLicenses(25, 1, "asc", "name", "")
                    setOpenDelete(false)
                    
                }
            }
        } catch (e) {
            console.error(e)
            setNotification({
                title: "Errore",
                description: "Ops! Qualcosa è andato storto",
                type: "error",
            })
            setShowNotification(true)
        }
    }

    const reactivateUser = async data => {
        console.log("Reactivate user")
    }

    const editLicense = async => {
        console.log("Ciao")
    }

    let configForm = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Inserisci i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "name",
                name: "name",
                label: "Nome",
                class: "sm:col-span-6",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">Inserisci un nome per la licenza, es. 4BIZ Basic.</p>
                    )
                }
            },
            {
                id: "description",
                name: "description",
                label: "Descrizione",
                class: "sm:col-span-6",
                type: "textarea",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
                rows: 5,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">La descrizione della licenza ha scopo puramente informativo.</p>
                    )
                },
            },
            {
                id: "price",
                name: "price",
                label: "Prezzo €",
                class: "sm:col-span-6",
                type: "number",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                step: "0.01",
                placeholder: "Inserisci prezzo",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999
            },
            {
                id: "type",
                name: "type",
                label: "Tipologia",
                class: "sm:col-span-6",
                type: "select",
                value: null,
                options: [
                    {
                        value: "RECURRENT",
                        label: "Ricorrente"
                    },
                    {
                        value: "LIFETIME",
                        label: "Lifetime"
                    }
                ],
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: null
            },
            {
                id: "recurrency",
                name: "recurrency",
                label: "Ricorrenza",
                class: "sm:col-span-6",
                type: "select",
                value: null,
                dependency: 'type',
                dependencyTrigger: 'RECURRENT',
                options: [
                    {
                        value: "1M",
                        label: "1 mese"
                    },
                    {
                        value: "3M",
                        label: "3 mesi"
                    },
                    {
                        value: "6M",
                        label: "6 mesi"
                    },
                    {
                        value: "1A",
                        label: "1 anno"
                    },
                    {
                        value: "2A",
                        label: "2 anni"
                    },
                ],
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: null
            },
            {
                id: "trial",
                name: "trial",
                label: "Periodo di prova",
                class: "sm:col-span-2",
                type: "number",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: 0,
                onChange: null,
                required: true,
                disabled: false,
                step: "0",
                placeholder: "Inserisci trial",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">Il periodo di licenza gratuita, lascia 0 se non vuoi attivare il trial.</p>
                    )
                },
            },
            {
                id: "counter",
                name: "counter",
                label: "Contatore",
                class: "sm:col-span-2",
                type: "select",
                value: null,
                options: [
                    {
                        value: "USER",
                        label: "Utente"
                    },
                    {
                        value: "DEVICE",
                        label: "Device"
                    },
                    {
                        value: "COMPANY",
                        label: "Azienda"
                    }
                ],
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">Il periodo di licenza gratuita, lascia 0 se non vuoi attivare il trial.</p>
                    )
                },
            },
        ],
        cancelText: "Annulla",
        submitText: "Invia",
        submit: data => addLicense(data)
    }

    let configFormEdit = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Inserisci i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "name",
                name: "name",
                label: "Nome",
                class: "sm:col-span-6",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedItem ? selectedItem.name : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">Inserisci un nome per la licenza, es. 4BIZ Basic.</p>
                    )
                }
            },
            {
                id: "description",
                name: "description",
                label: "Descrizione",
                class: "sm:col-span-6",
                type: "textarea",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedItem ? selectedItem.description : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
                rows: 5,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">La descrizione della licenza ha scopo puramente informativo.</p>
                    )
                },
            },
            {
                id: "price",
                name: "price",
                label: "Prezzo €",
                class: "sm:col-span-6",
                type: "number",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedItem ? selectedItem.price : null,
                onChange: null,
                required: true,
                disabled: false,
                step: "0.01",
                placeholder: "Inserisci prezzo",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999
            },
            {
                id: "type",
                name: "type",
                label: "Tipologia",
                class: "sm:col-span-6",
                type: "select",
                value: null,
                options: [
                    {
                        value: "RECURRENT",
                        label: "Ricorrente"
                    },
                    {
                        value: "LIFETIME",
                        label: "Lifetime"
                    }
                ],
                defaultValue: selectedItem ? selectedItem.type : null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: null
            },
            {
                id: "recurrency",
                name: "recurrency",
                label: "Ricorrenza",
                class: "sm:col-span-6",
                type: "select",
                value: null,
                dependency: 'type',
                dependencyTrigger: 'RECURRENT',
                options: [
                    {
                        value: "1M",
                        label: "1 mese"
                    },
                    {
                        value: "3M",
                        label: "3 mesi"
                    },
                    {
                        value: "6M",
                        label: "6 mesi"
                    },
                    {
                        value: "1A",
                        label: "1 anno"
                    },
                    {
                        value: "2A",
                        label: "2 anni"
                    },
                ],
                defaultValue: selectedItem ? selectedItem.recurrency : null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: null
            },
            {
                id: "trial",
                name: "trial",
                label: "Periodo di prova",
                class: "sm:col-span-2",
                type: "number",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedItem ? selectedItem.trial : null,
                onChange: null,
                required: true,
                disabled: false,
                step: "0",
                placeholder: "Inserisci trial",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">Il periodo di licenza gratuita, lascia 0 se non vuoi attivare il trial.</p>
                    )
                },
            },
            {
                id: "counter",
                name: "counter",
                label: "Contatore",
                class: "sm:col-span-2",
                type: "select",
                value: null,
                options: [
                    {
                        value: "USERS",
                        label: "Utenti"
                    },
                    {
                        value: "DEVICE",
                        label: "Device"
                    },
                    {
                        value: "COMPANY",
                        label: "Azienda"
                    }
                ],
                defaultValue: selectedItem ? selectedItem.counter : null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: null
            },
        ],
        cancelText: "Annulla",
        submitText: "Invia",
        submit: data => editLicense(data)
    }

    // Usa un useEffect per aggiornare il prezzo suggerito quando cambia la licenza selezionata
    useEffect(() => {
        if (selectedAssignLicense && data && data.data) {
            const selectedLicense = data.data.find(item => item.uuid === selectedAssignLicense);
            if (selectedLicense && selectedLicense.price && selectedLicense.price.length > 0) {
                setSelectedAssignSuggestedPrice(selectedLicense.price[0].value); // Imposta il prezzo suggerito
            }
        }
    }, [selectedAssignLicense, data]);


    let configFormAssign = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Inserisci la mail di chi vuoi <strong>invitare</strong> a collaborare nel tuo spazio 4BIZ.</p>
            )
        },
        fields: [
            {
                id: "email",
                name: "email",
                label: "Email",
                class: "sm:col-span-6",
                type: "email",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci email aziendale dell'utente",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "company",
                name: "company",
                label: "Azienda",
                class: "sm:col-span-6",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci la partita IVA",
                autoComplete: "vat",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "license",
                name: "license",
                label: "Licenza",
                class: "sm:col-span-2",
                type: "select",
                value: null,
                options: licenseInSelect ? licenseInSelect.data : [],
                defaultValue: null,
                onChange: value => setSelectedAssignLicense(value),
                required: true,
                disabled: false,
                autoComplete: "license",
                extra: null
            },
            {
                id: "price",
                name: "price",
                label: `Prezzo ${selectedAssignLicense && data ? formatPriceCounter(data.data.filter(item => item.uuid === selectedAssignLicense)[0].price) : ''}`,
                class: "sm:col-span-2",
                type: "number",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedAssignLicense && data ? data.data.filter(item => item.uuid === selectedAssignLicense)[0].price[0].value : '',
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci prezzo",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999, 
            },
            {
                id: "counter",
                name: "counter",
                label: `Limite ${selectedAssignLicense && data ? formatCounter(data.data.filter(item => item.uuid === selectedAssignLicense)[0].price[0].counter) : ''}`,
                class: "sm:col-span-2",
                type: "number",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci valore",
                autoComplete: "valore",
                autoCapitalize: true,
                size: 999,
            },
        ],
        cancelText: "Annulla",
        submitText: "Assegna",
        submit: data => assingLicense(data),
        onReset: () => setOpenAssign(false)
    }

    let configFormCapability = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Scegli a quali sezioni <strong>possono accedere</strong> gli utenti nel loro spazio 4BIZ.</p>
            )
        },
        fields: [
            {
                id: "CRM",
                name: "CRM",
                label: "Vendite",
                class: "sm:col-span-6",
                type: "checkbox",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: () => formatPermission(selectedItem.permission, "CRM"),
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "vendite",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "PROGETTI",
                name: "PROGETTI",
                label: "Progetti",
                class: "sm:col-span-6",
                type: "checkbox",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: () => formatPermission(selectedItem.permission, "PROGETTI"),
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "progetti",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "PIM",
                name: "PIM",
                label: "Magazzino",
                class: "sm:col-span-6",
                type: "checkbox",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: () => formatPermission(selectedItem.permission, "PIM"),
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "magazzino",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "FINANCE",
                name: "FINANCE",
                label: "Finanze",
                class: "sm:col-span-6",
                type: "checkbox",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: () => formatPermission(selectedItem.permission, "FINANCE"),
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "finanze",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "BI",
                name: "BI",
                label: "Analytics",
                class: "sm:col-span-6",
                type: "checkbox",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: () => formatPermission(selectedItem.permission, "BI"),
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "analytics",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "HR",
                name: "HR",
                label: "Risorse Umane",
                class: "sm:col-span-6",
                type: "checkbox",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: () => formatPermission(selectedItem.permission, "HR"),
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "hr",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "AI",
                name: "AI",
                label: "AI models",
                class: "sm:col-span-6",
                type: "checkbox",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: () => formatPermission(selectedItem.permission, "AI"),
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "aimodels",
                autoCapitalize: true,
                size: 999,
            }
        
        ],
        cancelText: "Annulla",
        submitText: "Conferma",
        submit: data => assingPermissionLicense(data)
    }

    const [showNotification, setShowNotification] = useState(false)

    return (
        <main className={`py-10 w-full bg-crazy-green min-h-screen overflow-y-scroll`}>

            {showNotification ? <Notification1 config={notification} show={showNotification} setShow={setShowNotification} /> : null}


            <div className='px-8 mb-8'>
                <div className="sm:hidden ">
                    <Modal2 title={"Invia reset password"} description={"Sei sicurə di voler inviare il reset della password?"} dataCallbackConfirm={() => resendPassword()} dataCallbackReset={() => setOpenResendPassword(false)} open={openResendPassword} setOpen={setOpenInvitation} />
                    <Modal2 title={"Blocca utente"} description={"Sei sicurə di voler bloccare l'utente?"} dataCallbackConfirm={() => blockUser()} dataCallbackReset={() => setOpenBlock(false)} open={openBlock} setOpen={setOpenBlock} />
                    {selectedItem ? <Modal2 title={"Elimina licenza"} description={"Sei sicurə di voler eliminare la licenza? Non potrai più assegnare questo tipo di licenza, ma le licenze già assegnate resteranno attive."} dataCallbackConfirm={() => deleteLicense()} dataCallbackReset={() => setOpenDelete(false)} open={openDelete} setOpen={setOpenDelete} /> : null}
                    <Modal2 title={"Riattiva utente"} description={"Sei sicurə di voler riattivare l'utente?"} dataCallbackConfirm={() => reactivateUser()} dataCallbackReset={() => setOpenReactivate(false)} open={openReactivate} setOpen={setOpenReactivate} />
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}

                </div>

            </div>
            {data ? <Table1 data={data} columns={colonne} config={config} dataCallback={dataCallback} /> : <Loader1 />}
            
            {/*Slider di creazione della licenza */}
            <Transition.Root show={openLicense} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpenLicense}>
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                        Crea licenza
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className={`relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                                            onClick={() => setOpenLicense(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                <Form1 config={configForm} />
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/*Slider di edit generico della licenza */}
            {selectedItem ? <Transition.Root show={openLicenseEdit} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpenLicenseEdit}>
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                        Modifica licenza
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className={`relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                                            onClick={() => setOpenLicenseEdit(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                <Form1 config={configFormEdit} />
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root> : null}

            {/*Slider di assegnazione della licenza */}
            <Transition.Root show={openAssing} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpenAssign}>
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                        Assegna licenza
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className={`relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                                            onClick={() => setOpenLicense(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                <Form1 config={configFormAssign} />
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/*Slider di edit PERMESSI della licenza */}
            {selectedItem ? <Transition.Root show={openCapability} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpenCapability}>
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                        Permessi licenze
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className={`relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                                            onClick={() => setOpenCapability(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                <Form1 config={configFormCapability} />
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root> : null}
        </main>
    )
}

