import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition, Dialog, RadioGroup } from '@headlessui/react'
import { ChevronDownIcon, ArrowUpTrayIcon, PlusCircleIcon, CursorArrowRaysIcon, ArrowDownTrayIcon, MagnifyingGlassIcon, PaperClipIcon, XMarkIcon, QuestionMarkCircleIcon, UserIcon, HandThumbUpIcon, CheckIcon, CogIcon, PlusIcon, StarIcon, EllipsisVerticalIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, UserGroupIcon, NoSymbolIcon, PaperAirplaneIcon, PencilIcon, TrashIcon, EnvelopeOpenIcon, EnvelopeIcon, PhoneIcon, KeyIcon, InformationCircleIcon, CurrencyEuroIcon, HomeModernIcon } from '@heroicons/react/24/outline'
import { LuEuro, LuMailCheck, LuPiggyBank } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebar, toggleNavbar, sidebarSubMenu } from '../../actions'
import { regimiFiscali, esigibilitaIVA } from '../../assets/data/regimiFiscali'
import image from '../../assets/img/default.svg'
import aruba from '../../assets/img/aruba.png'

import axios from 'axios';

import Cookies  from 'js-cookie'

import Table1 from '../../components/tables/table-1'
import Loader1 from '../../components/utility/loader-1'
import Modal2 from '../../components/modals/modal-2'
import Form1 from '../../components/forms/form-1'
import Notification1 from '../../components/notifications/notification-1'
import WYSIWYG1 from '../../components/utility/wysiwyg'

export default function Company() {

    let company = JSON.parse(Cookies.get('company'))
    let theme = company.theme
    let userCookie = JSON.parse(Cookies.get('user'))
    let user = userCookie.user
    let jwt = userCookie.jwt

    const [selectedUser, setSelectedUser] = useState(null)
    const [innerSidebarOpen, setInnerSidebarOpen] = useState(true)
    const [data, setData] = useState({
        meta: {
            total: 0
        },
        data: [] 
    })

    const [currentOption, setCurrentOption] = useState(0) 

    const [open, setOpen] = useState(false)
    const [tabs, setTabs] = useState([
        { name: 'Attivi', slug: 'attivi', count: 0, current: true },
        { name: 'Invitati', slug: 'invitati', count: 0, current: false },
        { name: 'Disattivi', slug: 'disattivati', count: 0, current: false },
    ])
    const [openInvitation, setOpenInvitation] = useState(false)
    const [openResendPassword, setOpenResendPassword] = useState(false)
    const [openBlock, setOpenBlock] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [notification, setNotification] = useState({
        title: "",
        description: "",
        type: "success"
    })
    const [showNotification, setShowNotification] = useState(false)
    const [loadingItem, setLoadingItem] = useState(false)

    const [companyData, setCompanyData] = useState(null)

    const getCompanyInformation = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/companies/profile`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: company.uuid, // Utilizza data.company.id al posto di ${ company.id }
                    }
                }
            );
            if (response.data) {
                setCompanyData(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getCompanyInformation()
    }, []);


    const handleTabChange = (slug) => {
        const updatedTabs = tabs.map(tab => ({
            ...tab,
            current: tab.slug === slug,
        }));
        setTabs(updatedTabs);
    };

    const [invitationSuccess, setInvitationSuccess] = useState(null)



 

    const editUser = async data => {
        try {
            setInvitationSuccess(null)
            data.uuid = selectedUser.uuid
            const response = await axios.patch(
                `${process.env.REACT_APP_API_KEY}/users/edit`, {
                user: data,
            },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                }
            );
            if (response.data) {
                setLoadingItem(true)
                getCompanyInformation()
                setSelectedUser(response.data)
                setTimeout(() => {
                    setLoadingItem(false)
                    setNotification({
                        title: "Conferma",
                        description: "Utente modificatə con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }, 1000)
                
            }
        } catch (e) {
            console.error(e)
        }
    }

    let configForm = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Modica i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "businessName",
                name: "businessName",
                label: "Ragione sociale",
                class: "sm:col-span-6",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.businessName : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "vat",
                name: "vat",
                label: "Partita IVA",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.vat : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "fiscalCode",
                name: "fiscalCode",
                label: "Codice fiscale",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.fiscalCode : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "infoEmail",
                name: "infoEmail",
                label: "Email generale",
                class: "sm:col-span-3",
                type: "email",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.infoEmail : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci email",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "billingEmail",
                name: "billingEmail",
                label: "Email fatturazione",
                class: "sm:col-span-3",
                type: "email",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.billingEmail : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci email",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "phone",
                name: "phone",
                label: "Telefono",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.phone : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "fax",
                name: "fax",
                label: "Fax",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.fax : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "website",
                name: "website",
                label: "Sito web",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedUser ? selectedUser.fax : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },

        ],
        cancelText: "Annulla",
        submitText: "Modifica",
        submit: data => editUser(data)
    }

    let configFormFatturazione = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Modica i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "taxRegime",
                name: "taxRegime",
                label: "Regime fiscale",
                class: "sm:col-span-3",
                type: "select",
                value: null,
                options: regimiFiscali ,
                defaultValue: companyData ? companyData.taxRegime : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci regime fiscale",
                autoComplete: "taxRegime",
                extra: null
            },
            {
                id: "taxCollectability",
                name: "taxCollectability",
                label: "Esigibilità IVA",
                class: "sm:col-span-3",
                type: "select",
                value: null,
                options: esigibilitaIVA ,
                defaultValue: companyData ? companyData.taxCollectability : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci esigibilità IVA",
                autoComplete: "textRegime",
                extra: null
            },
            {
                id: "pec",
                name: "pec",
                label: "PEC",
                class: "sm:col-span-3",
                type: "email",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.billingEmail : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci pec",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "sdiCode",
                name: "sdiCode",
                label: "Codice SDI",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: companyData ? companyData.sdiCode : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci codice SDI",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            

        ],
        cancelText: "Annulla",
        submitText: "Modifica",
        submit: data => editUser(data)
    }

    const colonne = [
        {
            name: "Nome",
            dataField: null,
            formatter: (item) => {
                return (
                    <div className="flex items-center cursor-pointer" onClick={() => {
                        setSelectedUser(item)
                    }}>
                        <div className="h-11 w-11 flex-shrink-0">
                            <img className="h-11 w-11 rounded-full" src={item.image ? item.image : image} alt="" />
                        </div>
                        <div className="ml-4">
                            <div className="font-medium text-gray-900 flex">{item.fname + " " + item.lname} {item.permission === "ADMIN" ? <StarIcon className='h-4 w-4 ml-2 text-yellow-600' /> : null}
                                {(!item.confirmed && item.enabled) ? <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 ml-4 bg-white">
                                    <svg className="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                                        <circle cx={3} cy={3} r={3} />
                                    </svg>
                                    Invitato
                                </span> : null}
                            </div>
                            <div className="mt-1 text-gray-500">{item.jobTitle}</div>
                        </div>
                    </div>
                )

            }
        },
        {
            name: "Contatti",
            dataField: null,
            formatter: (item) => {
                return (
                    <div className="flex flex-col cursor-pointer" onClick={() => {
                        setSelectedUser(item)
                    }}>
                        <div className="flex flex-row items-center">
                            <EnvelopeIcon className="w-4 h-4 mr-2" />{item.email}
                        </div>
                        {item.phone ? <div className="flex flex-row items-center">
                            <PhoneIcon className="w-4 h-4 mr-2" />{item.phone}
                        </div> : null}
                    </div>
                )

            }
        },
        {
            name: "Team",
            dataField: "team",
            sort: true
        },
        {
            name: "Azioni",
            dataField: null,
            formatter: (item) => {
                return (
                    <>{item.uuid !== user.uuid ? <div className="flex items-center">
                        <EnvelopeOpenIcon className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer' onClick={() => {
                            setOpenInvitation(true)
                        }} />
                        
                    </div> : <p className='text-gray-400 text-sm'>Non puoi effettuare operazioni su te stesso.</p>}</>
                )

            }
        },
    ]

    const config = {
        title: "Unità",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700">
                    La lista delle <strong>unità societarie</strong>, scegli quale operazione effettuare.
                </p>
            )
        },
        extra: () => {
            return (
                <>
                    <Menu as="div" className="relative inline-block text-left">
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                <img src={aruba} className='w-16 mr-2' /> <span className="text-nowrap text-ellipsis overflow-hidden">Fatturazione di Aruba</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                CSV .csv
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className={`flex rounded-md bg-${theme}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                    >
                        <PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />Aggiungi
                    </button>
                </>
            )
        },
        pagination: true,
        sizePerPage: 25,
        perPage: [5, 10, 25, 50, 75, 100],
        export: true,
        rowClick: null
    }

    const configBank = {
        title: "Banche",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700">
                    La lista dei <strong>conti bancari</strong>, scegli quale operazione effettuare.
                </p>
            )
        },
        extra: () => {
            return (
                <>
                    <Menu as="div" className="relative inline-block text-left">
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                <img src={aruba} className='w-16 mr-2' /> <span className="text-nowrap text-ellipsis overflow-hidden">Fatturazione di Aruba</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                                CSV .csv
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className={`flex rounded-md bg-${theme}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                    >
                        <PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />Aggiungi
                    </button>
                </>
            )
        },
        pagination: true,
        sizePerPage: 25,
        perPage: [5, 10, 25, 50, 75, 100],
        export: true,
        rowClick: null
    }


 


    return (
        <>
            {showNotification ? <Notification1 config={notification} show={showNotification} setShow={setShowNotification} /> : null}

                <div>
                        {/**Modali di conferma */}

                        {(() => {
                            switch (currentOption) {
                                case 0:
                                    return (
                                        <main>
                                            {companyData ? <div className={innerSidebarOpen ? `xl:pl-96 ` : "xl:pl-36"}>
                                                <div className={`absolute grow px-4 py-10 min-h-screen min-w-64 sm:px-6 lg:px-8 lg:py-6 bg-crazy-${theme} overflow-y-scroll`}>
                                                    {/* Main area */}
                                                    <div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5  lg:px-8">
                                                        <div className="flex items-center space-x-5">
                                                            <div className="flex-shrink-0">
                                                                <div className="relative">
                                                                    <img
                                                                        className="h-16 w-16 rounded-full"
                                                                        src={companyData.image ? companyData.image : process.env.REACT_APP_DEFAULT_AVATAR}
                                                                        alt=""
                                                                    />
                                                                    <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h1 className="text-2xl font-bold text-gray-900">{companyData.businessName}</h1>
                                                                <p className="text-sm font-medium text-gray-500">
                                                                    {companyData.vat}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button
                                                                        className={`mx-1 block rounded-md bg-${theme}-600 px-3 py-2 flex flex-start items-center text-center text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                                                                    >
                                                                        <CogIcon className="mr-2 h-5 w-5 text-white hover:text-white" />
                                                                        Azioni
                                                                        <ChevronDownIcon
                                                                            className="-mr-1 ml-2 h-5 w-5 text-white hover:text-white"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </Menu.Button>
                                                                </div>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                                        <div className="px-1 py-1 ">
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        onClick={() => {
                                                                                            setOpenResendPassword(true)
                                                                                        }}
                                                                                    >
                                                                                        <KeyIcon className='h-4 w-4 mr-2' />Reset password
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        onClick={() => {
                                                                                            setOpenBlock(true)
                                                                                        }}
                                                                                    >
                                                                                        <NoSymbolIcon className='h-4 w-4 mr-2' />Blocca utente
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            setOpenDelete(true)
                                                                                        }}
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                    >
                                                                                        <TrashIcon className='h-4 w-4 mr-2' /> Elimina
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        </div>
                                                    </div>

                                                    <div className="mx-auto mt-8  sm:px-6  lg:grid-flow-col-dense lg:grid-cols-3">
                                                        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                                                            {/* Description list*/}
                                                            <section aria-labelledby="applicant-information-title">
                                                                <div className="bg-white shadow sm:rounded-lg">
                                                                    <div className="px-4 py-5 sm:px-6">

                                                                        <div className='flex flex-row justify-between items-center mb-4'>
                                                                            <div className="flex flex-col justify-between">
                                                                                <h2 id="timeline-title" className="text-lg font-bold text-gray-900">
                                                                                    Informazioni società
                                                                                </h2>
                                                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Leggi o modifica i dati della società.</p>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                                                                        <Form1 config={configForm} />

                                                                    </div>

                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : "Loading"}
                                        </main>
                                    );
                                case 1:
                                    return (
                                        <main>
                                            {companyData ? <div className={innerSidebarOpen ? `xl:pl-96 ` : "xl:pl-36"}>
                                                <div className={`absolute grow px-4 py-10 min-h-screen min-w-64 sm:px-6 lg:px-8 lg:py-6 bg-crazy-${theme} overflow-y-scroll`}>
                                                    {/* Main area */}
                                                    <div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5  lg:px-8">
                                                        <div className="flex items-center space-x-5">
                                                            <div className="flex-shrink-0">
                                                                <div className="relative">
                                                                    <img
                                                                        className="h-16 w-16 rounded-full"
                                                                        src={companyData.image ? companyData.image : process.env.REACT_APP_DEFAULT_AVATAR}
                                                                        alt=""
                                                                    />
                                                                    <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h1 className="text-2xl font-bold text-gray-900">{companyData.businessName}</h1>
                                                                <p className="text-sm font-medium text-gray-500">
                                                                    {companyData.vat}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button
                                                                        className={`mx-1 block rounded-md bg-${theme}-600 px-3 py-2 flex flex-start items-center text-center text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                                                                    >
                                                                        <CogIcon className="mr-2 h-5 w-5 text-white hover:text-white" />
                                                                        Azioni
                                                                        <ChevronDownIcon
                                                                            className="-mr-1 ml-2 h-5 w-5 text-white hover:text-white"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </Menu.Button>
                                                                </div>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                                        <div className="px-1 py-1 ">
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        onClick={() => {
                                                                                            setOpenResendPassword(true)
                                                                                        }}
                                                                                    >
                                                                                        <KeyIcon className='h-4 w-4 mr-2' />Reset password
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        onClick={() => {
                                                                                            setOpenBlock(true)
                                                                                        }}
                                                                                    >
                                                                                        <NoSymbolIcon className='h-4 w-4 mr-2' />Blocca utente
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            setOpenDelete(true)
                                                                                        }}
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                    >
                                                                                        <TrashIcon className='h-4 w-4 mr-2' /> Elimina
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        </div>
                                                    </div>

                                                    <div className="mx-auto mt-8  sm:px-6  lg:grid-flow-col-dense lg:grid-cols-3">
                                                        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                                                            {/* Description list*/}
                                                            <section aria-labelledby="applicant-information-title">
                                                                <div className="bg-white shadow sm:rounded-lg">
                                                                    <div className="px-4 py-5 sm:px-6">

                                                                        <div className='flex flex-row justify-between items-center mb-4'>
                                                                            <div className="flex flex-col justify-between">
                                                                                <h2 id="timeline-title" className="text-lg font-bold text-gray-900">
                                                                                    Informazioni sull'utente
                                                                                </h2>
                                                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Leggi o modifica i dati dell'utente.</p>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                                                                        <Form1 config={configFormFatturazione} />

                                                                    </div>

                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : "Loading"}
                                        </main>
                                    );
                                case 2:
                                    return (
                                        <main>
                                            {companyData ? <div className={innerSidebarOpen ? `xl:pl-96 ` : "xl:pl-36"}>
                                                <div className={`absolute  ${innerSidebarOpen ? `width-4biz` : "width-4biz-full"} grow px-4 py-10 min-h-screen min-w-64 sm:px-6 lg:px-8 lg:py-6 bg-crazy-${theme} overflow-y-scroll`}>
                                                    {/* Main area */}
                                                    <div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5  lg:px-8">
                                                        <div className="flex items-center space-x-5">
                                                            <div className="flex-shrink-0">
                                                                <div className="relative">
                                                                    <img
                                                                        className="h-16 w-16 rounded-full"
                                                                        src={companyData.image ? companyData.image : process.env.REACT_APP_DEFAULT_AVATAR}
                                                                        alt=""
                                                                    />
                                                                    <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h1 className="text-2xl font-bold text-gray-900">{companyData.businessName}</h1>
                                                                <p className="text-sm font-medium text-gray-500">
                                                                    {companyData.vat}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button
                                                                        className={`mx-1 block rounded-md bg-${theme}-600 px-3 py-2 flex flex-start items-center text-center text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                                                                    >
                                                                        <CogIcon className="mr-2 h-5 w-5 text-white hover:text-white" />
                                                                        Azioni
                                                                        <ChevronDownIcon
                                                                            className="-mr-1 ml-2 h-5 w-5 text-white hover:text-white"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </Menu.Button>
                                                                </div>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                                        <div className="px-1 py-1 ">
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        onClick={() => {
                                                                                            setOpenResendPassword(true)
                                                                                        }}
                                                                                    >
                                                                                        <KeyIcon className='h-4 w-4 mr-2' />Reset password
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        onClick={() => {
                                                                                            setOpenBlock(true)
                                                                                        }}
                                                                                    >
                                                                                        <NoSymbolIcon className='h-4 w-4 mr-2' />Blocca utente
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            setOpenDelete(true)
                                                                                        }}
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                    >
                                                                                        <TrashIcon className='h-4 w-4 mr-2' /> Elimina
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        </div>
                                                    </div>

                                                    <div className="mx-auto mt-8  sm:px-6  lg:grid-flow-col-dense lg:grid-cols-3">
                                                        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                                                            {/* Description list*/}
                                                            <Table1 data={data} columns={colonne} config={config} dataCallback={null} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : "Loading"}
                                        </main>
                                    );
                                case 3:
                                    return (
                                        <main>
                                            {companyData ? <div className={innerSidebarOpen ? `xl:pl-96 ` : "xl:pl-36"}>
                                                <div className={`absolute  ${innerSidebarOpen ? `width-4biz` : "width-4biz-full"} grow px-4 py-10 min-h-screen min-w-64 sm:px-6 lg:px-8 lg:py-6 bg-crazy-${theme} overflow-y-scroll`}>
                                                    {/* Main area */}
                                                    <div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5  lg:px-8">
                                                        <div className="flex items-center space-x-5">
                                                            <div className="flex-shrink-0">
                                                                <div className="relative">
                                                                    <img
                                                                        className="h-16 w-16 rounded-full"
                                                                        src={companyData.image ? companyData.image : process.env.REACT_APP_DEFAULT_AVATAR}
                                                                        alt=""
                                                                    />
                                                                    <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h1 className="text-2xl font-bold text-gray-900">{companyData.businessName}</h1>
                                                                <p className="text-sm font-medium text-gray-500">
                                                                    {companyData.vat}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button
                                                                        className={`mx-1 block rounded-md bg-${theme}-600 px-3 py-2 flex flex-start items-center text-center text-sm font-semibold text-white shadow-sm hover:bg-${theme}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${theme}-600`}
                                                                    >
                                                                        <CogIcon className="mr-2 h-5 w-5 text-white hover:text-white" />
                                                                        Azioni
                                                                        <ChevronDownIcon
                                                                            className="-mr-1 ml-2 h-5 w-5 text-white hover:text-white"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </Menu.Button>
                                                                </div>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                                        <div className="px-1 py-1 ">
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        onClick={() => {
                                                                                            setOpenResendPassword(true)
                                                                                        }}
                                                                                    >
                                                                                        <KeyIcon className='h-4 w-4 mr-2' />Reset password
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                        onClick={() => {
                                                                                            setOpenBlock(true)
                                                                                        }}
                                                                                    >
                                                                                        <NoSymbolIcon className='h-4 w-4 mr-2' />Blocca utente
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            setOpenDelete(true)
                                                                                        }}
                                                                                        className={`${active ? `bg-${theme}-500 text-white` : 'text-gray-900'
                                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                    >
                                                                                        <TrashIcon className='h-4 w-4 mr-2' /> Elimina
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        </div>
                                                    </div>

                                                    <div className="mx-auto mt-8  sm:px-6  lg:grid-flow-col-dense lg:grid-cols-3">
                                                        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                                                            {/* Description list*/}
                                                            
                                                            <Table1 data={data} columns={colonne} config={configBank} dataCallback={null} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : "Loading"}
                                        </main>
                                    );
                                default:
                                    return null;
                            }
                        })()}
                       
                       

                    <aside className={`inset-y-0 left-72 hidden ${innerSidebarOpen ? `w-96` : `w-236`} overflow-y-auto border-r border-gray-200 xl:block`}>
                        <div className='flex flex-row justify-between'>
                            {innerSidebarOpen ? <div className="pb-4 px-4 mt-4">
                                <button
                                    type="button"
                                    onClick={() => setInnerSidebarOpen(!innerSidebarOpen)}
                                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <ChevronDoubleLeftIcon className="h-3 w-3" />
                                </button>
                            </div> : <div className="pb-4 px-4 mt-4">
                                <button
                                    type="button"
                                    onClick={() => setInnerSidebarOpen(!innerSidebarOpen)}
                                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <ChevronDoubleRightIcon className="h-3 w-3" />
                                </button></div>}
                            
                        </div>

                        
                        {/* Directory list */}
                        <nav className="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">

                            <ul role="list" className="relative z-0 divide-y divide-gray-200">
                                <li onClick={() => {
                                        setCurrentOption(0)
                                        }}>
                                        <div className={`relative flex items-center ${currentOption === 0 ? `border-2 border-${theme}-600 ring-inset bg-${theme}-50` : ""} space-x-3 px-6 py-5 hover:bg-${theme}-50`}>
                                                <InformationCircleIcon className="h-6 w-6 shrink-0 text-gray-600" aria-hidden="true" />
                                            {innerSidebarOpen ? <div className="min-w-0 flex-1">
                                                    {/* Extend touch target to entire panel */}
                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                    <p className="text-sm font-medium text-gray-900">
                                                        Informazioni
                                                        </p>
                                            </div> : null}
                                        </div>
                                </li>
                                <li onClick={() => {
                                        setCurrentOption(1)
                                        }}>
                                        <div className={`relative flex items-center ${currentOption === 1 ? `border-2 border-${theme}-600 ring-inset bg-${theme}-50` : ""} space-x-3 px-6 py-5 hover:bg-${theme}-50`}>
                                                <CurrencyEuroIcon className="h-6 w-6 shrink-0 text-gray-600" aria-hidden="true" />
                                            {innerSidebarOpen ? <div className="min-w-0 flex-1">
                                                    {/* Extend touch target to entire panel */}
                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                    <p className="text-sm font-medium text-gray-900">
                                                        Fatturazione
                                                        </p>
                                            </div> : null}
                                        </div>
                                </li>
                                <li onClick={() => {
                                        setCurrentOption(2)
                                        }}>
                                        <div className={`relative flex items-center ${currentOption === 2 ? `border-2 border-${theme}-600 ring-inset bg-${theme}-50` : ""} space-x-3 px-6 py-5 hover:bg-${theme}-50`}>
                                                <HomeModernIcon className="h-6 w-6 shrink-0 text-gray-600" aria-hidden="true" />
                                            {innerSidebarOpen ? <div className="min-w-0 flex-1">
                                                    {/* Extend touch target to entire panel */}
                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                    <p className="text-sm font-medium text-gray-900">
                                                        Unità
                                                        </p>
                                            </div> : null}
                                        </div>
                                </li>
                                <li onClick={() => {
                                        setCurrentOption(3)
                                        }}>
                                        <div className={`relative flex items-center ${currentOption === 3 ? `border-2 border-${theme}-600 ring-inset bg-${theme}-50` : ""} space-x-3 px-6 py-5 hover:bg-${theme}-50`}>
                                                <LuPiggyBank className="h-6 w-6 shrink-0 text-gray-600" aria-hidden="true" />
                                            {innerSidebarOpen ? <div className="min-w-0 flex-1">
                                                    {/* Extend touch target to entire panel */}
                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                    <p className="text-sm font-medium text-gray-900">
                                                        Banche
                                                        </p>
                                            </div> : null}
                                        </div>
                                </li>
                            </ul>
                        </nav>
                    </aside>
                </div>
            </>

            
    )
}

