//Redux
import { useSelector, useDispatch } from 'react-redux'
import { sidebarSubMenu, toggleNavbar } from '../../../actions'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
//Data
import { crm, progetti, pim, finance, options, hr, superadmin, aimodels, profile } from '../../../assets/data/sidebar'
import Cookies from 'js-cookie'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar1() {

    const navigate = useNavigate()
    const sidebar = useSelector(store => store.sidebar)
    const navigation = useSelector(store => store.navbar)
    const sidebase = useSelector(store => store.sidebaseMenu)
    const company = JSON.parse(Cookies.get("company"))
    let dispatch = useDispatch()

    useEffect(() => {
        
    }, [navigate])


    return (
        <>
            <div className={`hidden lg:inset-y-0  lg:flex lg:${sidebar ? `w-72` : `w-20`} lg:flex-col`}>
                <div className="flex h-screen flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                    
                    <nav className="flex mt-4 flex-1 flex-col min-h-full">
                        
                        <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul className="-mx-2 space-y-1">
                                    {(() => {
                                        switch (navigation.navbar) {
                                            case 'CRM':
                                                return (
                                                    <>
                                                        {crm.map((item) => (
                                                            <li key={item.name}>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(toggleNavbar({
                                                                            navbar: "CRM",
                                                                            sidebar: item.slug
                                                                        }))
                                                                        navigate(item.href)
                                                                    }}
                                                                    className={classNames(
                                                                        navigation.sidebar === item.slug ? `bg-green-100` : `hover:bg-green-100`,
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                                    {sidebar ? item.name : ""}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </>
                                                );
                                            case 'PROGETTI':
                                                return (
                                                    <>
                                                        {progetti.map((item) => (
                                                            <li key={item.name}>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(toggleNavbar({
                                                                            navbar: "PROGETTI",
                                                                            sidebar: item.slug
                                                                        }))
                                                                        navigate(item.href)
                                                                    }}
                                                                    className={classNames(
                                                                        navigation.sidebar === item.slug ? `bg-green-100` : `hover:bg-green-100`,
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                                    {sidebar ? item.name : ""}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </>
                                                );
                                            case 'PIM':
                                                return (
                                                    <>
                                                        {pim.map((item) => (
                                                            <li key={item.name}>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(toggleNavbar({
                                                                            navbar: "PIM",
                                                                            sidebar: item.slug
                                                                        }))
                                                                        navigate(item.href)
                                                                    }}
                                                                    className={classNames(
                                                                        navigation.sidebar === item.slug ? `bg-green-100` : `hover:bg-green-100`,
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                                    {sidebar ? item.name : ""}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </>
                                                );
                                            case 'FINANCE':
                                                return (
                                                    <>
                                                        {finance.map((item) => (
                                                            <li key={item.name}>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(toggleNavbar({
                                                                            navbar: "FINANCE",
                                                                            sidebar: item.slug
                                                                        }))
                                                                        navigate(item.href)
                                                                    }}
                                                                    className={classNames(
                                                                        navigation.sidebar === item.slug ? `bg-green-100` : `hover:bg-green-100`,
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                                    {sidebar ? item.name : ""}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </>
                                                );
                                            case 'HR':
                                                return (
                                                    <>
                                                        {hr.map((item) => (
                                                            <li key={item.name}>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(toggleNavbar({
                                                                            navbar: "HR",
                                                                            sidebar: item.slug
                                                                        }))
                                                                        navigate(item.href)
                                                                    }}
                                                                    className={classNames(
                                                                        navigation.sidebar === item.slug ? `bg-green-100` : `hover:bg-green-100`,
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                                    {sidebar ? item.name : ""}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </>
                                                );
                                            case 'OPTIONS':
                                                return (
                                                    <>
                                                        {options.map((item) => (
                                                            <li key={item.name}>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(toggleNavbar({
                                                                            navbar: "OPTIONS",
                                                                            sidebar: item.slug
                                                                        }))
                                                                        navigate(item.href)
                                                                    }}
                                                                    className={classNames(
                                                                        navigation.sidebar === item.slug ? `bg-green-100` : `hover:bg-green-100`,
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                                    {sidebar ? item.name : ""}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </>
                                                );
                                            case 'PROFILE':
                                                return (
                                                    <>
                                                        {profile.map((item) => (
                                                            <li key={item.name}>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(toggleNavbar({
                                                                            navbar: "PROFILE",
                                                                            sidebar: item.slug
                                                                        }))
                                                                        navigate(item.href)
                                                                    }}
                                                                    className={classNames(
                                                                        navigation.sidebar === item.slug ? `bg-green-100` : `hover:bg-green-100`,
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                                    {sidebar ? item.name : ""}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </>
                                                );
                                            case 'AI':
                                                return (
                                                    <>
                                                        {aimodels.map((item) => (
                                                            <li key={item.name}>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(toggleNavbar({
                                                                            navbar: "AI",
                                                                            sidebar: item.slug
                                                                        }))
                                                                        navigate(item.href)
                                                                    }}
                                                                    className={classNames(
                                                                        navigation.sidebar === item.slug ? `bg-green-100` : `hover:bg-green-100`,
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                                    {sidebar ? item.name : ""}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </>
                                                );
                                            case 'SUPERADMIN':
                                                return (
                                                    <>
                                                        {superadmin.map((item) => (
                                                            <li key={item.name}>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(toggleNavbar({
                                                                            navbar: "SUPERADMIN",
                                                                            sidebar: item.slug
                                                                        }))
                                                                        navigate(item.href)
                                                                    }}
                                                                    className={classNames(
                                                                        navigation.sidebar === item.slug ? `bg-green-100` : `hover:bg-green-100`,
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                                    {sidebar ? item.name : ""}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </>
                                                );
                                            default:
                                                return null;
                                        }
                                    })()}
                                    
                                </ul>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </>
        
    )
}
