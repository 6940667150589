import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

const PrivateRoute = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.auth);
    const companyAuthenticated = useSelector((state) => state.company);
    const lsAuthenticated = Cookies.get("@user");
    const lsCompanyAuthenticated = Cookies.get("@company");
    const dispatch = useDispatch();

    if (!isAuthenticated && !lsAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    if ((!isAuthenticated && lsAuthenticated) || (!companyAuthenticated && lsCompanyAuthenticated)) {
        dispatch({ type: 'SET_USER', payload: JSON.parse(lsAuthenticated) });
        dispatch({ type: 'CHOOSE_COMPANY', payload: JSON.parse(lsCompanyAuthenticated) });
    }

    return children;
};

export default PrivateRoute;
