import { Fragment, useState, useEffect } from 'react'
import { Transition, Dialog, RadioGroup } from '@headlessui/react'
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { ChartBarSquareIcon, ShieldCheckIcon, TrashIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'
import { BsDot } from "react-icons/bs";
import { TbPlugConnected } from "react-icons/tb";

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import axios from 'axios';

//Importo le componenti di 4BIZ
import Table1 from '../../components/tables/table-1'
import Loader1 from '../../components/utility/loader-1'
import Modal2 from '../../components/modals/modal-2'

import Form1 from '../../components/forms/form-1'
import Button from '../../components/utility/button'
import Notification1 from '../../components/notifications/notification-1'


export default function Channels() {

    const user = useSelector(state => state.auth.user)
    const jwt = useSelector(state => state.auth.jwt)

    const navigate = useNavigate()


    const [selectedItem, setSelectedItem] = useState(null)

    const [data, setData] = useState(null)
    const [openChannelEdit, setOpenChannelEdit] = useState(false)
    const [openAssing, setOpenAssign] = useState(false)
    const [openChannel, setOpenChannel] = useState(false)
    const [openCapability, setOpenCapability] = useState(false)
    const [tabs, setTabs] = useState([
        { name: 'Singoli', slug: 'single', count: 0, current: true },
        { name: 'Gruppi', slug: 'group', count: 0, current: false },
    ])
    const [selectedTab, setSelectedTab] = useState("single")
    const [openInvitation, setOpenInvitation] = useState(false)
    const [openResendPassword, setOpenResendPassword] = useState(false)
    const [openBlock, setOpenBlock] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openReactivate, setOpenReactivate] = useState(false)
    const [licenseInSelect, setLicenseInSelect] = useState(null)
    const [selectedAssignLicense, setSelectedAssignLicense] = useState(null)
    const [selectedAssignSuggestedPrice, setSelectedAssignSuggestedPrice] = useState(0)
    const [openTutorial, setOpenTutorial] = useState(false)
    const [openConnectionDatabase, setOpenConnectionDatabase] = useState(false)
    const [notification, setNotification] = useState({
        title: "",
        description: "",
        type: "success"
    })
    const [loadingConnection, setLoadingConnection] = useState(false)
    const dispatch = useDispatch();
    const theme = useSelector(state => state.company.theme)
    const company = useSelector(state => state.company)


    const getChannels = async (perPage, currentPage, sorted, sortedBy, search) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/ai-models/channels/fetch`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: company.uuid, // Utilizza data.company.id al posto di ${ company.id }
                        limit: perPage,
                        page: currentPage,
                        sorted: sorted,
                        sortedBy: sortedBy,
                        search: search
                    }
                }
            );
            if (response.data) {
                setData(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getChannelsSelect = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/license/fetchInSelect`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }                
                }
            );
            if (response.data) {
                setLicenseInSelect(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        getChannels(25, 1, "asc", "name", "")
        getChannelsSelect()
    }, []);


    const dataCallback = async (array, perPage, currentPage, sorted, sortedBy, search) => {
        getChannels(perPage, currentPage, sorted, sortedBy, search)
    }

    const config = {
        title: "Channels",
        description: () => {
            return (
                <p className="mt-2 text-sm text-gray-700 flex flex-row">
                    La lista dei tuoi <strong>channels 4BIZ </strong> <span className="cursor-pointer text-xs px-1 flex flex-row ml-2 rounded-lg bg-gray-100 text-gray-600" onClick={() => setOpenTutorial(true)} >Scopri di più su cosa sono e come utilizzarli <InformationCircleIcon className="ml-2 h-4 w-4 text-gray-700" /></span>.
                </p>
            )
        },
        extra: () => {
            return (
                <>
                    {selectedTab === "single" ? <Button
                        onClick={() => setOpenChannel(true)}
                        right="1"
                        icon={<PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />}
                        title="Channel"
                    /> : <Button
                        onClick={() => setOpenAssign(true)}
                        icon={<PlusCircleIcon className="mr-2 h-5 w-5 text-white hover:text-white" />}
                        title="Gruppo"
                    />}
                    
                </>
            )
        },
        pagination: true,
        sizePerPage: 25,
        perPage: [5, 10, 25, 50, 75, 100],
        export: true,
        rowClick: (item) => {
            setSelectedItem(item)
            setOpenChannelEdit(true)
        },
    }

    // Funzione per mappare la ricorrenza
    const formatRecurrency = (recurrency) => {
        const recurrencyMap = {
            '1M': 'Mensile',
            '2M': 'Bimestre',
            '3M': 'Trimestre',
            '6M': 'Semestre',
            '1A': 'Annuale',
            '2A': 'Biannuale'
        };
        return recurrencyMap[recurrency] || '';
    };

    // Funzione per mappare il contatore
    const formatCounter = (counter) => {
        const counterMap = {
            'USER': 'Utente',
            'DEVICE': 'Device',
            'COMPANY': 'Azienda'
        };
        return counterMap[counter] || '';
    };

    // Funzione per formattare il prezzo
    const formatPrice = (price) => {
        if (!price || price.length === 0) {
            return "Nessun prezzo disponibile";
        }

        const ultimoPrezzo = price[price.length - 1];

        return (
            <div className="flex flex-col">
                {ultimoPrezzo.value.toFixed(2)}{"€ /"}
                {formatRecurrency(ultimoPrezzo.recurrency)}{" /"}
                {formatCounter(ultimoPrezzo.counter)}
            </div>
        );
    };

    // Funzione per formattare la ricorrenza
    const formatPriceCounter = (price) => {
        console.log(price)
        if (!price || price.length === 0) {
            return "Nessun prezzo disponibile";
        }

        const ultimoPrezzo = price[price.length - 1];


        return formatRecurrency(ultimoPrezzo.recurrency) + " /" + formatCounter(ultimoPrezzo.counter)
        /*return (
            <span className="flex flex-col">
                {formatRecurrency(ultimoPrezzo.recurrency)}{" /"}
                {formatCounter(ultimoPrezzo.counter)}
            </span>
        );*/
    };

    const formatPermission = (permissions, section) => {
        if (!permissions || permissions.length === 0) {
            return null;
        }

        const lastPermission = permissions[permissions.length - 1]; // L'ultimo oggetto di permission
        if (!lastPermission.sections || lastPermission.sections.length === 0) {
            return null;
        }

        const sectionFound = lastPermission.sections.find(s => s.value === section);
        return sectionFound ? sectionFound.enabled : null; // Restituisce il valore di `enabled`
    };

    function getActiveChannelStatus (item) {
        if(item.active) {
            return <span className='flex flex-row items-center'><BsDot className="w-8 h-8 text-green-600" />{item.name}</span>;
        } else {
            return <span className='flex flex-row items-center'><BsDot className="w-8 h-8 text-gray-400" />{item.name}</span>;
        }
    }

    // Definizione delle colonne
    const colonne = [
        {
            name: "Nome",
            dataField: "name",
            formatter: (item) => getActiveChannelStatus(item)
        },
        {
            name: "Prezzo",
            dataField: "price",
            formatter: (item) => formatPrice(item.price)
        },
        {
            name: "Azioni",
            dataField: "",
            formatter: (item) => (
                <div className="flex flex-row">
                    <Tooltip id="send-tooltip" />
                    <TbPlugConnected
                        data-tooltip-id="send-tooltip"
                        data-tooltip-content="Configurazione"
                        className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer'
                        onClick={() => {
                            setSelectedItem(item);
                            if(item.metadata) {
                                if(item.metadata.type === "DATABASE") {
                                    setOpenConnectionDatabase(true)
                                }
                            }
                        }}
                    />
                    <Tooltip id="delete-tooltip" />
                    <TrashIcon
                        data-tooltip-id="delete-tooltip"
                        data-tooltip-content="Elimina licenza"
                        className='h-5 w-5 ml-2 text-gray-500 hover:text-gray-700 cursor-pointer'
                        onClick={() => {
                            setSelectedItem(item);
                            setOpenDelete(true);
                        }}
                    />
                </div>
            )
        }
    ];

    const handleTabChange = (slug) => {
        const updatedTabs = tabs.map(tab => ({
            ...tab,
            current: tab.slug === slug,
        }));
        setTabs(updatedTabs);
        setSelectedTab(slug)
    };

    const addChannel = async data => {
        try {
            data.systemUser = user
            data.addToCompany = company.uuid
            const response = await axios.post(
                `${process.env.REACT_APP_API_KEY}/ai-models/channels/create`,
                data,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                }
            );
            if (response.data) {
                getChannels(25, 1, "asc", "name", "")
            }
        } catch (e) {
            console.error(e)
        }
    }

    const assingLicense = async data => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_KEY}/license/assignLicense`, data,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: data.uuid, // Utilizza data.company.id al posto di ${ company.id }
                    }
                }
            );
            if (response.data) {
                setData(response.data);
            }
        } catch (e) {
            console.error(e)
        }
    }

    const resendPassword = async data => {
        console.log("Resend password")
    }

    const blockUser = async data => {
        console.log("Blcok user")
    }

    const deleteLicense = async () => {
        try {
            if (selectedItem) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/users/deleteLicense`, {
                    uuid: selectedItem.uuid,
                    company: company.uuid
                },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    }
                );
                if (response.data) {
                    setOpenDelete(false)
                    setNotification({
                        title: "Conferma",
                        description: "Utente eliminatə con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }
            }
        } catch (e) {
            console.error(e)
            setNotification({
                title: "Errore",
                description: "Ops! Qualcosa è andato storto",
                type: "error",
            })
            setShowNotification(true)
        }
    }

    const [collectionResult, setCollectionResult] = useState(null)

    const editConnectionDatabase = async data => {
        try {
            if (selectedItem) {
                setCollectionResult(null)
                setLoadingConnection(true)
                let body = {...selectedItem, ...data}
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/ai-models/channels/database-connection-settings`, body,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        }
                    }
                );
                if (response.data) {
                    getChannels(25, 1, "asc", "name", "")
                    setOpenDelete(false)
                    setTimeout(() => setLoadingConnection(false), 2000)
                    setCollectionResult(response.data.collections)
                }
            }
        } catch (e) {
            console.error(e)
            setNotification({
                title: "Errore",
                description: "Ops! Qualcosa è andato storto",
                type: "error",
            })
            setShowNotification(true)
        }
    }

    const reactivateUser = async data => {
        console.log("Reactivate user")
    }

    let configForm = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Inserisci i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "name",
                name: "name",
                label: "Nome",
                class: "sm:col-span-6",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
                extra: () => {
                    return (
                        <p className="mt-1 text-xs leading-6 text-gray-400">Inserisci un nome per la licenza, es. 4BIZ Basic.</p>
                    )
                }
            },
            {
                id: "type",
                name: "type",
                label: "Tipologia",
                class: "sm:col-span-3",
                type: "select",
                value: null,
                options: [
                    {
                        value: "DATABASE",
                        label: "Database"
                    },
                    {
                        value: "FILE",
                        label: "File"
                    },
                    {
                        value: "MEDIA",
                        label: "Media"
                    },
                    {
                        value: "INTEGRATION",
                        label: "Integrazione"
                    },

                ],
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: null
            },
            {
                id: "database",
                name: "database",
                label: "Scegli il database",
                class: "sm:col-span-3",
                type: "select",
                value: null,
                dependency: 'type',
                dependencyTrigger: 'DATABASE',
                options: [
                    {
                        value: "MSSQL",
                        label: "SQL Server",
                        image: process.env.REACT_APP_MSSQL_LOGO
                    },
                    {
                        value: "MYSQL",
                        label: "MySQL",
                        image: process.env.REACT_APP_MYSQL_LOGO
                    },
                    {
                        value: "POSTGRESQL",
                        label: "PostgreSQL",
                        image: process.env.REACT_APP_POSTGRESQL_LOGO
                    },
                    {
                        value: "SQLITE",
                        label: "SQLite",
                        image: process.env.REACT_APP_SQLITE_LOGO
                    },
                    {
                        value: "MONGODB",
                        label: "MongoDB",
                        image: process.env.REACT_APP_MONGO_LOGO
                    },
                    {
                        value: "INFLUX",
                        label: "Influx",
                        image: process.env.REACT_APP_INFLUX_LOGO
                    },
                    {
                        value: "ELASTICSEARCH",
                        label: "Elastic",
                        image: process.env.REACT_APP_ELASTIC_LOGO
                    },
                ],
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: null
            },
            {
                id: "filetype",
                name: "filetype",
                label: "Scegli il tipo di file",
                class: "sm:col-span-3",
                type: "select",
                value: null,
                dependency: 'type',
                dependencyTrigger: 'FILE',
                options: [
                    {
                        value: "CSV",
                        label: "File .csv"
                    },
                    {
                        value: "XLS",
                        label: "File .xls"
                    },
                ],
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: null
            },
            {
                id: "mediatype",
                name: "mediatype",
                label: "Scegli il tipo di media",
                class: "sm:col-span-3",
                type: "select",
                value: null,
                dependency: 'type',
                dependencyTrigger: 'MEDIA',
                options: [
                    {
                        value: "SMB",
                        label: "SMB"
                    },
                    {
                        value: "FTP",
                        label: "FTP"
                    },
                    {
                        value: "SFTP",
                        label: "sFTP"
                    },
                    {
                        value: "NFS",
                        label: "NFS"
                    },
                ],
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "mediatype",
                extra: null
            },
            {
                id: "recurrency",
                name: "recurrency",
                label: "Ricorrenza",
                class: "sm:col-span-6",
                type: "select",
                value: null,
                dependency: 'type',
                dependencyTrigger: 'MEDIA',
                options: [
                    {
                        value: "1M",
                        label: "1 mese"
                    },
                    {
                        value: "3M",
                        label: "3 mesi"
                    },
                    {
                        value: "6M",
                        label: "6 mesi"
                    },
                    {
                        value: "1A",
                        label: "1 anno"
                    },
                    {
                        value: "2A",
                        label: "2 anni"
                    },
                ],
                defaultValue: null,
                onChange: null,
                required: true,
                disabled: false,
                autoComplete: "type",
                extra: null
            },
        ],
        cancelText: "Annulla",
        submitText: "Invia",
        submit: data => addChannel(data)
    }

    let configFormConnectionDatabase = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Inserisci i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "host",
                name: "host",
                label: "Host",
                class: "sm:col-span-4",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedItem ? (selectedItem.metadata ? selectedItem.metadata.connection.host : null) : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "es. localhost",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "port",
                name: "port",
                label: "Porta",
                class: "sm:col-span-2",
                type: "number",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedItem ? (selectedItem.metadata ? selectedItem.metadata.connection.port : null) : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "es. 3306",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "db",
                name: "db",
                label: "Database",
                class: "sm:col-span-2",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedItem ? (selectedItem.metadata ? selectedItem.metadata.connection.db : null) : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "es. main",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "user",
                name: "user",
                label: "Username",
                class: "sm:col-span-2",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedItem ? (selectedItem.metadata ? selectedItem.metadata.connection.user : null) : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "es. root",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "password",
                name: "password",
                label: "Password",
                class: "sm:col-span-2",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: selectedItem ? (selectedItem.metadata ? selectedItem.metadata.connection.password : null) : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "es. localhost",
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
            },
        ],
        cancelText: "Annulla",
        submitText: "Invia",
        submit: data => editConnectionDatabase(data)
    }

    let configFormResult = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Selezione la fonte di input e <strong>salva</strong> per aggiornarla.</p>
            )
        },
        fields: [
            {
                id: "source",
                name: "source",
                label: "Sorgente",
                class: "sm:col-span-6",
                type: "select",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                onChange: null,
                required: true,
                disabled: false,
                options: collectionResult ? collectionResult : null ,
                autoComplete: "name",
                autoCapitalize: true,
                size: 999,
            },
        ],
        cancelText: "Annulla",
        submitText: "Invia",
        submit: data => editConnectionDatabase(data)
    }

    // Usa un useEffect per aggiornare il prezzo suggerito quando cambia la licenza selezionata
    useEffect(() => {
        if (selectedAssignLicense && data && data.data) {
            const selectedLicense = data.data.find(item => item.uuid === selectedAssignLicense);
            if (selectedLicense && selectedLicense.price && selectedLicense.price.length > 0) {
                setSelectedAssignSuggestedPrice(selectedLicense.price[0].value); // Imposta il prezzo suggerito
            }
        }
    }, [selectedAssignLicense, data]);


   

    const [showNotification, setShowNotification] = useState(false)

    return (
        <main className={`py-10 w-full bg-crazy-green min-h-screen overflow-y-scroll`}>

            {showNotification ? <Notification1 config={notification} show={showNotification} setShow={setShowNotification} /> : null}


            <div className='px-8 mb-8'>
                <div className="sm:hidden ">
                    <Modal2 title={"Invia reset password"} description={"Sei sicurə di voler inviare il reset della password?"} dataCallbackConfirm={() => resendPassword()} dataCallbackReset={() => setOpenResendPassword(false)} open={openResendPassword} setOpen={setOpenInvitation} />
                    <Modal2 title={"Blocca utente"} description={"Sei sicurə di voler bloccare l'utente?"} dataCallbackConfirm={() => blockUser()} dataCallbackReset={() => setOpenBlock(false)} open={openBlock} setOpen={setOpenBlock} />
                    {selectedItem ? <Modal2 title={"Elimina licenza"} description={"Sei sicurə di voler eliminare la licenza? Non potrai più assegnare questo tipo di licenza, ma le licenze già assegnate resteranno attive."} dataCallbackConfirm={() => deleteLicense()} dataCallbackReset={() => setOpenDelete(false)} open={openDelete} setOpen={setOpenDelete} /> : null}
                    <Modal2 title={"Riattiva utente"} description={"Sei sicurə di voler riattivare l'utente?"} dataCallbackConfirm={() => reactivateUser()} dataCallbackReset={() => setOpenReactivate(false)} open={openReactivate} setOpen={setOpenReactivate} />
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}

                </div>

            </div>
            <div className='px-8 mb-8'>
                <div className="hidden sm:block ">
                    <div className="bg-white rounded-xl shadow px-8">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <span
                                    key={tab.name}
                                    onClick={() => handleTabChange(tab.slug)}
                                    className={`cursor-pointer flex whitespace-nowrap border-b-2 ${tab.current ? `border-green-500` : ""} py-4 px-1 text-sm font-medium`}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    <span className={`${tab.current ? `bg-green-500 text-white px-2.5 py-0.5 ` : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700'} rounded-xl`}>{tab.name}</span>
                                </span>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            

            {data ? <Table1 data={data} columns={colonne} config={config} dataCallback={dataCallback} /> : <Loader1 />}
            
            {/*Slider di creazione della licenza */}
            <Transition.Root show={openChannel} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpenChannel}>
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                        Crea channel
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className={`relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                                            onClick={() => setOpenChannel(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                <Form1 config={configForm} />
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/*Slider di edit generico della licenza */}
            {selectedItem ? <Transition.Root show={openConnectionDatabase} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpenConnectionDatabase}>
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                        Connessione a database
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className={`relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                                            onClick={() => setOpenChannelEdit(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                {!collectionResult ? <Form1 config={configFormConnectionDatabase} /> :
                                                <Form1 config={configFormResult} /> }
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root> : null}

        </main>
    )
}

