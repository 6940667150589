import { React, useState, Fragment, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Listbox, Transition, Switch } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import Loader1 from '../utility/loader-1'


export default function Form1({ config }) {
    const { register, handleSubmit, control, formState: { errors }, reset, watch } = useForm();

    // Stato locale per gestire il valore del campo "dependency"
    const [fieldValues, setFieldValues] = useState({});

    // Funzione di gestione dell'input per salvare il valore del campo
    const handleInputChange = (name, value) => {
        setFieldValues((prevValues) => ({ ...prevValues, [name]: value }));
        const field = config.fields.find(f => f.name === name);
        if (field && field.onChange) {
            field.onChange(value); // Chiama l'onChange passato da config solo per il campo selezionato
        }
    };

    // Funzione per verificare se un campo dovrebbe essere mostrato in base alla sua dipendenza
    const shouldDisplayField = (field) => {
        if (field.dependency) {
            const dependencyValue = fieldValues[field.dependency];
            return dependencyValue === field.dependencyTrigger;
        }
        return true;
    };

    // Inizializza lo stato con i valori di default dei campi quando il componente viene caricato
    useEffect(() => {
        const defaultValues = {};
        config.fields.forEach(field => {
            // Usa il valore di default se esiste, altrimenti prendi il primo valore disponibile
            const defaultValue = field.defaultValue || (field.options ? field.options[0].value : null);
            if (defaultValue) {
                defaultValues[field.name] = defaultValue;
                handleInputChange(field.name, defaultValue); // Imposta anche lo stato iniziale
            }
        });
        setFieldValues(defaultValues);
    }, [config.fields]);

    const onSubmit = (data) => {
        config.submit(data);
        reset();
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    return (
        <>
            {config && config.fields ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border-b border-gray-900/10 pb-8">
                        {config.formatter && config.formatter()}
                        <div className="grid mt-4 max-w-screen grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6 items-center">
                            {config.fields.map((item) => {
                                if (!shouldDisplayField(item)) {
                                    // Nascondi il campo se non soddisfa la condizione di dipendenza
                                    return null;
                                }
                                return (
                                    <div className={item.class} key={item.id}>
                                        {(() => {
                                            switch (item.type) {
                                                case 'text':
                                                case 'email':
                                                case 'number':
                                                    return (
                                                        <>
                                                            <label htmlFor={item.id} className="block text-sm font-medium leading-6 text-gray-900">
                                                                {item.label}
                                                            </label>
                                                            <input
                                                                type={item.type}
                                                                id={item.id}
                                                                step={item.step}
                                                                disabled={item.disabled}
                                                                defaultValue={item.defaultValue}
                                                                autoComplete={item.autoComplete || ""}
                                                                className={`block w-full rounded-md border-0 py-1.5 ${item.disabled ? `text-gray-400 bg-gray-100` : `text-gray-900`} shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6`}
                                                                placeholder={item.placeholder || ""}
                                                                onChange={(e) => handleInputChange(item.name, e.target.value)}
                                                                {...register(item.name)}
                                                            />
                                                        </>
                                                    );
                                                case 'checkbox':
                                                    return (
                                                        <Controller
                                                            name={item.name}
                                                            control={control} 
                                                            disabled={item.disabled}
                                                            defaultValue={item.defaultValue || false} // Imposta un valore di default (false se non è specificato)
                                                            render={({ field: { onChange, value } }) => (
                                                                <>
                                                                    <div className="flex items-center">
                                                                        <Switch
                                                                            checked={value} // Collega il valore `checked` al valore gestito da react-hook-form
                                                                            onChange={onChange} // Usa `onChange` del `Controller` per gestire il valore
                                                                            className={`${value ? 'bg-green-600' : 'bg-gray-200'}
                                relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                        >
                                                                            <span
                                                                                className={`${value ? 'translate-x-6' : 'translate-x-1'}
                                    inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                            />
                                                                        </Switch>
                                                                        <label htmlFor={item.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                                            {item.label}
                                                                        </label>
                                                                    </div>
                                                                    {item.subLabel && (
                                                                        <p className="text-sm text-gray-500 ml-3">
                                                                            {item.subLabel}
                                                                        </p>
                                                                    )}
                                                                </>
                                                            )}
                                                        />
                                                    );
                                                case 'textarea':
                                                    return (
                                                        <>
                                                            <label htmlFor={item.id} className="block text-sm font-medium leading-6 text-gray-900">
                                                                {item.label}
                                                            </label>
                                                            <textarea
                                                                id={item.id}
                                                                disabled={item.disabled}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                                rows={item.rows || 3}
                                                                defaultValue={item.defaultValue}
                                                                placeholder={item.placeholder || ""}
                                                                onChange={(e) => handleInputChange(item.name, e.target.value)}
                                                                {...register(item.name)}
                                                            />
                                                        </>
                                                    );
                                                case 'select':
                                                    return (
                                                        <Controller
                                                            name={item.name}
                                                            control={control}
                                                            defaultValue={item.defaultValue || item.options[0]?.value} // Set default to first option if no defaultValue
                                                            render={({ field }) => (
                                                                <Listbox
                                                                    value={field.value}
                                                                    onChange={(option) => {
                                                                        field.onChange(option.value); // Salva solo il valore nel form
                                                                        handleInputChange(item.name, option.value); // Aggiorna il valore del campo
                                                                    }}
                                                                >
                                                                    {({ open }) => (
                                                                        <>
                                                                            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                                                                {item.label}
                                                                            </Listbox.Label>
                                                                            <div className="relative w-full sm:w-52"> {/* Forzare la larghezza */}
                                                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 sm:text-sm sm:leading-6">
                                                                                    <span className="block truncate">{field.value ? item.options.find(opt => opt.value === field.value)?.label : item.placeholder}</span>
                                                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                                    </span>
                                                                                </Listbox.Button>

                                                                                <Transition
                                                                                    show={open}
                                                                                    as={Fragment}
                                                                                    leave="transition ease-in duration-100"
                                                                                    leaveFrom="opacity-100"
                                                                                    leaveTo="opacity-0"
                                                                                >
                                                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                                        {item.options.map((option) => (
                                                                                            <Listbox.Option
                                                                                                key={option.value}
                                                                                                className={({ active }) =>
                                                                                                    classNames(
                                                                                                        active ? 'text-white bg-green-600' : 'text-gray-900',
                                                                                                        'relative cursor-default select-none py-2 pl-4 pr-4'
                                                                                                    )
                                                                                                }
                                                                                                value={option}
                                                                                            >
                                                                                                {({ selected, active }) => (
                                                                                                    <>
                                                                                                        <span
                                                                                                            className={classNames(
                                                                                                                selected ? 'font-semibold' : 'font-normal',
                                                                                                                'block truncate flex flex-row'
                                                                                                            )}
                                                                                                        >{option.image ? <img src={option.image} className="w-8 h-auto mr-4" /> : null}
                                                                                                            {option.label}
                                                                                                        </span>

                                                                                                        {selected ? (
                                                                                                            <span
                                                                                                                className={classNames(
                                                                                                                    active ? 'text-white' : 'text-green-600',
                                                                                                                    'absolute inset-y-0 left-0 flex items-center pl-3'
                                                                                                                )}
                                                                                                            >
                                                                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                            </span>
                                                                                                        ) : null}
                                                                                                    </>
                                                                                                )}
                                                                                            </Listbox.Option>
                                                                                        ))}
                                                                                    </Listbox.Options>
                                                                                </Transition>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </Listbox>
                                                            )}
                                                        />
                                                    );
                                                default:
                                                    return null;
                                            }
                                        })()}
                                        {item.extra && item.extra()}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-between gap-x-6">
                        <button type="reset"
                            onClick={config.onReset ? config.onReset : null}
                            className="grow rounded-md bg-white px-3 py-2 text-sm font-semibold border border-gray-200 text-gray-600 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            {config.cancelText}
                        </button>
                        <button
                            type="submit"
                            className="grow rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            {config.submitText}
                        </button>
                    </div>
                </form>
            ) : (
                <Loader1 />
            )}
        </>
    );
}