import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'


export default function Loader1() {

    let company = useSelector(state => state.company)

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="w-8 h-8 border-4 border-t-green-200 border-r-green-300 border-b-green-400 border-l-green-500 rounded-full animate-spin"></div>
        </div>
    )
}

