import { combineReducers } from 'redux'

//REDUCERS
import counterReducer from "./counter";
import authReducer from "./auth";
import { sidebarReducer, sidebarMenuReducer } from "./sidebar"
import navbarReducer from './navbar';
import activeCompanyReducer from './company';


const rootReducers = combineReducers({
    counter: counterReducer,
    auth: authReducer,
    sidebar: sidebarReducer,
    navbar: navbarReducer,
    sidebaseMenu: sidebarMenuReducer,
    company: activeCompanyReducer
})

export default rootReducers;