import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Usa localStorage come storage predefinito
import rootReducers from "../reducers"; // Importa il tuo reducer principale

// Configurazione per redux-persist
const persistConfig = {
    key: 'root', // Chiave per identificare lo stato persistente
    storage,     // LocalStorage è lo storage predefinito
};

// Creiamo un reducer persistente con redux-persist
const persistedReducer = persistReducer(persistConfig, rootReducers);

// Crea lo store con il reducer persistente
const rootStore = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // Visualizza lo state in Redux DevTools
);

// Persist store
export const persistor = persistStore(rootStore);

// Esporta lo store come predefinito
export default rootStore;