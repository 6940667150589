import { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { ArrowLeftEndOnRectangleIcon, ChatBubbleLeftEllipsisIcon, CogIcon, PlusCircleIcon, QuestionMarkCircleIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { Bars3Icon, BellIcon, XMarkIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronUpDownIcon, CheckCircleIcon, BuildingOfficeIcon  } from '@heroicons/react/24/outline'
import { Combobox } from '@headlessui/react'

//Navigazione
import { header } from '../../../assets/data/header'

//Redux
import { useSelector, useDispatch } from 'react-redux'
import { toggleSidebar, toggleNavbar, sidebarSubMenu } from '../../../actions'

import Cookies from "js-cookie";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Header1() {

    const [query, setQuery] = useState('')


    const sidebar = useSelector(state => state.sidebar);
    const navbar = useSelector(state => state.navbar)
    let company = JSON.parse(Cookies.get('company'))
    let theme = company.theme
    let userCookie = JSON.parse(Cookies.get('user'))
    let user = userCookie.user
    const dispatch = useDispatch();


    console.log(user)


    const navigate = useNavigate()

    const filteredPeople =
        query === ''
            ? user.companies
            : user.companies.filter((company) => {
                return company.name.toLowerCase().includes(query.toLowerCase())
            })

    const logout = async () => {
        Cookies.remove('user'); // Rimuovi i cookie dell'utente
        Cookies.remove('company'); // Rimuovi i cookie dell'azienda
        navigate('/login'); // Reindirizza alla pagina di login
    }

    return (
        <Disclosure as="header" className="bg-white shadow border-b-2 border-gray-100 fixed sticky">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-full bg-white lg:inset-x-0 px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
                        <div className="relative flex h-16 justify-between">
                            <div className="relative z-10 flex px-2 lg:px-0">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        className="h-14 w-auto"
                                        src={process.env.REACT_APP_LOGO}
                                        alt="Your Company"
                                    />
                                </div>
                            </div>
                            <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0">
                                <div className="relative">
                                    <ChatBubbleLeftEllipsisIcon
                                        className="pointer-events-none absolute left-4 top-3 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />

                                    <input
                                        className={`min-w-96 mt-1 pl-11 rounded-md border-0 bg-green-100 px-4 py-2 text-gray-900 focus:ring-0 sm:text-sm`}
                                        placeholder="Chiedimi qualcosa..."
                                        onChange={(e) => console.log(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="relative z-10 flex items-center lg:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className={`relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500`}>
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                                <select id="countries" className={`cursor-pointer pr-24 mr-4 bg-green-100  border-green-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500`}>
                                    {filteredPeople.map((item) => (
                                        <option key={item} value={item}>{item.businessName}</option>
                                    ))}
                                </select>
                                
                              

                                <button
                                    type="button"
                                    className={`relative mx-1 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">View notifications</span>
                                    <PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
                                </button>

                                <button
                                    type="button"
                                    className={`relative mx-1 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>

                                <button
                                    type="button"
                                    className={`relative mx-1 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">Documentazione</span>
                                    <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
                                </button>

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-4 flex-shrink-0">
                                    <div>
                                        <Menu.Button className={`relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}>
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">Open user menu</span>
                                            <img className="h-8 w-8 rounded-full" src={user.image ? user.image : process.env.REACT_APP_DEFAULT_AVATAR} alt="" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item >
                                                    {({ active }) => (
                                                        <span
                                                        onClick={() => {
                                                            dispatch(toggleNavbar({
                                                                navbar: "PROFILE",
                                                                sidebar: "profile"
                                                            }))
                                                            navigate("/options/profile")
                                                        }}  
                                                        className={`flex items-center cursor-pointer hover:bg-green-100 block px-4 py-2 text-sm text-gray-700`}
                                                        >
                                                        <UserCircleIcon className='h-5 w-5 mr-1' />  Il mio profilo
                                                        </span>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                        <span
                                                            onClick={() => {
                                                                dispatch(toggleNavbar({
                                                                    navbar: "OPTIONS",
                                                                    sidebar: "users"
                                                                }))
                                                                navigate(`/options/users`)

                                                            }}
                                                        className={`flex items-center cursor-pointer hover:bg-green-100 block px-4 py-2 text-sm text-gray-700`}
                                                        >
                                                           <CogIcon className='h-5 w-5 mr-1'/> Impostazioni 
                                                        </span>
                                                </Menu.Item>
                                                <Menu.Item >
                                                    {({ active }) => (
                                                        <span
                                                        onClick={() => logout()}
                                                        className={`flex items-center cursor-pointer hover:bg-green-100 block px-4 py-2 text-sm text-gray-700`}
                                                        >
                                                        <ArrowLeftEndOnRectangleIcon className='h-5 w-5 mr-1' /> Esci
                                                        </span>
                                                    )}
                                                </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                        <nav className="hidden lg:flex justify-between items-center lg:space-x-8 lg:py-2" aria-label="Global">
                            <div>
                                {sidebar ? <button
                                    type="button"
                                    onClick={() => dispatch(toggleSidebar(false))}
                                    className="inline-flex w-10 items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <ChevronDoubleLeftIcon className="h-3 w-3" />
                                </button> :
                                <button
                                    type="button"
                                    onClick={() => dispatch(toggleSidebar(true))}
                                    className="inline-flex w-10 items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm  hover:bg-gray-50"
                                >
                                    <ChevronDoubleRightIcon className="h-3 w-3" />
                                </button> }
                            </div>
                            <div>
                                {header.map((item, index) => (
                                    <span
                                        key={index}
                                        onClick={() => {
                                            dispatch(toggleNavbar({
                                            navbar: item.slug,
                                            sidebar: item.initial
                                        }))
                                        navigate(item.path)
                                    }}
                                        className={
                                            `inline-flex items-center text-md rounded-md py-2 px-3 text-sm font-medium cursor-pointer ${item.slug === navbar.navbar ? `bg-green-100 text-gray-900` : `text-gray-900 hover:bg-green-50 hover:text-gray-900`}`
                                        }
                                        aria-current={item.slug === navbar.navbar ? 'page' : undefined}
                                    >
                                        {item.icon} {/* Renderizza l'icona */}
                                        <span>{item.name}</span>
                                    </span>
                                ))}
                            </div>
                            <div>

                            </div>
                            
                        </nav>
                    </div>

                    <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {header.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="button"
                                    onClick={() => {
                                        dispatch(toggleNavbar({
                                        navbar: item.slug,
                                        sidebar: item.initial
                                    }))
                                    navigate(item.path)
                                }}

                                    className={classNames(
                                        item.current ? `bg-green-100 text-gray-900 cursor-pointer font-mono` : `text-gray-900 hover:bg-green-50 hover:text-gray-900`,
                                        'block rounded-md py-2 px-3 font-black cursor-pointer'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.icon} {/* Renderizza l'icona */}
                                    <span>{item.name}</span>
                                </Disclosure.Button>
                            ))}
                        </div>
                        <div className="border-t border-gray-200 pb-3 pt-4">
                            <div className="flex items-center px-4">
                                <div className="flex-shrink-0">
                                    {user ? <img className="h-10 w-10 rounded-full" src={user.image ? process.env.REACT_APP_FILESERVER + user.image.url : process.env.REACT_APP_DEFAULT_AVATAR} alt="" /> : <img className="h-10 w-10 rounded-full" src={process.env.REACT_APP_DEFAULT_AVATAR} alt="" />}
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-gray-800">{user.name}</div>
                                    <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                </div>
                                <button
                                    type="button"
                                    className={`relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">Vedi notifiche</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="mt-3 space-y-1 px-2">
                                    <Disclosure.Button
                                    onClick={() => {
                                        dispatch(toggleNavbar({
                                            navbar: "PROFILE",
                                            sidebar: "profile"
                                        }))
                                        navigate("/options/profile")
                                    }}
                                        className="block cursor-pointer rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                        Il tuo profilo
                                    </Disclosure.Button>
                                    <Disclosure.Button
                                        onClick={() => {
                                            dispatch(toggleNavbar({
                                                navbar: "OPTIONS",
                                                sidebar: "users"
                                            }))
                                            navigate("/options/users")
                                        }}
                                        className="block cursor-pointer rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                        Opzioni 
                                    </Disclosure.Button>
                                    <Disclosure.Button
                                    onClick={() => logout()}
                                        className="block cursor-pointer rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                        Esci
                                    </Disclosure.Button>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
