
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MagnifyingGlassIcon,
    ArrowDownTrayIcon,
    ChevronUpIcon,
} from '@heroicons/react/20/solid';

import { useSelector } from 'react-redux'
import Loader1 from '../utility/loader-1';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Cookies from 'js-cookie'


export default function Table1({ data, columns, config, dataCallback }) {
    
    const company = JSON.parse(Cookies.get("company"))

    const checkbox = useRef();
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedItems, setselectedItems] = useState([]);
    const [sorted, setSorted] = useState('asc');
    const [sortedBy, setSortedBy] = useState('');
    const [perPage, setPerPage] = useState(config.sizePerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [from, setFrom] = useState(1)
    const [search, setSearch] = useState("")

    const calcPages = async () => {
        const totalPages = Math.ceil(data.meta.total / perPage);
        const pags = [];
        for (let page = 1; page <= totalPages; page++) {
            pags.push(
                <span
                    key={page}
                    aria-current="page"
                    onClick={() => setCurrentPage(page)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-${currentPage === page ? 'white' : 'gray-900'
                        } ring-1 ring-inset ring-${currentPage === page ? company.theme : 'gray'}-300 hover:bg-${currentPage === page ? `green-400` : 'gray-50'
                        } focus:z-20 focus:outline-offset-0 cursor-pointer bg-${currentPage === page ? `green-600` : 'white'}`}
                >
                    {page}
                </span>
            );
        }
        setPages(pags);
    };

    useEffect(() => {
        // Esegui solo al primo rendering
        if (!data.meta.total) return;
        dataCallback(data, perPage, currentPage, sorted, sortedBy, search);
        calcPages();
    }, [data.meta.total, perPage, currentPage, sorted, sortedBy, search]);

    useLayoutEffect(() => {
        const isIndeterminate = selectedItems.length > 0 && selectedItems.length < data.length;
        setChecked(selectedItems.length === data.length);
        setIndeterminate(isIndeterminate);
        checkbox.current.indeterminate = isIndeterminate;
    }, [selectedItems]);

    function toggleAll() {
        setselectedItems(checked || indeterminate ? [] : data);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    //Funzione per scaricare in formato CSV
    const arrayToCsv = (dataArray, filename, displayFields) => {
        const fieldsToDisplay = displayFields || Object.keys(dataArray[0] || {});
        const csvContent = dataArray.reduce((acc, row) => {
            const values = fieldsToDisplay.map(field => `"${row[field] || ''}"`).join(',');
            return `${acc}${values}\n`;
        }, 'data:text/csv;charset=utf-8,');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', filename || 'data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    //Funzione per scaricare in formato XLS
    const arrayToXLS = (dataArray, filename, displayFields) => {
        const fieldsToDisplay = displayFields || Object.keys(dataArray[0] || {});
        const wsData = dataArray.map(row =>
            fieldsToDisplay.reduce((acc, field) => {
                acc[field] = row[field];
                return acc;
            }, {})
        );
        const ws = XLSX.utils.json_to_sheet(wsData, { header: fieldsToDisplay });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const blob = XLSX.write(wb, { bookSST: true, type: 'blob' });
        saveAs(blob, filename || 'data.xlsx');
    };

    
    

    return (
        <>{data ? <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-3xl font-extrabold leading-6 text-gray-900">{config.title ? config.title : ''}</h1>
                    {config.description ? config.description() : ''}
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-start">
                    <div className="">
                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <Menu.Button
                                    className={`mx-1 block rounded-md bg-green-600 px-3 py-2 flex flex-start items-center text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600`}
                                >
                                    <ArrowDownTrayIcon className="mr-2 h-5 w-5 text-white hover:text-white" />
                                    Esporta
                                    <ChevronDownIcon
                                        className="-mr-1 ml-2 h-5 w-5 text-white hover:text-white"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                    <div className="px-1 py-1 ">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => arrayToXLS(data.data, config.title)}
                                                    className={`${active ? `bg-green-500 text-white` : 'text-gray-900'
                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                >
                                                    Esporta .xls
                                                </button>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => arrayToCsv(data.data, config.title)}
                                                    className={`${active ? `bg-green-500 text-white` : 'text-gray-900'
                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                >
                                                    Esporta .csv
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                    {config.extra ? config.extra() : null}
                </div>
            </div>
            <div className="mt-8 flow-root bg-white px-4 rounded-xl shadow">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className='flex justify-between mt-2'>
                            <div className="relative">
                                <MagnifyingGlassIcon
                                    className="pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                <input
                                    className="w-full mt-1 pl-11 rounded-md border-0 bg-gray-100 px-4 py-1.5 text-gray-900 focus:ring-0 sm:text-sm"
                                    placeholder="Cerca..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                            
                            <div>
                                <select
                                    id="location"
                                    name="location"
                                    className={`mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6`}
                                    defaultValue={perPage}
                                    onChange={e => {
                                        setPerPage(e.target.value)
                                    }}
                                >
                                    {config.perPage ? <>
                                    {config.perPage.map(size => {
                                        return (
                                            <option key={size} value={size}>{size}</option> 
                                        )
                                    })}
                                        <option value="1">Tutti i record</option>
                                    </> : <>
                                        <option value="10">10</option>
                                        <option value="20">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="1">Tutti i record</option>
                                    </>}
                                    
                                </select>
                            </div>
                        </div>
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr className=''>
                                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                        <input
                                            type="checkbox"
                                            className={`absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600`}
                                            ref={checkbox}
                                            checked={checked}
                                            onChange={toggleAll}
                                        />
                                    </th>
                                    {columns.map((col, index) => (
                                        <th key={index} scope="col" className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            <span className='flex'>{col.name} {col.sort ? <>{(sortedBy === col.dataField && sorted === "asc") ? <ChevronUpIcon className="ml-2 h-5 w-5 text-gray-900 cursor-pointer" onClick={() => {
                                                setSortedBy(col.dataField)
                                                setSorted("desc")
                                            }} /> : <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-900 cursor-pointer" onClick={() => {
                                                    setSortedBy(col.dataField)
                                                    setSorted("asc")
                                                    }} />}</> : null}</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {data && data.meta.total !== 0 && (
                                    <>
                                        {data.data
                                        .slice() // Crea una copia dell'array prima di applicare il sort
                                        .sort((a, b) => (sorted === 'asc' ? 1 : -1) * (a[sortedBy]?.toLowerCase() || '').localeCompare((b[sortedBy]?.toLowerCase() || ''))).
                                        map((item, index) => (
                                            <tr key={index} className={`py-1.5 hover:bg-green-50 ${config.rowClick ? `cursor-pointer` : null}`} onClick={config.rowClick ? () => config.rowClick(item) : null}>
                                                <td className="whitespace-nowrap relative px-7 sm:w-12 sm:px-6">
                                                    {selectedItems.includes(item) && (
                                                        <div className={`absolute inset-y-0 left-0 w-0.5 bg-green-600`} />
                                                    )}
                                                    <input
                                                        type="checkbox"
                                                        className={`absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600`}
                                                        value={item.id}
                                                        checked={selectedItems.includes(item)}
                                                        onChange={(e) =>
                                                            setselectedItems(
                                                                e.target.checked
                                                                    ? [...selectedItems, item]
                                                                    : selectedItems.filter((p) => p !== item)
                                                            )
                                                        }
                                                    />
                                                </td>
                                                {columns.map((column, j) => (
                                                    <td key={j} className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                                                        {typeof column.formatter === 'undefined' ? item[column.dataField] : column.formatter(item)}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </>
                                )}
                                {(!data || data.meta.total === 0) && (
                                    <tr>
                                        <td className="p-3" colSpan={columns.length + 1} align="center">
                                            <span className='text-sm'>Non ci sono dati disponibili 🧐</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div className="flex items-center justify-between border-t border-gray-200 bg-white py-3 ">
                            <div className="flex flex-1 justify-between sm:hidden">
                                <a
                                    href="#"
                                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                >
                                    Previous
                                </a>
                                <a
                                    href="#"
                                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                >
                                    Next
                                </a>
                            </div>
                            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                                <div>
                                    <p className="text-sm text-gray-700">
                                        Visualizzo da <span className="font-medium">{from + (perPage * (currentPage - 1))}</span> a <span className="font-medium">{perPage > data.meta.total ? data.meta.total : perPage * currentPage}</span> di{' '}
                                        <span className="font-medium">{data.meta.total}</span> risultati
                                    </p>
                                </div>
                                <div>
                                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                        <button
                                            type="button"
                                            disabled={currentPage === 1}
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                        >
                                            <span className="sr-only">Precente</span>
                                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                     

                                        <>{pages.map((page) => {
                                            return page
                                        })}</>


                                        
                                       
                                        <button
                                            type="button"
                                            disabled={currentPage === pages.length}
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                        >
                                            <span className="sr-only">Successivo</span>
                                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : <Loader1 />}</>
    )
}