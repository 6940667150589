import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from '../../assets/img/default.svg'
import background from '../../assets/img/login-bg.webp'
import { RadioGroup } from '@headlessui/react'
import axios from 'axios'
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from 'react-redux'

//Gestione dati nei cookies
import Cookies from 'js-cookie';


const colors = [
    { name: 'Green', bgColor: 'bg-green-500', selectedColor: 'ring-green-500', value: 'green' },
    { name: 'Lime', bgColor: 'bg-lime-500', selectedColor: 'ring-lime-500', value: 'lime' },
    { name: 'Yellow', bgColor: 'bg-yellow-500', selectedColor: 'ring-yellow-500', value: 'green' },
    { name: 'Orange', bgColor: 'bg-orange-500', selectedColor: 'ring-orange-500', value: 'orange' },
    { name: 'Red', bgColor: 'bg-red-500', selectedColor: 'ring-red-500', value: 'red' },
    { name: 'Pink', bgColor: 'bg-pink-500', selectedColor: 'ring-pink-500', value: 'pink' },
    { name: 'Violet', bgColor: 'bg-violet-500', selectedColor: 'ring-violet-500', value: 'violet' },
    { name: 'Cyan', bgColor: 'bg-cyan-500', selectedColor: 'ring-cyan-500', value: 'cyan' },
    { name: 'Blue', bgColor: 'bg-blue-500', selectedColor: 'ring-blue-500', value: 'blue' },
    { name: 'Slate', bgColor: 'bg-slate-500', selectedColor: 'ring-slate-500', value: 'slate' },
]

export default function Login() {

    let user  = useSelector(state => state.auth ? state.auth.user : state.auth)
    let jwt  = useSelector(state => state.auth ? state.auth.jwt : state.auth)
    const dispatch = useDispatch();


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    const navigate = useNavigate(); // Ottieni l'istanza di history
    const [email, setemail] = useState("")
    const [password, setPassword] = useState("")
    const [show, setShow] = useState(false)
    const [logged, setLogged] = useState(false)
    const [newCompany, setNewCompany] = useState(false)
    const [selected, setSelected] = useState([])
    const [err, setErr] = useState(null)
    const [businessName, setBusinessName] = useState("")
    const [vat, setVat] = useState("")
    const [palette, setPalette] = useState(colors[0])
    const [vatErr, setVatErr] = useState(null)




    const login = async () => {
        try {
            if (email !== '' && password !== '') {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/auth/signin`,
                    {
                        email: email,
                        password: password,
                    },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.data) {
                    
                    dispatch({ type: 'SET_USER', payload: response.data });
                    Cookies.set('user', JSON.stringify(response.data), {
                        expires: 1,
                    });
                    setErr(null);
                    setLogged(true);
                }
            } else {
                setErr('Si è verificato un errore, controlla i dati inseriti e riprova.');
            }
        } catch (e) {
            console.error(e);
            setErr('Si è verificato un errore, controlla i dati inseriti e riprova.');
        }
    };

    const proceedTo = async () => {
    if (selected && selected === "ADD_NEW") {
        setNewCompany(true);
    } else if (selected && selected !== "ADD_NEW") {
        dispatch({ type: 'CHOOSE_COMPANY', payload: selected });

        // Controlla se il cookie '@company' esiste
        const existingCompany = Cookies.get('company');
        
        if (existingCompany) {
            // Se il cookie esiste, lo aggiorniamo con il nuovo valore
            console.log('Aggiornamento cookie:', existingCompany);
        } else {
            // Se il cookie non esiste, lo creiamo
            console.log('Creazione nuovo cookie');
        }

        // Imposta o aggiorna il cookie
        Cookies.set('company', JSON.stringify(selected), {
            expires: 1  // Il cookie scadrà dopo 1 giorno
        });

        navigate(`/options/users`);
    }
};

    const addCompany = async () => {
        try {
            if (businessName !== '' && vat !== '') {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/companies/create`,
                    {
                        businessName: businessName,
                        vat: 'IT' + vat,
                        theme: palette,
                        user: user._id
                    },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwt}`
                        },
                    }
                );

                if (response.data) {
                    dispatch({ type: 'CHOOSE_COMPANY', payload: response.data });
                    setErr(null);
                    navigate(`/crm/customers`)

                }
            } else {
                setErr('Si è verificato un errore, controlla i dati inseriti e riprova.');
            }
        } catch (e) {
            console.error(e);
            setErr('Si è verificato un errore, controlla i dati inseriti e riprova.');
        }
    }

    
    return (
        <>
            {logged ?
                <>
                    {newCompany ? <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-24">
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            <img
                                className="mx-auto h-24 w-auto"
                                src={process.env.REACT_APP_LOGO}
                                alt="Luigis"
                            />
                            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Crea una nuova azienda
                            </h2>
                            <p className="text-sm text-gray-400">Ti chiediamo un paio di dati per iniziare, potrai inserire il resto in seguito da <strong>Impostazioni {`>`} Dettagli Società</strong> 🖖🏻</p>
                        </div>

                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

                            <form className="space-y-6" >
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Ragione sociale
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            value={businessName}
                                            onChange={e => setBusinessName(e.target.value)}
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        PIVA / Codice Fiscale
                                    </label>
                                    <div className="mt-2 flex">
                                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                            IT
                                        </span>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            value={vat}
                                            onChange={e => {
                                                setVat(e.target.value)
                                                const italianTaxCodeRegex = /^[0-9]{11}$|^[A-Z]{6}[0-9]{2}[ABCDEHLMPRST]{1}[0-9]{2}[A-Z]{1}[0-9]{3}[A-Z]{1}$/;

                                                if (italianTaxCodeRegex.test(e.target.value)) {
                                                    setVat(e.target.value);
                                                    setVatErr(null)
                                                } else {
                                                    // Puoi gestire l'errore o fornire un feedback all'utente
                                                    setVatErr("🧐 Sei sicuro? Il valore da te inserito non ci sembra ne una partita IVA, ne un codice fiscale.");
                                                }
                                            }}
                                            required
                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                        />

                                        
                                    </div>
                                    {vatErr ? <p className="text-xs text-red-600 mt-2">{vatErr}</p> : null}
                                </div>

                                <div>
                                     <div className="relative max-w-sm mt-2">
                                        <RadioGroup value={palette} onChange={setPalette}>
                                            <RadioGroup.Label className="block text-sm font-medium leading-6 text-gray-900">
                                                Palette
                                            </RadioGroup.Label>
                                            <div className="mt-4 flex items-center space-x-3">
                                                {colors.map((color) => (
                                                    <RadioGroup.Option
                                                        key={color.name}
                                                        value={color.value}
                                                        onChange={e => console.log(e.target.value)}
                                                        className={({ active, checked }) =>
                                                            classNames(
                                                                color.selectedColor,
                                                                active && checked ? 'ring ring-offset-1' : '',
                                                                !active && checked ? 'ring-2' : '',
                                                                'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                                                            )
                                                        }
                                                    >
                                                        <RadioGroup.Label as="span" className="sr-only">
                                                            {color.name}
                                                        </RadioGroup.Label>
                                                        <span
                                                            aria-hidden="true"
                                                            className={classNames(color.bgColor, 'h-8 w-8 rounded-full border border-black border-opacity-10')}
                                                        />
                                                    </RadioGroup.Option>
                                                ))}
                                            </div>
                                        </RadioGroup>

                                    </div>
                                </div>

                                <div className="flex flex-row justify-between items-center">
                                    <button
                                        type="button"
                                        onClick={() => setNewCompany(false)}
                                        className="flex w-full mt-4 justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-green-600 shadow-sm hover:bg-green-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Indietro
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => addCompany()}
                                        className="flex w-full mt-4 justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Procedi
                                    </button>
                                </div>
                            </form>
                            
                            
                            
                        </div>

                    </div> : <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-24">
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            <img
                                className="mx-auto h-24 w-auto"
                                src={process.env.REACT_APP_LOGO}
                                alt="Luigis"
                            />
                            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Seleziona l'azienda
                            </h2>
                        </div>

                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                            <RadioGroup value={selected} onChange={setSelected}>
                                <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                                <div className="space-y-4">
                                    {user ? <>{user.companies ? user.companies.map((item, index) => (
                                        <RadioGroup.Option
                                            key={index}
                                            value={item}
                                            className={({ active }) =>
                                                classNames(
                                                    active ? 'border-green-600 ring-2 ring-green-600' : 'border-gray-300',
                                                    'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                                                )
                                            }
                                        >
                                            {({ active, checked }) => (
                                                <>
                                                
                                                    <span className="flex flex-row justify-start items-center">
                                                        <RadioGroup.Description
                                                            as="span"
                                                            className="mt-2 flex text-sm mr-4 sm:mt-0 sm:flex-col sm:text-right"
                                                        >
                                                            <img className="h-10 w-10 rounded-full" src={process.env.REACT_APP_DEFAULT_AVATAR} alt="" />
                                                        </RadioGroup.Description>
                                                        <span className="flex flex-col text-sm">
                                                            <RadioGroup.Label as="span" className="font-medium text-gray-900">
                                                                {item.businessName}
                                                            </RadioGroup.Label>
                                                            <RadioGroup.Description as="span" className="text-gray-500">
                                                                <span className="block sm:inline">
                                                                    {item.vat}
                                                                </span>{' '}
                                                            </RadioGroup.Description>
                                                        </span>
                                                    </span>
                                                    
                                                    <span
                                                        className={classNames(
                                                            active ? 'border' : 'border-2',
                                                            checked ? 'border-green-600' : 'border-transparent',
                                                            'pointer-events-none absolute -inset-px rounded-lg'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </>
                                            )}
                                        </RadioGroup.Option>
                                    )) : <p className="text-sm">Nessuna azienda associata all'account</p>}</> : <p className="text-sm">Nessuna azienda associata all'account</p>}
                                      {user ? <>{ user.permission === "ADMIN" || user.permission === "SUPERADMIN" ? <>
                                        <div className="relative flex py-5 items-center">
                                            <div className="flex-grow border-t border-gray-200"></div>
                                            <span className="flex-shrink mx-4  text-xs text-gray-300">oppure</span>
                                            <div className="flex-grow border-t border-gray-200"></div>
                                        </div>
                                    <RadioGroup.Option
                                        value={"ADD_NEW"}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'border-green-600 ring-2 ring-green-600' : 'border-gray-300',
                                                'relative block cursor-pointer rounded-lg border-dashed border-2 bg-white px-6 py-2 shadow-sm focus:outline-none sm:flex sm:justify-between'
                                            )
                                        }
                                    >
                                        {({ active, checked }) => (
                                            <>

                                                <span className="flex flex-row justify-start items-center">
                                                    <PlusCircleIcon className="h-16 w-16 text-gray-400 mr-4" />
                                                    <span className="flex flex-col text-sm">
                                                        <RadioGroup.Label as="span" className="font-medium text-gray-900">
                                                            Crea nuova
                                                        </RadioGroup.Label>
                                                        <RadioGroup.Description as="span" className="text-gray-500">
                                                            <span className="block sm:inline">
                                                                Crea una nuova azienda associata al tuo account.
                                                            </span>
                                                        </RadioGroup.Description>
                                                    </span>
                                                </span>

                                                <span
                                                    className={classNames(
                                                        active ? 'border' : 'border-2',
                                                        checked ? 'border-green-600' : 'border-transparent',
                                                        'pointer-events-none absolute -inset-px rounded-lg'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </>
                                        )}
                                            </RadioGroup.Option></> : null}</> : null}
                                </div>
                                </RadioGroup>
                            <button
                                type="button"
                                onClick={() => proceedTo()}
                                className="flex w-full mt-4 justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                Procedi
                            </button>
                        </div>
                        
                    </div>}
                </>
             : <div className="flex min-h-screen flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">

                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            <img
                                className="mx-auto h-24 w-auto"
                                src={process.env.REACT_APP_LOGO}
                                alt="4biz"
                            />
                            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Accedi al tuo account 
                            </h2>
                        </div>

                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form className="space-y-6" >
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            value={email}
                                            onChange={e => setemail(e.target.value)}
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="flex items-center justify-between">
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                                Password
                                        </label>
                                        <div className="text-sm">
                                            <Link to="/recover-password" className="font-semibold text-green-600 hover:text-green-500">
                                                Password dimenticata?
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="relative w-full mt-2">
                                        <div className={`absolute inset-y-0 right-0 flex items-center px-2`} >
                                            <input className="hidden js-password-toggle" id="toggle" type="checkbox" onChange={(e) => setShow(e.target.checked)} />
                                            <label className={`bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label`} htmlFor="toggle">{!show ? "Mostra" : "Nascondi"}</label>
                                        </div>
                                        <input
                                            id="pass"
                                            name="pass"
                                            type={!show ? "password" : "text"}
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                            autoComplete="off"
                                        />
                                        
                                    </div>
                                        {err ? <p className="text-xs text-red-600 mt-2">{err}</p> : null}
                                </div>

                                <div>
                                    <button
                                        type="button"
                                        onClick={() => login()}
                                        className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Accedi
                                    </button>
                                    
                                </div>
                            </form>

                            <p className="mt-10 text-center text-sm text-gray-500">
                                Non hai ancora un servizio attivo?{' '}
                                <a href={process.env.REACT_APP_LEAD_GENERATION} target="_blank" rel="noreferrer" className="font-semibold leading-6 text-green-600 hover:text-green-500">
                                    Inizia subito
                                </a>
                                    {' '} o{' '}
                                    <a href={`https://4biz.ai`} target="_self" rel="noreferrer" className="font-semibold leading-6 text-green-600 hover:text-green-500">
                                        torna al sito
                                    </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={background}
                        alt=""
                    />
                </div>
            </div>}
        </>
    )
}
