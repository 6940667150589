import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from '../../assets/img/default.svg'
import background from '../../assets/img/login-bg.webp'
import { RadioGroup } from '@headlessui/react'
import axios from 'axios'
import { BriefcaseIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from 'react-redux'

//Gestione dati nei cookies
import { setCookie } from "nookies";
import { AES, enc } from "crypto-js";
import Footer1 from "../../components/navigations/footers/footer-1";

const colors = [
    { name: 'Green', bgColor: 'bg-green-500', selectedColor: 'ring-green-500', value: 'green' },
    { name: 'Lime', bgColor: 'bg-lime-500', selectedColor: 'ring-lime-500', value: 'lime' },
    { name: 'Yellow', bgColor: 'bg-yellow-500', selectedColor: 'ring-yellow-500', value: 'green' },
    { name: 'Orange', bgColor: 'bg-orange-500', selectedColor: 'ring-orange-500', value: 'orange' },
    { name: 'Red', bgColor: 'bg-red-500', selectedColor: 'ring-red-500', value: 'red' },
    { name: 'Pink', bgColor: 'bg-pink-500', selectedColor: 'ring-pink-500', value: 'pink' },
    { name: 'Violet', bgColor: 'bg-violet-500', selectedColor: 'ring-violet-500', value: 'violet' },
    { name: 'Cyan', bgColor: 'bg-cyan-500', selectedColor: 'ring-cyan-500', value: 'cyan' },
    { name: 'Blue', bgColor: 'bg-blue-500', selectedColor: 'ring-blue-500', value: 'blue' },
    { name: 'Slate', bgColor: 'bg-slate-500', selectedColor: 'ring-slate-500', value: 'slate' },
]

export default function ForgotPassword() {

    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [err, setErr] = useState(null)
    const [success, setSuccess] = useState(null)
   

    const recoverPassword = async () => {
        try {
            if (email !== '') {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_KEY}/auth/reset-password`,
                    {
                        email: email,
                    },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.data) {

                    setSuccess("Recupero password effettuato con successo 🎉")
                }
            } else {
                setErr('Si è verificato un errore, controlla i dati inseriti e riprova.');
            }
        } catch (e) {
            console.error(e);
            setErr('Si è verificato un errore, controlla i dati inseriti e riprova.');
        }
    };


    return (
        <div className="h-screen flex flex-col">
            <div className="flex  flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                    <img
                        className="mx-auto h-24 w-auto"
                        src={process.env.REACT_APP_LOGO}
                        alt="4BIZ"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Recupera password
                    </h2>
                    <p className="text-sm text-gray-400">Invieremo una nuova password provvisoria alla email indicata, potrai cambiarla in qualsiasi momento dalla sezione <strong>Il mio profilo</strong> 🖖🏻</p>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-lg">

                   <form className="grid mt-4 max-w-screen grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6 items-center" >
                        <div className="sm:col-span-6">
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email
                                </label>
                                <div className="text-sm">
                                    <Link to="/login" className="font-semibold text-green-600 hover:text-green-500">
                                        Torna al login
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required
                                    placeHolder="Inserisci la tua email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            {success ? <p className="text-sm font-semibold text-green-600 mt-2">{success}</p> : null}
                            {err ? <p className="text-sm font-semibold text-red-600 mt-2">{err}</p> : null}
                        </div>
                      
                       
                       

                        <div className="sm:col-span-6 flex flex-row">
                            
                            <button
                                type="button"
                                onClick={() => recoverPassword()}
                                className="flex w-full mt-4 justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 border-2 border-green-600 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                Recupera password
                            </button>
                        </div>
                    </form>



                </div>

            </div>

            <Footer1 />

        </div>
    )
}
