import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition, Dialog, RadioGroup } from '@headlessui/react'
import { ChevronDownIcon, ArrowUpTrayIcon, PlusCircleIcon, CursorArrowRaysIcon, ArrowDownTrayIcon, MagnifyingGlassIcon, PaperClipIcon, XMarkIcon, QuestionMarkCircleIcon, UserIcon, HandThumbUpIcon, CheckIcon, CogIcon, PlusIcon, StarIcon, EllipsisVerticalIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, UserGroupIcon, NoSymbolIcon, PaperAirplaneIcon, PencilIcon, TrashIcon, EnvelopeOpenIcon, EnvelopeIcon, PhoneIcon, KeyIcon, InformationCircleIcon, CurrencyEuroIcon, HomeModernIcon, CameraIcon } from '@heroicons/react/24/outline'
import { LuEuro, LuMailCheck, LuPiggyBank } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebar, toggleNavbar, sidebarSubMenu } from '../../actions'
import { regimiFiscali, esigibilitaIVA } from '../../assets/data/regimiFiscali'
import image from '../../assets/img/default.svg'
import aruba from '../../assets/img/aruba.png'

import axios from 'axios';

import Cookies  from 'js-cookie'

import Table1 from '../../components/tables/table-1'
import Loader1 from '../../components/utility/loader-1'
import Modal2 from '../../components/modals/modal-2'
import Form1 from '../../components/forms/form-1'
import Notification1 from '../../components/notifications/notification-1'
import WYSIWYG1 from '../../components/utility/wysiwyg'

export default function MyProfile() {

    let company = JSON.parse(Cookies.get('company'))
    let theme = company.theme
    let userCookie = JSON.parse(Cookies.get('user'))
    let user = userCookie.user
    let jwt = userCookie.jwt

    const [selectedUser, setSelectedUser] = useState(null)
  

    const [currentOption, setCurrentOption] = useState(0) 

    const [open, setOpen] = useState(false)
    const [tabs, setTabs] = useState([
        { name: 'Attivi', slug: 'attivi', count: 0, current: true },
        { name: 'Invitati', slug: 'invitati', count: 0, current: false },
        { name: 'Disattivi', slug: 'disattivati', count: 0, current: false },
    ])
    const [openInvitation, setOpenInvitation] = useState(false)
    const [openResendPassword, setOpenResendPassword] = useState(false)
    const [openBlock, setOpenBlock] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [notification, setNotification] = useState({
        title: "",
        description: "",
        type: "success"
    })
    const [showNotification, setShowNotification] = useState(false)
    const [loadingItem, setLoadingItem] = useState(false)

    const [data, setData] = useState(null)

    const getData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_KEY}/users/profile`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    },
                    params: {
                        id: user.uuid, // Utilizza data.company.id al posto di ${ company.id }
                    }
                }
            );
            if (response.data) {
                setData(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getData()
    }, []);


    const handleTabChange = (slug) => {
        const updatedTabs = tabs.map(tab => ({
            ...tab,
            current: tab.slug === slug,
        }));
        setTabs(updatedTabs);
    };

    const [invitationSuccess, setInvitationSuccess] = useState(null)



 

    const editUser = async data => {
        try {
            setInvitationSuccess(null)
            const response = await axios.patch(
                `${process.env.REACT_APP_API_KEY}/users/profile-edit`, {
                user: data,
                uuid: user.uuid
            },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                }
            );
            if (response.data) {
                setLoadingItem(true)
                getData()
                setSelectedUser(response.data)
                setTimeout(() => {
                    setLoadingItem(false)
                    setNotification({
                        title: "Conferma",
                        description: "Utente modificatə con successo!",
                        type: "success",
                    })
                    setShowNotification(true)
                }, 1000)
                
            }
        } catch (e) {
            console.error(e)
        }
    }

    let configForm = {
        formatter: () => {
            return (
                <p className="text-sm text-gray-500 mt-4">Modica i dati e <strong>salva</strong> per aggiornarli.</p>
            )
        },
        fields: [
            {
                id: "fname",
                name: "fname",
                label: "Nome",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: data ? data.fname : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci nome",
                autoComplete: "firstName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "lname",
                name: "lname",
                label: "Cognome",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: data ? data.lname : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci cognome",
                autoComplete: "lastName",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "email",
                name: "email",
                label: "Email",
                class: "sm:col-span-6",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: data ? data.email : null,
                onChange: null,
                required: true,
                disabled: true,
                placeholder: "Inserisci email",
                autoComplete: "email",
                autoCapitalize: true,
                size: 999,
            },
            {
                id: "jobTitle",
                name: "jobTitle",
                label: "Posizione lavorativa",
                class: "sm:col-span-3",
                type: "text",
                pattern: null,
                patternTitle: "Prova pattern",
                maxLength: null,
                minLength: null,
                defaultValue: data ? data.jobTitle : null,
                onChange: null,
                required: true,
                disabled: false,
                placeholder: "Inserisci posizione lavorativa",
                autoComplete: "jobTitle",
                autoCapitalize: true,
                size: 999,
            },
        ],
        cancelText: "Annulla",
        submitText: "Modifica",
        submit: data => editUser(data)
    }

    

    return (
        <>
            {showNotification ? <Notification1 config={notification} show={showNotification} setShow={setShowNotification} /> : null}

                        {/**Modali di conferma */}

            {data ? <main className={`py-10 w-full bg-crazy-green min-h-screen overflow-y-scroll`}>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5  lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                <img
                                    className="h-16 w-16 rounded-full"
                                    src={data.image ? data.image : process.env.REACT_APP_DEFAULT_AVATAR}
                                    alt=""
                                />
                                <span className="text-xs mt-2 text-emerald-700  cursor-pointer flex flex-row"><CameraIcon className=" cursor-pointer h-4 w-4 text-emerald-700 font-bold mr-2" /> Cambia immagine</span>
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{data.fname + " " + data.lname}</h1>
                            <p className="text-sm font-medium text-gray-500">
                                {data.email}
                            </p>
                        </div>
                    </div>
                    <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <Menu.Button
                                    className={`mx-1 block rounded-md bg-green-600 px-3 py-2 flex flex-start items-center text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600`}
                                >
                                    <CogIcon className="mr-2 h-5 w-5 text-white hover:text-white" />
                                    Azioni
                                    <ChevronDownIcon
                                        className="-mr-1 ml-2 h-5 w-5 text-white hover:text-white"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                    <div className="px-1 py-1 ">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    type="button"
                                                    className={`${active ? `bg-green-500 text-white` : 'text-gray-900'
                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    onClick={() => {
                                                        setOpenResendPassword(true)
                                                    }}
                                                >
                                                    <KeyIcon className='h-4 w-4 mr-2' />Reset password
                                                </button>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setOpenDelete(true)
                                                    }}
                                                    className={`${active ? `bg-green-500 text-white` : 'text-gray-900'
                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                >
                                                    <TrashIcon className='h-4 w-4 mr-2' /> Elimina
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>

                <div className="mx-auto mt-8 max-w-7xl  sm:px-6  lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                        {/* Description list*/}
                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">

                                    <div className='flex flex-row justify-between items-center mb-4'>
                                        <div className="flex flex-col justify-between">
                                            <h2 id="timeline-title" className="text-lg font-bold text-gray-900">
                                                Informazioni
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Leggi o modifica i dati del tuo profilo.</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                                    <Form1 config={configForm} />

                                </div>

                            </div>
                        </section>
                    </div>
                </div>
                
                          
            </main> : <Loader1 />}
        </> 
    )
}

