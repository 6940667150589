export const toggleSidebar = (value) => {
    return {
        type: 'TOGGLE_SIDEBAR',
        payload: value
    }
}

export const toggleNavbar = (value) => {
    return {
        type: 'TOGGLE_NAVBAR',
        payload: value
    }
}

export const user = (value) => {
    return {
        type: 'SET_USER',
        payload: value
    }
}

export const chooseCompany = (value) => {
    return {
        type: 'CHOOSE_COMPANY',
        payload: value
    }
}

export const sidebarSubMenu = (value) => {
    return {
        type: 'TOGGLE_MENU_SIDEBAR',
        payload: value
    }
}
